import React from "react";
import Page from "05_templates/Page";
import { PageHeader, PageHeadline, PageDescription } from "02_atoms/PageHeader";
import { Link } from "@reach/router";

const PasswordResetSuccess: React.FunctionComponent<{}> = () => {
  return (
    <Page navbarInitialShown={false}>
      <PageHeader>
        <PageHeadline>Passwort geändert</PageHeadline>
        <PageDescription>
          Vielen Dank. Sie haben ein neues Passwort vergeben. Sie können sich in
          wenigen Momenten mit diesem einloggen.
        </PageDescription>
      </PageHeader>
      <div>
        <Link to="/">Zurück zum Login</Link>
      </div>
    </Page>
  );
};

export default PasswordResetSuccess;
