import React from "react";
import styled, { css } from "styled-components";
import { Theme } from "01_base/Base";

type ButtonProps = {
  fullsize?: boolean;
  cta?: boolean;
  disabled?: boolean;
  theme: Theme;
};

const Button = styled("button")<ButtonProps>`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  background-color: ${(props: ButtonProps) =>
    props.cta ? props.theme.colors.orange : props.theme.colors.white};
  padding: 5px 10px;
  color:${(props: ButtonProps) =>
    props.cta ? props.theme.colors.white : props.theme.colors.orange};
  cursor: pointer;
  ${(props: ButtonProps) => props.fullsize && "width: 100%;"}
  border: 1px solid ${(props: ButtonProps) => props.theme.colors.orange};
  ${(props: ButtonProps) => props.disabled && css`
    color: ${props => props.theme.colors.grey};
    opacity: .7;
  `}
`;

export default Button;
