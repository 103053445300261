import React, { useState, useEffect, useRef } from "react";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import styled from "styled-components";
import "react-image-crop/dist/ReactCrop.css";
import {
  getStudentPhotoAction,
  updateStudentPhotoAction
} from "../Connectivity/Api";
import { KshClassesPhotoTkshPhoto } from "../Connectivity/Portal";
import Overlay from "02_atoms/Overlay";
import { Form } from "react-final-form";
import FormRow from "02_atoms/Form/FormRow";
import StyledForm from "04_organisms/Form";
import Button from "02_atoms/Button";
import CancelButton from "02_atoms/CancelButton";
import FileUpload from "../03_molecules/FileUpload";

async function getCroppedImg(image: string, pixelCrop: Crop) {
  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width!;
  canvas.height = pixelCrop.height!;
  const ctx = canvas.getContext("2d");

  const img = new Image();
  img.src = image;

  ctx!.drawImage(
    img,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width!,
    pixelCrop.height!,
    0,
    0,
    pixelCrop.width!,
    pixelCrop.height!
  );

  // As Base64 string
  return canvas.toDataURL("image/jpeg");
}

const ImageWrapper = styled("div")`
  overflow: hidden;

  img {
    max-width: 50%;
  }
`;

const cleanupDataUrl = (data: string) => data.substr(data.indexOf(",") + 1);

const createDataUrl = (image: KshClassesPhotoTkshPhoto): string => {
  // remove existing data header first
  if (image.img) {
    image.img = cleanupDataUrl(image.img);
  }
  return `data:${image.mimetype};base64,${image.img}`;
};

const ImageEditor: React.FunctionComponent<{}> = () => {
  const closeOverlayFunction = useRef<Function>(() => { });
  const [
    currentImage,
    setCurrentImage
  ] = useState<KshClassesPhotoTkshPhoto | null>(null);
  const [src, setSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({
    x: 10,
    y: 10,
    aspect: 1 / 1,
    width: 20,
    height: 20
  });
  const [pixelCrop, setPixelCrop] = useState<PixelCrop | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getStudentPhotoAction();
      setCurrentImage(result);
    };

    fetchData();
  }, []);

  const onSelectFile = (filename: string, content: string) => {
    setSrc("data:image/*;base64," + content);
  };

  const updateImage = async () => {
    const croppedImg = await getCroppedImg(src!, pixelCrop!);

    currentImage!.img = cleanupDataUrl(croppedImg!);

    await updateStudentPhotoAction(currentImage!);

    setCurrentImage(currentImage);
    setSrc(null);
    closeOverlayFunction.current();
  };

  const onCropChange = (crop: Crop, pixelCrop: PixelCrop) => {
    setCrop(crop);
    setPixelCrop(pixelCrop);
  };

  return (
    <>
      <ImageWrapper>
        {currentImage && <img src={createDataUrl(currentImage)} />}
      </ImageWrapper>
      <Overlay
        editLabel="Ausweisbild ändern"
        closeFunction={closeOverlayFunction}
      >
        <Form
          onSubmit={updateImage}
          render={({
            submitError,
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            errors
          }) => (
              <StyledForm onSubmit={handleSubmit}>
                {submitError && <div className="error">{submitError}</div>}
                <label>Ausweisbild:</label>
                <ImageWrapper>
                  {currentImage && <img src={createDataUrl(currentImage)} />}
                </ImageWrapper>
                <label>Neues Ausweisbild hochladen:</label>
                <FormRow>
                  <FileUpload
                    onFileUploadCompleted={onSelectFile}
                    accept={["image/jpeg", "image/png"]}
                    humanReadabledAcceptedFiletypes={"jpg/.jpeg, .png"}
                    extraMessageForOneFile={" - Nur ein Bild je Ausweis"}
                  />
                </FormRow>
                <FormRow>
                  {src && (
                    <>
                      <ReactCrop src={src} crop={crop} onChange={onCropChange} />
                      <div>
                        <Button type="submit">Speichern</Button>
                        <a onClick={() => closeOverlayFunction} />
                      </div>
                    </>
                  )}
                </FormRow>
                <CancelButton
                  closeFunction={() => closeOverlayFunction.current()}
                />
              </StyledForm>
            )}
        />
      </Overlay>
    </>
  );
};

export default ImageEditor;
