import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

declare global {
    interface Window {
        grecaptcha: any;
    }
}

export default () => {
    const grecaptchaObject = window.grecaptcha
    return (
        <div style={{ paddingLeft: 10 }} > 
            <ReCAPTCHA sitekey="6Ld_YM0ZAAAAAIRbL8gAT6mdGIdfx71wR8VTODPJ" grecaptcha={grecaptchaObject} />
        </div>
    )
}