import React from "react";

import Cookies from "js-cookie";

import {
  loginAction,
  meAction,
  getUserGroupList,
  updateEmailAction,
  updatePasswordAction,
  updateAddressAction,
  getAddressAction,
  updateMobileAction,
  getStudentDataAction,
  getStudyDataAction,
  getCountryList,
  reviewLog
} from "./Connectivity/Api";

import {
  SystemJSONTJSONObject,
  KshClassesCountrycodeTkshCountryCode,
  SystemGenericsCollectionsTListkshClassesCountrycodeTkshCountryCode
} from "Connectivity/Portal";
import { AddressInterface } from "Types/AddressInterface";
import { User } from "Types/User";
import { AddressOptions, Student } from "Types";

type LoginState = {
  loggedIn: boolean;
  user: User | null;
};

interface LoginContextProviderStore {
  login: (login: string, password: string) => Promise<Boolean>;
  logout: () => void;
  loggedIn: boolean;
  updateEmail: (email: string) => Promise<Boolean>;
  updateMobile: (mobile: string) => Promise<Boolean>;
  updatePassword: (
    current: string,
    future: string,
    futureVerify: string
  ) => Promise<Boolean>;
  updateAddress: (
    type: AddressOptions,
    address: AddressInterface
  ) => Promise<SystemJSONTJSONObject>;
  getAddress: (type: AddressOptions) => Promise<AddressInterface>;
  getStudentData: () => Promise<Student>;
  getStudyData: () => any;
  verifyData: () => Promise<boolean>;
  user: User | null;
  getCountries: () => Promise<
    SystemGenericsCollectionsTListkshClassesCountrycodeTkshCountryCode
  >;
}

export const LoginContext = React.createContext(
  {} as LoginContextProviderStore
);

export const LoginConsumer = LoginContext.Consumer;

export class LoginProvider extends React.Component<{}, LoginState> {
  public readonly state: LoginState = {
    loggedIn: false,
    user: null
  };

  login = async (login: string, password: string): Promise<Boolean> => {
    const token = await loginAction(login, password);

    if (!token.value) {
      return false;
    }

    /** @var User */
    const user = await meAction();

    user.groups = await getUserGroupList();

    this.setState(() => ({
      loggedIn: true,
      user
    }));

    return true;
  };

  logout = () => {
    Cookies.remove("token");
    this.setState(() => ({
      loggedIn: false,
      user: null
    }));
  };

  async componentDidMount() {
    const token = Cookies.get("token") || false;

    if (!token) {
      return;
    }

    const user = await meAction();
    if (!user) {
      this.setState(() => ({
        loggedIn: false,
        user: null
      }));

      return;
    }

    user.groups = await getUserGroupList();

    this.setState(() => ({
      loggedIn: true,
      user
    }));
  }

  updateEmail = async (email: string): Promise<Boolean> => {
    const mail = await updateEmailAction(email);

    if (!mail) {
      return false;
    }

    this.setState((prev: LoginState) => ({
      ...prev,
      user: {
        ...this.state.user,
        email
      }
    }));

    return true;
  };

  updatePassword = async (future: string): Promise<Boolean> => {
    const password = await updatePasswordAction(future);
    if (!password) {
      return false;
    }

    return true;
  };

  updateMobile = async (newMobile: string): Promise<Boolean> => {
    const mobile = await updateMobileAction(newMobile);

    if (!mobile) {
      return false;
    }

    this.setState((prev: LoginState) => ({
      ...prev,
      user: {
        ...this.state.user,
        mobilephone: newMobile
      }
    }));

    return true;
  };

  getCountries = async () => {
    return await getCountryList();
  };

  getStudentData = async () => {
    return await getStudentDataAction();
  };

  getStudyData = async () => {
    return await getStudyDataAction();
  };

  verifyData = async () => {
    if (!this.state.user) {
      return false;
    }
    if (!this.state.user.kpid) {
      return false;
    }

    await reviewLog(this.state.user.kpid);
    this.setState((prev: LoginState) => ({
      ...prev,
      user: {
        ...prev.user,
        reviewdata: 0
      }
    }));

    return true;
  };

  /**
   * @TODO check return type
   */
  updateAddress = async (
    type: AddressOptions,
    address: AddressInterface
  ): Promise<SystemJSONTJSONObject> => {
    return await updateAddressAction(type, address);
  };

  getAddress = async (type: AddressOptions): Promise<AddressInterface> => {
    return await getAddressAction(type);
  };

  render() {
    const store: LoginContextProviderStore = {
      loggedIn: this.state.loggedIn,
      user: this.state.user,
      login: this.login,
      logout: this.logout,
      updateEmail: this.updateEmail,
      updateMobile: this.updateMobile,
      updatePassword: this.updatePassword,
      updateAddress: this.updateAddress,
      getStudentData: this.getStudentData,
      getStudyData: this.getStudyData,
      getAddress: this.getAddress,
      verifyData: this.verifyData,
      getCountries: this.getCountries
    };

    return (
      <LoginContext.Provider value={store}>
        {this.props.children}
      </LoginContext.Provider>
    );
  }
}
