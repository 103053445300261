import React, { useState, useEffect, useCallback } from "react";
import { useToggle } from "Utils/Hooks";
import { KshClassesPublicationTkshPublication } from "Connectivity/Portal";
import Button from "02_atoms/Button";
import { sortResult } from "Utils/CareerSort";

import {
  getServiceLectureAction,
  updateServiceLectureAction,
  moveUpServiceLectureAction,
  moveDownServiceLectureAction,
  deleteServiceLectureAction,
  createServiceLectureAction,
  updateServiceLectureFileAction,
  deleteServiceLectureFileAction
} from "Connectivity/Api";

import Lecture from "./Lecture";
import Table from "02_atoms/Form/Table";
import notify, { addNotification } from "Utils/SaveNotification";

const LectureList: React.FunctionComponent<{}> = () => {
  const [addNewIsOpen, toggle] = useToggle(false);
  const [lectureList, setLectureList] = useState<
    Array<KshClassesPublicationTkshPublication>
  >([]);

  const fetchData = useCallback(async () => {
    const result = await getServiceLectureAction();
    sortResult(result);
    setLectureList(result);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Table>
        <tbody>
          {lectureList &&
            lectureList.map(
              (lecture: KshClassesPublicationTkshPublication, i) => (
                <Lecture
                  lecture={lecture}
                  key={`publication-${i}`}
                  reFetch={(newLectureList: Array<KshClassesPublicationTkshPublication>) => setLectureList(newLectureList)}
                  reFetchWithoutNewList={fetchData}
                  updateLecture={addNotification(updateServiceLectureAction)}
                  deleteLecture={addNotification(deleteServiceLectureAction)}
                  updateFile={updateServiceLectureFileAction}
                  deleteFile={deleteServiceLectureFileAction}
                  moveUp={async () => {
                    let list = await moveUpServiceLectureAction(lecture);
                    notify();
                    list = sortResult(list);
                    setLectureList(list);
                  }}
                  moveDown={async () => {
                    let list = await moveDownServiceLectureAction(lecture);
                    notify();
                    list = sortResult(list);
                    setLectureList(list);
                  }}
                >
                  {lecture.caption}
                </Lecture>
              )
            )}

          {addNewIsOpen && (
            <Lecture
              lecture={{}}
              isNew={true}
              updateLecture={createServiceLectureAction}
              toggleAddNew={toggle}
              reFetch={async (newLectureList: Array<KshClassesPublicationTkshPublication>) => {
                await setLectureList(newLectureList);
                toggle();
              }}
            />
          )}
        </tbody>
      </Table>

      {!addNewIsOpen && (
        <Button fullsize cta onClick={() => toggle()}>
          neuen Vortrag hinzufügen
        </Button>
      )}
    </>
  );
};

export default LectureList;
