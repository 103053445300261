import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as Sentry from "@sentry/browser";
import { AppContainer } from "react-hot-loader";

// if (process.env.REACT_APP_SENTRY_DSN && process.env.NODE_ENV === "production") {
  // Sentry.init({
    // dsn: process.env.REACT_APP_SENTRY_DSN,
    // release: process.env.REACT_APP_SENTRY_RELEASE
  // });
// }

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <App />
    </AppContainer>,
    document.getElementById("root")
  );
};

// Render once
render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./App", () => {
    render(App);
  });
}
