/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Server API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://services.ksh-m.de:2001/portal/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *  
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Body
 */
export interface Body {
    /**
     * 
     * @type {number}
     * @memberof Body
     */
    kpid: number;
}

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {SystemGenericsCollectionsTListkshClassesEmailAliasTkshEmailAlias}
     * @memberof InlineResponse200
     */
    value?: SystemGenericsCollectionsTListkshClassesEmailAliasTkshEmailAlias;
}

/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2001
     */
    value?: number;
}

/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {SystemGenericsCollectionsTListkshClassesCountrycodeTkshCountryCode}
     * @memberof InlineResponse2002
     */
    value?: SystemGenericsCollectionsTListkshClassesCountrycodeTkshCountryCode;
}

/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {SystemGenericsCollectionsTListkshClassesCareerTkshCareer}
     * @memberof InlineResponse2003
     */
    value?: SystemGenericsCollectionsTListkshClassesCareerTkshCareer;
}

/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {SystemGenericsCollectionsTListSystemInteger}
     * @memberof InlineResponse2004
     */
    value?: SystemGenericsCollectionsTListSystemInteger;
}

/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {SystemGenericsCollectionsTListkshClassesPublicationTkshPublication}
     * @memberof InlineResponse2005
     */
    value?: SystemGenericsCollectionsTListkshClassesPublicationTkshPublication;
}

/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {SystemGenericsCollectionsTListkshClassesNewsTkshNews}
     * @memberof InlineResponse2006
     */
    value?: SystemGenericsCollectionsTListkshClassesNewsTkshNews;
}

/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {SystemGenericsCollectionsTListkshClassesStudyprogramTkshStudyProgram}
     * @memberof InlineResponse2007
     */
    value?: SystemGenericsCollectionsTListkshClassesStudyprogramTkshStudyProgram;
}

/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {SystemGenericsCollectionsTListkshClassesGroupTkshGroup}
     * @memberof InlineResponse2008
     */
    value?: SystemGenericsCollectionsTListkshClassesGroupTkshGroup;
}

/**
 * 
 * @export
 * @interface KshClassesAppcmdTkshAppCmd
 */
export interface KshClassesAppcmdTkshAppCmd {
    /**
     * 
     * @type {number}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    active?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    app?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    cmd?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    methodin?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    methodout?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    validfor?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    params?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    strspecial?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    subprop?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    valueonly?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    jsonkey?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    isarray?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    mocking?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    requiredfields?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesAppcmdTkshAppCmd
     */
    resultclass?: string;
}

/**
 * 
 * @export
 * @interface KshClassesCareerTkshCareer
 */
export interface KshClassesCareerTkshCareer {
    /**
     * 
     * @type {number}
     * @memberof KshClassesCareerTkshCareer
     */
    active?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesCareerTkshCareer
     */
    caption?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesCareerTkshCareer
     */
    careertype?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesCareerTkshCareer
     */
    seq?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesCareerTkshCareer
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesCareerTkshCareer
     */
    kpid?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesCareerTkshCareer
     */
    yearto?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesCareerTkshCareer
     */
    yearfrom?: number;
}

/**
 * 
 * @export
 * @interface KshClassesCountrycodeTkshCountryCode
 */
export interface KshClassesCountrycodeTkshCountryCode {
    /**
     * 
     * @type {number}
     * @memberof KshClassesCountrycodeTkshCountryCode
     */
    acid?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesCountrycodeTkshCountryCode
     */
    caption?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesCountrycodeTkshCountryCode
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesCountrycodeTkshCountryCode
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesCountrycodeTkshCountryCode
     */
    seq?: number;
}

/**
 * 
 * @export
 * @interface KshClassesEmailAliasTkshEmailAlias
 */
export interface KshClassesEmailAliasTkshEmailAlias {
    /**
     * 
     * @type {string}
     * @memberof KshClassesEmailAliasTkshEmailAlias
     */
    active?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesEmailAliasTkshEmailAlias
     */
    destination?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesEmailAliasTkshEmailAlias
     */
    domainid?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesEmailAliasTkshEmailAlias
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesEmailAliasTkshEmailAlias
     */
    isaccount?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesEmailAliasTkshEmailAlias
     */
    kpid?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesEmailAliasTkshEmailAlias
     */
    login?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesEmailAliasTkshEmailAlias
     */
    source?: string;
}

/**
 * 
 * @export
 * @interface KshClassesFileloadTkshFileLoad
 */
export interface KshClassesFileloadTkshFileLoad {
    /**
     * 
     * @type {string}
     * @memberof KshClassesFileloadTkshFileLoad
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesFileloadTkshFileLoad
     */
    content?: string;
    /**
     * 
     * @type {Date}
     * @memberof KshClassesFileloadTkshFileLoad
     */
    datecreated?: Date;
    /**
     * 
     * @type {string}
     * @memberof KshClassesFileloadTkshFileLoad
     */
    filetype?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesFileloadTkshFileLoad
     */
    kpid?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesFileloadTkshFileLoad
     */
    id?: number;
}

/**
 * 
 * @export
 * @interface KshClassesGroupTkshGroup
 */
export interface KshClassesGroupTkshGroup {
    /**
     * 
     * @type {string}
     * @memberof KshClassesGroupTkshGroup
     */
    caption?: string;
}

/**
 * 
 * @export
 * @interface KshClassesLecturerTkshLecturer
 */
export interface KshClassesLecturerTkshLecturer {
    /**
     * 
     * @type {string}
     * @memberof KshClassesLecturerTkshLecturer
     */
    campus?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesLecturerTkshLecturer
     */
    filter?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesLecturerTkshLecturer
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesLecturerTkshLecturer
     */
    kshemail?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesLecturerTkshLecturer
     */
    qualification?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesLecturerTkshLecturer
     */
    urlextern?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesLecturerTkshLecturer
     */
    workspace?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesLecturerTkshLecturer
     */
    kpid?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesLecturerTkshLecturer
     */
    profession?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesLecturerTkshLecturer
     */
    swcardid?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesLecturerTkshLecturer
     */
    urlintern?: string;
}

/**
 * 
 * @export
 * @interface KshClassesNewsTkshNews
 */
export interface KshClassesNewsTkshNews {
    /**
     * 
     * @type {number}
     * @memberof KshClassesNewsTkshNews
     */
    active?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesNewsTkshNews
     */
    caption?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesNewsTkshNews
     */
    content?: string;
    /**
     * 
     * @type {Date}
     * @memberof KshClassesNewsTkshNews
     */
    datechanged?: Date;
    /**
     * 
     * @type {Date}
     * @memberof KshClassesNewsTkshNews
     */
    datecreated?: Date;
    /**
     * 
     * @type {string}
     * @memberof KshClassesNewsTkshNews
     */
    document?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesNewsTkshNews
     */
    documentid?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesNewsTkshNews
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesNewsTkshNews
     */
    kpid?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesNewsTkshNews
     */
    seq?: number;
    /**
     * 
     * @type {Date}
     * @memberof KshClassesNewsTkshNews
     */
    validfrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof KshClassesNewsTkshNews
     */
    validto?: Date;
    /**
     * 
     * @type {string}
     * @memberof KshClassesNewsTkshNews
     */
    url?: string;
}

/**
 * 
 * @export
 * @interface KshClassesParamstrTkshParamStr
 */
export interface KshClassesParamstrTkshParamStr {
    /**
     * 
     * @type {string}
     * @memberof KshClassesParamstrTkshParamStr
     */
    editstr?: string;
}

/**
 * 
 * @export
 * @interface KshClassesPhotoTkshPhoto
 */
export interface KshClassesPhotoTkshPhoto {
    /**
     * 
     * @type {string}
     * @memberof KshClassesPhotoTkshPhoto
     */
    img?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesPhotoTkshPhoto
     */
    kpid?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesPhotoTkshPhoto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPhotoTkshPhoto
     */
    mimetype?: string;
}

/**
 * 
 * @export
 * @interface KshClassesPostaladdressTkshPostalAddress
 */
export interface KshClassesPostaladdressTkshPostalAddress {
    /**
     * 
     * @type {number}
     * @memberof KshClassesPostaladdressTkshPostalAddress
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesPostaladdressTkshPostalAddress
     */
    apid?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPostaladdressTkshPostalAddress
     */
    additional?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPostaladdressTkshPostalAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPostaladdressTkshPostalAddress
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPostaladdressTkshPostalAddress
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPostaladdressTkshPostalAddress
     */
    countrystr?: string;
    /**
     * 
     * @type {Date}
     * @memberof KshClassesPostaladdressTkshPostalAddress
     */
    lastconfirmed?: Date;
    /**
     * 
     * @type {number}
     * @memberof KshClassesPostaladdressTkshPostalAddress
     */
    countryid?: number;
}

/**
 * 
 * @export
 * @interface KshClassesProfessorTkshProfessor
 */
export interface KshClassesProfessorTkshProfessor {
    /**
     * 
     * @type {string}
     * @memberof KshClassesProfessorTkshProfessor
     */
    consultation?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesProfessorTkshProfessor
     */
    faculty?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesProfessorTkshProfessor
     */
    professorfor?: string;
}

/**
 * 
 * @export
 * @interface KshClassesPublicationTkshPublication
 */
export interface KshClassesPublicationTkshPublication {
    /**
     * 
     * @type {number}
     * @memberof KshClassesPublicationTkshPublication
     */
    active?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPublicationTkshPublication
     */
    caption?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesPublicationTkshPublication
     */
    publicationtype?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPublicationTkshPublication
     */
    authors?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPublicationTkshPublication
     */
    coverimage?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPublicationTkshPublication
     */
    document?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesPublicationTkshPublication
     */
    documentid?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesPublicationTkshPublication
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesPublicationTkshPublication
     */
    kpid?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesPublicationTkshPublication
     */
    publicationyear?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesPublicationTkshPublication
     */
    seq?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPublicationTkshPublication
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesPublicationTkshPublication
     */
    description?: string;
}

/**
 * 
 * @export
 * @interface KshClassesStaffTkshStaff
 */
export interface KshClassesStaffTkshStaff {
    /**
     * 
     * @type {string}
     * @memberof KshClassesStaffTkshStaff
     */
    consultation?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesStaffTkshStaff
     */
    emailsignature?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesStaffTkshStaff
     */
    functioninternal?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesStaffTkshStaff
     */
    kshemail?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesStaffTkshStaff
     */
    lrzid?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesStaffTkshStaff
     */
    phoneinternal?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesStaffTkshStaff
     */
    phonepin?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesStaffTkshStaff
     */
    roomlabel?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesStaffTkshStaff
     */
    roomnr?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesStaffTkshStaff
     */
    swcardid?: number;
}

/**
 * 
 * @export
 * @interface KshClassesStudentTkshStudent
 */
export interface KshClassesStudentTkshStudent {
    /**
     * 
     * @type {string}
     * @memberof KshClassesStudentTkshStudent
     */
    kalias?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesStudentTkshStudent
     */
    kshemail?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesStudentTkshStudent
     */
    mnr?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesStudentTkshStudent
     */
    swcardid?: number;
}

/**
 * 
 * @export
 * @interface KshClassesStudyprogramTkshStudyProgram
 */
export interface KshClassesStudyprogramTkshStudyProgram {
    /**
     * 
     * @type {number}
     * @memberof KshClassesStudyprogramTkshStudyProgram
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesStudyprogramTkshStudyProgram
     */
    caption?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesStudyprogramTkshStudyProgram
     */
    semester?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesStudyprogramTkshStudyProgram
     */
    _short?: string;
}

/**
 * 
 * @export
 * @interface KshClassesTestdummyTkshTestDummy
 */
export interface KshClassesTestdummyTkshTestDummy {
    /**
     * 
     * @type {string}
     * @memberof KshClassesTestdummyTkshTestDummy
     */
    caption?: string;
    /**
     * 
     * @type {Date}
     * @memberof KshClassesTestdummyTkshTestDummy
     */
    datum?: Date;
    /**
     * 
     * @type {number}
     * @memberof KshClassesTestdummyTkshTestDummy
     */
    id?: number;
}

/**
 * 
 * @export
 * @interface KshClassesUserPasswordresetTkshPasswordReset
 */
export interface KshClassesUserPasswordresetTkshPasswordReset {
    /**
     * 
     * @type {Date}
     * @memberof KshClassesUserPasswordresetTkshPasswordReset
     */
    birthdate?: Date;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserPasswordresetTkshPasswordReset
     */
    login?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserPasswordresetTkshPasswordReset
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserPasswordresetTkshPasswordReset
     */
    unsec?: string;
}

/**
 * 
 * @export
 * @interface KshClassesUserTkshUser
 */
export interface KshClassesUserTkshUser {
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    birthname?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    middlename?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    citizenship1?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    citizenship2?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    birthcountry?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    firstname?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesUserTkshUser
     */
    gender?: number;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    birthdate?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    login?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    mobilephone?: string;
    /**
     * 
     * @type {string}
     * @memberof KshClassesUserTkshUser
     */
    birthcity?: string;
    /**
     * 
     * @type {number}
     * @memberof KshClassesUserTkshUser
     */
    kpid?: number;
    /**
     * 
     * @type {number}
     * @memberof KshClassesUserTkshUser
     */
    reviewdata?: number;
}

/**
 * 
 * @export
 * @interface KshXdataServicePortalToolsTkshAddressCode
 */
export interface KshXdataServicePortalToolsTkshAddressCode {
    /**
     * 
     * @type {string}
     * @memberof KshXdataServicePortalToolsTkshAddressCode
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof KshXdataServicePortalToolsTkshAddressCode
     */
    districtid?: string;
    /**
     * 
     * @type {string}
     * @memberof KshXdataServicePortalToolsTkshAddressCode
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof KshXdataServicePortalToolsTkshAddressCode
     */
    stateid?: string;
    /**
     * 
     * @type {string}
     * @memberof KshXdataServicePortalToolsTkshAddressCode
     */
    stateshort?: string;
    /**
     * 
     * @type {string}
     * @memberof KshXdataServicePortalToolsTkshAddressCode
     */
    zip?: string;
}

/**
 * 
 * @export
 * @interface SystemGenericsCollectionsTListSystemInteger
 */
export interface SystemGenericsCollectionsTListSystemInteger extends Array<number> {
}

/**
 * 
 * @export
 * @interface SystemGenericsCollectionsTListkshClassesCareerTkshCareer
 */
export interface SystemGenericsCollectionsTListkshClassesCareerTkshCareer extends Array<KshClassesCareerTkshCareer> {
}

/**
 * 
 * @export
 * @interface SystemGenericsCollectionsTListkshClassesCountrycodeTkshCountryCode
 */
export interface SystemGenericsCollectionsTListkshClassesCountrycodeTkshCountryCode extends Array<KshClassesCountrycodeTkshCountryCode> {
}

/**
 * 
 * @export
 * @interface SystemGenericsCollectionsTListkshClassesEmailAliasTkshEmailAlias
 */
export interface SystemGenericsCollectionsTListkshClassesEmailAliasTkshEmailAlias extends Array<KshClassesEmailAliasTkshEmailAlias> {
}

/**
 * 
 * @export
 * @interface SystemGenericsCollectionsTListkshClassesGroupTkshGroup
 */
export interface SystemGenericsCollectionsTListkshClassesGroupTkshGroup extends Array<KshClassesGroupTkshGroup> {
}

/**
 * 
 * @export
 * @interface SystemGenericsCollectionsTListkshClassesNewsTkshNews
 */
export interface SystemGenericsCollectionsTListkshClassesNewsTkshNews extends Array<KshClassesNewsTkshNews> {
}

/**
 * 
 * @export
 * @interface SystemGenericsCollectionsTListkshClassesPublicationTkshPublication
 */
export interface SystemGenericsCollectionsTListkshClassesPublicationTkshPublication extends Array<KshClassesPublicationTkshPublication> {
}

/**
 * 
 * @export
 * @interface SystemGenericsCollectionsTListkshClassesStudyprogramTkshStudyProgram
 */
export interface SystemGenericsCollectionsTListkshClassesStudyprogramTkshStudyProgram extends Array<KshClassesStudyprogramTkshStudyProgram> {
}

/**
 * 
 * @export
 * @interface SystemJSONTJSONObject
 */
export interface SystemJSONTJSONObject {
}


/**
 * AdminApi - fetch parameter creator
 * @export
 */
export const AdminApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshAdminServiceReadSystemValues(options: any = {}): FetchArgs {
            const localVarPath = `/Admin/ReadSystemValues`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshAdminServiceReadSystemValues(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).ikshAdminServiceReadSystemValues(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshAdminServiceReadSystemValues(options?: any) {
            return AdminApiFp(configuration).ikshAdminServiceReadSystemValues(options)(fetch, basePath);
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public ikshAdminServiceReadSystemValues(options?: any) {
        return AdminApiFp(this.configuration).ikshAdminServiceReadSystemValues(options)(this.fetch, this.basePath);
    }

}

/**
 * EmailApi - fetch parameter creator
 * @export
 */
export const EmailApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceAliasLecturerRead(options: any = {}): FetchArgs {
            const localVarPath = `/Email/AliasLecturerRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceAliasList(options: any = {}): FetchArgs {
            const localVarPath = `/Email/AliasList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceAliasStudentRead(options: any = {}): FetchArgs {
            const localVarPath = `/Email/AliasStudentRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchLecturerToAccount(body: KshClassesEmailAliasTkshEmailAlias, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshEmailServiceSwitchLecturerToAccount.');
            }
            const localVarPath = `/Email/SwitchLecturerToAccount`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesEmailAliasTkshEmailAlias" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchLecturerToForwarding(body: KshClassesEmailAliasTkshEmailAlias, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshEmailServiceSwitchLecturerToForwarding.');
            }
            const localVarPath = `/Email/SwitchLecturerToForwarding`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesEmailAliasTkshEmailAlias" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchStudentToAccount(body: KshClassesEmailAliasTkshEmailAlias, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshEmailServiceSwitchStudentToAccount.');
            }
            const localVarPath = `/Email/SwitchStudentToAccount`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesEmailAliasTkshEmailAlias" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchStudentToForwarding(body: KshClassesEmailAliasTkshEmailAlias, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshEmailServiceSwitchStudentToForwarding.');
            }
            const localVarPath = `/Email/SwitchStudentToForwarding`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesEmailAliasTkshEmailAlias" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceAliasLecturerRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesEmailAliasTkshEmailAlias> {
            const localVarFetchArgs = EmailApiFetchParamCreator(configuration).ikshEmailServiceAliasLecturerRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceAliasList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = EmailApiFetchParamCreator(configuration).ikshEmailServiceAliasList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceAliasStudentRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesEmailAliasTkshEmailAlias> {
            const localVarFetchArgs = EmailApiFetchParamCreator(configuration).ikshEmailServiceAliasStudentRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchLecturerToAccount(body: KshClassesEmailAliasTkshEmailAlias, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesEmailAliasTkshEmailAlias> {
            const localVarFetchArgs = EmailApiFetchParamCreator(configuration).ikshEmailServiceSwitchLecturerToAccount(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchLecturerToForwarding(body: KshClassesEmailAliasTkshEmailAlias, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesEmailAliasTkshEmailAlias> {
            const localVarFetchArgs = EmailApiFetchParamCreator(configuration).ikshEmailServiceSwitchLecturerToForwarding(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchStudentToAccount(body: KshClassesEmailAliasTkshEmailAlias, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesEmailAliasTkshEmailAlias> {
            const localVarFetchArgs = EmailApiFetchParamCreator(configuration).ikshEmailServiceSwitchStudentToAccount(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchStudentToForwarding(body: KshClassesEmailAliasTkshEmailAlias, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesEmailAliasTkshEmailAlias> {
            const localVarFetchArgs = EmailApiFetchParamCreator(configuration).ikshEmailServiceSwitchStudentToForwarding(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceAliasLecturerRead(options?: any) {
            return EmailApiFp(configuration).ikshEmailServiceAliasLecturerRead(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceAliasList(options?: any) {
            return EmailApiFp(configuration).ikshEmailServiceAliasList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceAliasStudentRead(options?: any) {
            return EmailApiFp(configuration).ikshEmailServiceAliasStudentRead(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchLecturerToAccount(body: KshClassesEmailAliasTkshEmailAlias, options?: any) {
            return EmailApiFp(configuration).ikshEmailServiceSwitchLecturerToAccount(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchLecturerToForwarding(body: KshClassesEmailAliasTkshEmailAlias, options?: any) {
            return EmailApiFp(configuration).ikshEmailServiceSwitchLecturerToForwarding(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchStudentToAccount(body: KshClassesEmailAliasTkshEmailAlias, options?: any) {
            return EmailApiFp(configuration).ikshEmailServiceSwitchStudentToAccount(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesEmailAliasTkshEmailAlias} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshEmailServiceSwitchStudentToForwarding(body: KshClassesEmailAliasTkshEmailAlias, options?: any) {
            return EmailApiFp(configuration).ikshEmailServiceSwitchStudentToForwarding(body, options)(fetch, basePath);
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public ikshEmailServiceAliasLecturerRead(options?: any) {
        return EmailApiFp(this.configuration).ikshEmailServiceAliasLecturerRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public ikshEmailServiceAliasList(options?: any) {
        return EmailApiFp(this.configuration).ikshEmailServiceAliasList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public ikshEmailServiceAliasStudentRead(options?: any) {
        return EmailApiFp(this.configuration).ikshEmailServiceAliasStudentRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesEmailAliasTkshEmailAlias} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public ikshEmailServiceSwitchLecturerToAccount(body: KshClassesEmailAliasTkshEmailAlias, options?: any) {
        return EmailApiFp(this.configuration).ikshEmailServiceSwitchLecturerToAccount(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesEmailAliasTkshEmailAlias} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public ikshEmailServiceSwitchLecturerToForwarding(body: KshClassesEmailAliasTkshEmailAlias, options?: any) {
        return EmailApiFp(this.configuration).ikshEmailServiceSwitchLecturerToForwarding(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesEmailAliasTkshEmailAlias} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public ikshEmailServiceSwitchStudentToAccount(body: KshClassesEmailAliasTkshEmailAlias, options?: any) {
        return EmailApiFp(this.configuration).ikshEmailServiceSwitchStudentToAccount(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesEmailAliasTkshEmailAlias} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public ikshEmailServiceSwitchStudentToForwarding(body: KshClassesEmailAliasTkshEmailAlias, options?: any) {
        return EmailApiFp(this.configuration).ikshEmailServiceSwitchStudentToForwarding(body, options)(this.fetch, this.basePath);
    }

}

/**
 * HomepageApi - fetch parameter creator
 * @export
 */
export const HomepageApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceEmeritusProfessorList(options: any = {}): FetchArgs {
            const localVarPath = `/Homepage/EmeritusProfessorList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceEmeritusProfessorRead(kpid: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshHomepageServiceEmeritusProfessorRead.');
            }
            const localVarPath = `/Homepage/EmeritusProfessorRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceFormerProfessorList(options: any = {}): FetchArgs {
            const localVarPath = `/Homepage/FormerProfessorList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceFormerProfessorRead(kpid: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshHomepageServiceFormerProfessorRead.');
            }
            const localVarPath = `/Homepage/FormerProfessorRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceLecturerList(options: any = {}): FetchArgs {
            const localVarPath = `/Homepage/LecturerList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceLecturerRead(kpid: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshHomepageServiceLecturerRead.');
            }
            const localVarPath = `/Homepage/LecturerRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceProfessorList(options: any = {}): FetchArgs {
            const localVarPath = `/Homepage/ProfessorList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceProfessorOldList(options: any = {}): FetchArgs {
            const localVarPath = `/Homepage/ProfessorOldList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceProfessorRead(kpid: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshHomepageServiceProfessorRead.');
            }
            const localVarPath = `/Homepage/ProfessorRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceSeminarList(year: number, options: any = {}): FetchArgs {
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year', 'Required parameter year was null or undefined when calling ikshHomepageServiceSeminarList.');
            }
            const localVarPath = `/Homepage/SeminarList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceSeminarOldList(options: any = {}): FetchArgs {
            const localVarPath = `/Homepage/SeminarOldList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceSeminarRead(courseid: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseid' is not null or undefined
            if (courseid === null || courseid === undefined) {
                throw new RequiredError('courseid', 'Required parameter courseid was null or undefined when calling ikshHomepageServiceSeminarRead.');
            }
            const localVarPath = `/Homepage/SeminarRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (courseid !== undefined) {
                localVarQueryParameter['courseid'] = courseid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceTestProc(options: any = {}): FetchArgs {
            const localVarPath = `/Homepage/TestProc`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceTestStream(options: any = {}): FetchArgs {
            const localVarPath = `/Homepage/TestStream`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * HomepageApi - functional programming interface
 * @export
 */
export const HomepageApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceEmeritusProfessorList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceEmeritusProfessorList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceEmeritusProfessorRead(kpid: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceEmeritusProfessorRead(kpid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceFormerProfessorList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceFormerProfessorList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceFormerProfessorRead(kpid: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceFormerProfessorRead(kpid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceLecturerList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceLecturerList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceLecturerRead(kpid: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceLecturerRead(kpid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceProfessorList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceProfessorList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceProfessorOldList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceProfessorOldList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceProfessorRead(kpid: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceProfessorRead(kpid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceSeminarList(year: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceSeminarList(year, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceSeminarOldList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceSeminarOldList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceSeminarRead(courseid: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceSeminarRead(courseid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceTestProc(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceTestProc(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceTestStream(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HomepageApiFetchParamCreator(configuration).ikshHomepageServiceTestStream(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * HomepageApi - factory interface
 * @export
 */
export const HomepageApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceEmeritusProfessorList(options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceEmeritusProfessorList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceEmeritusProfessorRead(kpid: number, options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceEmeritusProfessorRead(kpid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceFormerProfessorList(options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceFormerProfessorList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceFormerProfessorRead(kpid: number, options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceFormerProfessorRead(kpid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceLecturerList(options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceLecturerList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceLecturerRead(kpid: number, options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceLecturerRead(kpid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceProfessorList(options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceProfessorList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceProfessorOldList(options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceProfessorOldList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceProfessorRead(kpid: number, options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceProfessorRead(kpid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceSeminarList(year: number, options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceSeminarList(year, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceSeminarOldList(options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceSeminarOldList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceSeminarRead(courseid: number, options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceSeminarRead(courseid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceTestProc(options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceTestProc(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshHomepageServiceTestStream(options?: any) {
            return HomepageApiFp(configuration).ikshHomepageServiceTestStream(options)(fetch, basePath);
        },
    };
};

/**
 * HomepageApi - object-oriented interface
 * @export
 * @class HomepageApi
 * @extends {BaseAPI}
 */
export class HomepageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceEmeritusProfessorList(options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceEmeritusProfessorList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceEmeritusProfessorRead(kpid: number, options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceEmeritusProfessorRead(kpid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceFormerProfessorList(options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceFormerProfessorList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceFormerProfessorRead(kpid: number, options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceFormerProfessorRead(kpid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceLecturerList(options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceLecturerList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceLecturerRead(kpid: number, options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceLecturerRead(kpid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceProfessorList(options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceProfessorList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceProfessorOldList(options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceProfessorOldList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceProfessorRead(kpid: number, options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceProfessorRead(kpid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceSeminarList(year: number, options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceSeminarList(year, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceSeminarOldList(options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceSeminarOldList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceSeminarRead(courseid: number, options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceSeminarRead(courseid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceTestProc(options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceTestProc(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomepageApi
     */
    public ikshHomepageServiceTestStream(options?: any) {
        return HomepageApiFp(this.configuration).ikshHomepageServiceTestStream(options)(this.fetch, this.basePath);
    }

}

/**
 * KeyTablesApi - fetch parameter creator
 * @export
 */
export const KeyTablesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iKeyTablesServiceCountryCodes(options: any = {}): FetchArgs {
            const localVarPath = `/KeyTables/CountryCodes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * KeyTablesApi - functional programming interface
 * @export
 */
export const KeyTablesApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iKeyTablesServiceCountryCodes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = KeyTablesApiFetchParamCreator(configuration).iKeyTablesServiceCountryCodes(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * KeyTablesApi - factory interface
 * @export
 */
export const KeyTablesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iKeyTablesServiceCountryCodes(options?: any) {
            return KeyTablesApiFp(configuration).iKeyTablesServiceCountryCodes(options)(fetch, basePath);
        },
    };
};

/**
 * KeyTablesApi - object-oriented interface
 * @export
 * @class KeyTablesApi
 * @extends {BaseAPI}
 */
export class KeyTablesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyTablesApi
     */
    public iKeyTablesServiceCountryCodes(options?: any) {
        return KeyTablesApiFp(this.configuration).iKeyTablesServiceCountryCodes(options)(this.fetch, this.basePath);
    }

}

/**
 * LecturerApi - fetch parameter creator
 * @export
 */
export const LecturerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshLecturerServiceDataRead(options: any = {}): FetchArgs {
            const localVarPath = `/Lecturer/DataRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesLecturerTkshLecturer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshLecturerServiceDataUpdate(body: KshClassesLecturerTkshLecturer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshLecturerServiceDataUpdate.');
            }
            const localVarPath = `/Lecturer/DataUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesLecturerTkshLecturer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * LecturerApi - functional programming interface
 * @export
 */
export const LecturerApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshLecturerServiceDataRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesLecturerTkshLecturer> {
            const localVarFetchArgs = LecturerApiFetchParamCreator(configuration).ikshLecturerServiceDataRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesLecturerTkshLecturer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshLecturerServiceDataUpdate(body: KshClassesLecturerTkshLecturer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesLecturerTkshLecturer> {
            const localVarFetchArgs = LecturerApiFetchParamCreator(configuration).ikshLecturerServiceDataUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * LecturerApi - factory interface
 * @export
 */
export const LecturerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshLecturerServiceDataRead(options?: any) {
            return LecturerApiFp(configuration).ikshLecturerServiceDataRead(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesLecturerTkshLecturer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshLecturerServiceDataUpdate(body: KshClassesLecturerTkshLecturer, options?: any) {
            return LecturerApiFp(configuration).ikshLecturerServiceDataUpdate(body, options)(fetch, basePath);
        },
    };
};

/**
 * LecturerApi - object-oriented interface
 * @export
 * @class LecturerApi
 * @extends {BaseAPI}
 */
export class LecturerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturerApi
     */
    public ikshLecturerServiceDataRead(options?: any) {
        return LecturerApiFp(this.configuration).ikshLecturerServiceDataRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesLecturerTkshLecturer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturerApi
     */
    public ikshLecturerServiceDataUpdate(body: KshClassesLecturerTkshLecturer, options?: any) {
        return LecturerApiFp(this.configuration).ikshLecturerServiceDataUpdate(body, options)(this.fetch, this.basePath);
    }

}

/**
 * OpenapiApi - fetch parameter creator
 * @export
 */
export const OpenapiApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [excludeEntities] 
         * @param {boolean} [excludeOperations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iCustomOpenApiServiceSwaggerDocument(excludeEntities?: boolean, excludeOperations?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/openapi/swagger.json`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (excludeEntities !== undefined) {
                localVarQueryParameter['ExcludeEntities'] = excludeEntities;
            }

            if (excludeOperations !== undefined) {
                localVarQueryParameter['ExcludeOperations'] = excludeOperations;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * OpenapiApi - functional programming interface
 * @export
 */
export const OpenapiApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [excludeEntities] 
         * @param {boolean} [excludeOperations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iCustomOpenApiServiceSwaggerDocument(excludeEntities?: boolean, excludeOperations?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = OpenapiApiFetchParamCreator(configuration).iCustomOpenApiServiceSwaggerDocument(excludeEntities, excludeOperations, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * OpenapiApi - factory interface
 * @export
 */
export const OpenapiApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {boolean} [excludeEntities] 
         * @param {boolean} [excludeOperations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iCustomOpenApiServiceSwaggerDocument(excludeEntities?: boolean, excludeOperations?: boolean, options?: any) {
            return OpenapiApiFp(configuration).iCustomOpenApiServiceSwaggerDocument(excludeEntities, excludeOperations, options)(fetch, basePath);
        },
    };
};

/**
 * OpenapiApi - object-oriented interface
 * @export
 * @class OpenapiApi
 * @extends {BaseAPI}
 */
export class OpenapiApi extends BaseAPI {
    /**
     * 
     * @param {boolean} [excludeEntities] 
     * @param {boolean} [excludeOperations] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenapiApi
     */
    public iCustomOpenApiServiceSwaggerDocument(excludeEntities?: boolean, excludeOperations?: boolean, options?: any) {
        return OpenapiApiFp(this.configuration).iCustomOpenApiServiceSwaggerDocument(excludeEntities, excludeOperations, options)(this.fetch, this.basePath);
    }

}

/**
 * ProfessorApi - fetch parameter creator
 * @export
 */
export const ProfessorApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerCreate(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceCareerCreate.');
            }
            const localVarPath = `/Professor/CareerCreate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerDelete(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceCareerDelete.');
            }
            const localVarPath = `/Professor/CareerDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerList(options: any = {}): FetchArgs {
            const localVarPath = `/Professor/CareerList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerMoveDown(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceCareerMoveDown.');
            }
            const localVarPath = `/Professor/CareerMoveDown`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerMoveUp(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceCareerMoveUp.');
            }
            const localVarPath = `/Professor/CareerMoveUp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerRead(kpid: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshProfessorServiceCareerRead.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling ikshProfessorServiceCareerRead.');
            }
            const localVarPath = `/Professor/CareerRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerUpdate(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceCareerUpdate.');
            }
            const localVarPath = `/Professor/CareerUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceDataRead(options: any = {}): FetchArgs {
            const localVarPath = `/Professor/DataRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesProfessorTkshProfessor} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceDataUpdate(body: KshClassesProfessorTkshProfessor, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceDataUpdate.');
            }
            const localVarPath = `/Professor/DataUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesProfessorTkshProfessor" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalCreate(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFunctionExternalCreate.');
            }
            const localVarPath = `/Professor/FunctionExternalCreate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalDelete(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFunctionExternalDelete.');
            }
            const localVarPath = `/Professor/FunctionExternalDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalList(options: any = {}): FetchArgs {
            const localVarPath = `/Professor/FunctionExternalList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalMoveDown(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFunctionExternalMoveDown.');
            }
            const localVarPath = `/Professor/FunctionExternalMoveDown`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalMoveUp(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFunctionExternalMoveUp.');
            }
            const localVarPath = `/Professor/FunctionExternalMoveUp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalRead(kpid: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshProfessorServiceFunctionExternalRead.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling ikshProfessorServiceFunctionExternalRead.');
            }
            const localVarPath = `/Professor/FunctionExternalRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalUpdate(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFunctionExternalUpdate.');
            }
            const localVarPath = `/Professor/FunctionExternalUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshCreate(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFunctionKshCreate.');
            }
            const localVarPath = `/Professor/FunctionKshCreate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshDelete(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFunctionKshDelete.');
            }
            const localVarPath = `/Professor/FunctionKshDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshList(options: any = {}): FetchArgs {
            const localVarPath = `/Professor/FunctionKshList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshMoveDown(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFunctionKshMoveDown.');
            }
            const localVarPath = `/Professor/FunctionKshMoveDown`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshMoveUp(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFunctionKshMoveUp.');
            }
            const localVarPath = `/Professor/FunctionKshMoveUp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshRead(kpid: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshProfessorServiceFunctionKshRead.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling ikshProfessorServiceFunctionKshRead.');
            }
            const localVarPath = `/Professor/FunctionKshRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshUpdate(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFunctionKshUpdate.');
            }
            const localVarPath = `/Professor/FunctionKshUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationCreate(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFurtherEducationCreate.');
            }
            const localVarPath = `/Professor/FurtherEducationCreate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationDelete(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFurtherEducationDelete.');
            }
            const localVarPath = `/Professor/FurtherEducationDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationList(options: any = {}): FetchArgs {
            const localVarPath = `/Professor/FurtherEducationList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationMoveDown(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFurtherEducationMoveDown.');
            }
            const localVarPath = `/Professor/FurtherEducationMoveDown`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationMoveUp(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFurtherEducationMoveUp.');
            }
            const localVarPath = `/Professor/FurtherEducationMoveUp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationRead(kpid: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshProfessorServiceFurtherEducationRead.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling ikshProfessorServiceFurtherEducationRead.');
            }
            const localVarPath = `/Professor/FurtherEducationRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationUpdate(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceFurtherEducationUpdate.');
            }
            const localVarPath = `/Professor/FurtherEducationUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} lastChanged 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLastChangedIDList(lastChanged: string, options: any = {}): FetchArgs {
            // verify required parameter 'lastChanged' is not null or undefined
            if (lastChanged === null || lastChanged === undefined) {
                throw new RequiredError('lastChanged', 'Required parameter lastChanged was null or undefined when calling ikshProfessorServiceLastChangedIDList.');
            }
            const localVarPath = `/Professor/LastChangedIDList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lastChanged !== undefined) {
                localVarQueryParameter['LastChanged'] = lastChanged;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureCreate(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceLectureCreate.');
            }
            const localVarPath = `/Professor/LectureCreate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureDelete(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceLectureDelete.');
            }
            const localVarPath = `/Professor/LectureDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureFileDelete(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceLectureFileDelete.');
            }
            const localVarPath = `/Professor/LectureFileDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureFileLoad(kpid: number, ID: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshProfessorServiceLectureFileLoad.');
            }
            // verify required parameter 'ID' is not null or undefined
            if (ID === null || ID === undefined) {
                throw new RequiredError('ID', 'Required parameter ID was null or undefined when calling ikshProfessorServiceLectureFileLoad.');
            }
            const localVarPath = `/Professor/LectureFileLoad`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['Kpid'] = kpid;
            }

            if (ID !== undefined) {
                localVarQueryParameter['ID'] = ID;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesFileloadTkshFileLoad} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureFileSave(body: KshClassesFileloadTkshFileLoad, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceLectureFileSave.');
            }
            const localVarPath = `/Professor/LectureFileSave`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesFileloadTkshFileLoad" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureList(options: any = {}): FetchArgs {
            const localVarPath = `/Professor/LectureList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureMoveDown(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceLectureMoveDown.');
            }
            const localVarPath = `/Professor/LectureMoveDown`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureMoveUp(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceLectureMoveUp.');
            }
            const localVarPath = `/Professor/LectureMoveUp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureRead(kpid: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshProfessorServiceLectureRead.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling ikshProfessorServiceLectureRead.');
            }
            const localVarPath = `/Professor/LectureRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureUpdate(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceLectureUpdate.');
            }
            const localVarPath = `/Professor/LectureUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusCreate(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceMainFocusCreate.');
            }
            const localVarPath = `/Professor/MainFocusCreate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusDelete(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceMainFocusDelete.');
            }
            const localVarPath = `/Professor/MainFocusDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusList(options: any = {}): FetchArgs {
            const localVarPath = `/Professor/MainFocusList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusMoveDown(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceMainFocusMoveDown.');
            }
            const localVarPath = `/Professor/MainFocusMoveDown`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusMoveUp(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceMainFocusMoveUp.');
            }
            const localVarPath = `/Professor/MainFocusMoveUp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusRead(kpid: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshProfessorServiceMainFocusRead.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling ikshProfessorServiceMainFocusRead.');
            }
            const localVarPath = `/Professor/MainFocusRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusUpdate(body: KshClassesCareerTkshCareer, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceMainFocusUpdate.');
            }
            const localVarPath = `/Professor/MainFocusUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesCareerTkshCareer" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceNewsList(expand?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Professor/NewsList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expand !== undefined) {
                localVarQueryParameter['$expand'] = expand;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesNewsTkshNews} body 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceNewsUpdate(body: KshClassesNewsTkshNews, expand?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceNewsUpdate.');
            }
            const localVarPath = `/Professor/NewsUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (expand !== undefined) {
                localVarQueryParameter['$expand'] = expand;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesNewsTkshNews" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePhotoRead(options: any = {}): FetchArgs {
            const localVarPath = `/Professor/PhotoRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationCreate(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServicePublicationCreate.');
            }
            const localVarPath = `/Professor/PublicationCreate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationDelete(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServicePublicationDelete.');
            }
            const localVarPath = `/Professor/PublicationDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationFileDelete(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServicePublicationFileDelete.');
            }
            const localVarPath = `/Professor/PublicationFileDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationFileLoad(kpid: number, ID: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshProfessorServicePublicationFileLoad.');
            }
            // verify required parameter 'ID' is not null or undefined
            if (ID === null || ID === undefined) {
                throw new RequiredError('ID', 'Required parameter ID was null or undefined when calling ikshProfessorServicePublicationFileLoad.');
            }
            const localVarPath = `/Professor/PublicationFileLoad`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['Kpid'] = kpid;
            }

            if (ID !== undefined) {
                localVarQueryParameter['ID'] = ID;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesFileloadTkshFileLoad} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationFileSave(body: KshClassesFileloadTkshFileLoad, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServicePublicationFileSave.');
            }
            const localVarPath = `/Professor/PublicationFileSave`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesFileloadTkshFileLoad" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationList(options: any = {}): FetchArgs {
            const localVarPath = `/Professor/PublicationList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationMoveDown(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServicePublicationMoveDown.');
            }
            const localVarPath = `/Professor/PublicationMoveDown`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationMoveUp(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServicePublicationMoveUp.');
            }
            const localVarPath = `/Professor/PublicationMoveUp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationRead(kpid: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshProfessorServicePublicationRead.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling ikshProfessorServicePublicationRead.');
            }
            const localVarPath = `/Professor/PublicationRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationUpdate(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServicePublicationUpdate.');
            }
            const localVarPath = `/Professor/PublicationUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchCreate(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceResearchCreate.');
            }
            const localVarPath = `/Professor/ResearchCreate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchDelete(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceResearchDelete.');
            }
            const localVarPath = `/Professor/ResearchDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchFileDelete(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceResearchFileDelete.');
            }
            const localVarPath = `/Professor/ResearchFileDelete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchFileLoad(kpid: number, ID: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshProfessorServiceResearchFileLoad.');
            }
            // verify required parameter 'ID' is not null or undefined
            if (ID === null || ID === undefined) {
                throw new RequiredError('ID', 'Required parameter ID was null or undefined when calling ikshProfessorServiceResearchFileLoad.');
            }
            const localVarPath = `/Professor/ResearchFileLoad`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['Kpid'] = kpid;
            }

            if (ID !== undefined) {
                localVarQueryParameter['ID'] = ID;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesFileloadTkshFileLoad} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchFileSave(body: KshClassesFileloadTkshFileLoad, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceResearchFileSave.');
            }
            const localVarPath = `/Professor/ResearchFileSave`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesFileloadTkshFileLoad" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchList(options: any = {}): FetchArgs {
            const localVarPath = `/Professor/ResearchList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchMoveDown(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceResearchMoveDown.');
            }
            const localVarPath = `/Professor/ResearchMoveDown`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchMoveUp(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceResearchMoveUp.');
            }
            const localVarPath = `/Professor/ResearchMoveUp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchRead(kpid: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling ikshProfessorServiceResearchRead.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling ikshProfessorServiceResearchRead.');
            }
            const localVarPath = `/Professor/ResearchRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchUpdate(body: KshClassesPublicationTkshPublication, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshProfessorServiceResearchUpdate.');
            }
            const localVarPath = `/Professor/ResearchUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPublicationTkshPublication" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ProfessorApi - functional programming interface
 * @export
 */
export const ProfessorApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerCreate(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceCareerCreate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerDelete(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceCareerDelete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceCareerList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerMoveDown(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceCareerMoveDown(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerMoveUp(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceCareerMoveUp(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerRead(kpid: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesCareerTkshCareer> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceCareerRead(kpid, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerUpdate(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceCareerUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceDataRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesProfessorTkshProfessor> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceDataRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesProfessorTkshProfessor} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceDataUpdate(body: KshClassesProfessorTkshProfessor, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesProfessorTkshProfessor> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceDataUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalCreate(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionExternalCreate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalDelete(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionExternalDelete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionExternalList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalMoveDown(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionExternalMoveDown(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalMoveUp(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionExternalMoveUp(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalRead(kpid: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesCareerTkshCareer> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionExternalRead(kpid, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalUpdate(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionExternalUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshCreate(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionKshCreate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshDelete(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionKshDelete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionKshList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshMoveDown(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionKshMoveDown(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshMoveUp(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionKshMoveUp(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshRead(kpid: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesCareerTkshCareer> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionKshRead(kpid, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshUpdate(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFunctionKshUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationCreate(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFurtherEducationCreate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationDelete(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFurtherEducationDelete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFurtherEducationList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationMoveDown(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFurtherEducationMoveDown(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationMoveUp(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFurtherEducationMoveUp(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationRead(kpid: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesCareerTkshCareer> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFurtherEducationRead(kpid, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationUpdate(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceFurtherEducationUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} lastChanged 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLastChangedIDList(lastChanged: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceLastChangedIDList(lastChanged, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureCreate(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceLectureCreate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureDelete(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceLectureDelete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureFileDelete(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceLectureFileDelete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureFileLoad(kpid: number, ID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceLectureFileLoad(kpid, ID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesFileloadTkshFileLoad} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureFileSave(body: KshClassesFileloadTkshFileLoad, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesPublicationTkshPublication> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceLectureFileSave(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceLectureList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureMoveDown(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceLectureMoveDown(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureMoveUp(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceLectureMoveUp(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureRead(kpid: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesPublicationTkshPublication> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceLectureRead(kpid, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureUpdate(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceLectureUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusCreate(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceMainFocusCreate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusDelete(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceMainFocusDelete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceMainFocusList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusMoveDown(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceMainFocusMoveDown(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusMoveUp(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceMainFocusMoveUp(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusRead(kpid: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesCareerTkshCareer> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceMainFocusRead(kpid, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusUpdate(body: KshClassesCareerTkshCareer, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceMainFocusUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceNewsList(expand?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2006> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceNewsList(expand, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesNewsTkshNews} body 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceNewsUpdate(body: KshClassesNewsTkshNews, expand?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesNewsTkshNews> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceNewsUpdate(body, expand, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePhotoRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesPhotoTkshPhoto> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServicePhotoRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationCreate(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServicePublicationCreate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationDelete(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServicePublicationDelete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationFileDelete(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServicePublicationFileDelete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationFileLoad(kpid: number, ID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServicePublicationFileLoad(kpid, ID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesFileloadTkshFileLoad} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationFileSave(body: KshClassesFileloadTkshFileLoad, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesPublicationTkshPublication> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServicePublicationFileSave(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServicePublicationList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationMoveDown(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServicePublicationMoveDown(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationMoveUp(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServicePublicationMoveUp(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationRead(kpid: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesPublicationTkshPublication> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServicePublicationRead(kpid, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationUpdate(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServicePublicationUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchCreate(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceResearchCreate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchDelete(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceResearchDelete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchFileDelete(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceResearchFileDelete(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchFileLoad(kpid: number, ID: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceResearchFileLoad(kpid, ID, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesFileloadTkshFileLoad} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchFileSave(body: KshClassesFileloadTkshFileLoad, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesPublicationTkshPublication> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceResearchFileSave(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceResearchList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchMoveDown(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceResearchMoveDown(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchMoveUp(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceResearchMoveUp(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchRead(kpid: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesPublicationTkshPublication> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceResearchRead(kpid, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchUpdate(body: KshClassesPublicationTkshPublication, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2005> {
            const localVarFetchArgs = ProfessorApiFetchParamCreator(configuration).ikshProfessorServiceResearchUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * ProfessorApi - factory interface
 * @export
 */
export const ProfessorApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerCreate(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceCareerCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerDelete(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceCareerDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerList(options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceCareerList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerMoveDown(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceCareerMoveDown(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerMoveUp(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceCareerMoveUp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerRead(kpid: number, id: number, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceCareerRead(kpid, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceCareerUpdate(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceCareerUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceDataRead(options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceDataRead(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesProfessorTkshProfessor} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceDataUpdate(body: KshClassesProfessorTkshProfessor, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceDataUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalCreate(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionExternalCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalDelete(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionExternalDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalList(options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionExternalList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalMoveDown(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionExternalMoveDown(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalMoveUp(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionExternalMoveUp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalRead(kpid: number, id: number, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionExternalRead(kpid, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionExternalUpdate(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionExternalUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshCreate(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionKshCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshDelete(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionKshDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshList(options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionKshList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshMoveDown(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionKshMoveDown(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshMoveUp(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionKshMoveUp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshRead(kpid: number, id: number, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionKshRead(kpid, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFunctionKshUpdate(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFunctionKshUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationCreate(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFurtherEducationCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationDelete(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFurtherEducationDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationList(options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFurtherEducationList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationMoveDown(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFurtherEducationMoveDown(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationMoveUp(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFurtherEducationMoveUp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationRead(kpid: number, id: number, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFurtherEducationRead(kpid, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceFurtherEducationUpdate(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceFurtherEducationUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} lastChanged 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLastChangedIDList(lastChanged: string, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceLastChangedIDList(lastChanged, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureCreate(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceLectureCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureDelete(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceLectureDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureFileDelete(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceLectureFileDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureFileLoad(kpid: number, ID: number, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceLectureFileLoad(kpid, ID, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesFileloadTkshFileLoad} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureFileSave(body: KshClassesFileloadTkshFileLoad, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceLectureFileSave(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureList(options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceLectureList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureMoveDown(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceLectureMoveDown(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureMoveUp(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceLectureMoveUp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureRead(kpid: number, id: number, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceLectureRead(kpid, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceLectureUpdate(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceLectureUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusCreate(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceMainFocusCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusDelete(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceMainFocusDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusList(options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceMainFocusList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusMoveDown(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceMainFocusMoveDown(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusMoveUp(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceMainFocusMoveUp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusRead(kpid: number, id: number, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceMainFocusRead(kpid, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesCareerTkshCareer} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceMainFocusUpdate(body: KshClassesCareerTkshCareer, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceMainFocusUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceNewsList(expand?: string, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceNewsList(expand, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesNewsTkshNews} body 
         * @param {string} [expand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceNewsUpdate(body: KshClassesNewsTkshNews, expand?: string, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceNewsUpdate(body, expand, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePhotoRead(options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServicePhotoRead(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationCreate(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServicePublicationCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationDelete(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServicePublicationDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationFileDelete(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServicePublicationFileDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationFileLoad(kpid: number, ID: number, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServicePublicationFileLoad(kpid, ID, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesFileloadTkshFileLoad} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationFileSave(body: KshClassesFileloadTkshFileLoad, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServicePublicationFileSave(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationList(options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServicePublicationList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationMoveDown(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServicePublicationMoveDown(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationMoveUp(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServicePublicationMoveUp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationRead(kpid: number, id: number, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServicePublicationRead(kpid, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServicePublicationUpdate(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServicePublicationUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchCreate(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceResearchCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchDelete(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceResearchDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchFileDelete(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceResearchFileDelete(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchFileLoad(kpid: number, ID: number, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceResearchFileLoad(kpid, ID, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesFileloadTkshFileLoad} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchFileSave(body: KshClassesFileloadTkshFileLoad, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceResearchFileSave(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchList(options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceResearchList(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchMoveDown(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceResearchMoveDown(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchMoveUp(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceResearchMoveUp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchRead(kpid: number, id: number, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceResearchRead(kpid, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPublicationTkshPublication} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshProfessorServiceResearchUpdate(body: KshClassesPublicationTkshPublication, options?: any) {
            return ProfessorApiFp(configuration).ikshProfessorServiceResearchUpdate(body, options)(fetch, basePath);
        },
    };
};

/**
 * ProfessorApi - object-oriented interface
 * @export
 * @class ProfessorApi
 * @extends {BaseAPI}
 */
export class ProfessorApi extends BaseAPI {
    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceCareerCreate(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceCareerCreate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceCareerDelete(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceCareerDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceCareerList(options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceCareerList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceCareerMoveDown(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceCareerMoveDown(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceCareerMoveUp(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceCareerMoveUp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceCareerRead(kpid: number, id: number, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceCareerRead(kpid, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceCareerUpdate(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceCareerUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceDataRead(options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceDataRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesProfessorTkshProfessor} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceDataUpdate(body: KshClassesProfessorTkshProfessor, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceDataUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionExternalCreate(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionExternalCreate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionExternalDelete(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionExternalDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionExternalList(options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionExternalList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionExternalMoveDown(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionExternalMoveDown(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionExternalMoveUp(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionExternalMoveUp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionExternalRead(kpid: number, id: number, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionExternalRead(kpid, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionExternalUpdate(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionExternalUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionKshCreate(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionKshCreate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionKshDelete(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionKshDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionKshList(options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionKshList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionKshMoveDown(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionKshMoveDown(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionKshMoveUp(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionKshMoveUp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionKshRead(kpid: number, id: number, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionKshRead(kpid, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFunctionKshUpdate(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFunctionKshUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFurtherEducationCreate(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFurtherEducationCreate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFurtherEducationDelete(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFurtherEducationDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFurtherEducationList(options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFurtherEducationList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFurtherEducationMoveDown(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFurtherEducationMoveDown(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFurtherEducationMoveUp(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFurtherEducationMoveUp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFurtherEducationRead(kpid: number, id: number, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFurtherEducationRead(kpid, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceFurtherEducationUpdate(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceFurtherEducationUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} lastChanged 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceLastChangedIDList(lastChanged: string, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceLastChangedIDList(lastChanged, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceLectureCreate(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceLectureCreate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceLectureDelete(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceLectureDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceLectureFileDelete(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceLectureFileDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {number} ID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceLectureFileLoad(kpid: number, ID: number, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceLectureFileLoad(kpid, ID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesFileloadTkshFileLoad} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceLectureFileSave(body: KshClassesFileloadTkshFileLoad, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceLectureFileSave(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceLectureList(options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceLectureList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceLectureMoveDown(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceLectureMoveDown(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceLectureMoveUp(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceLectureMoveUp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceLectureRead(kpid: number, id: number, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceLectureRead(kpid, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceLectureUpdate(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceLectureUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceMainFocusCreate(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceMainFocusCreate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceMainFocusDelete(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceMainFocusDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceMainFocusList(options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceMainFocusList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceMainFocusMoveDown(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceMainFocusMoveDown(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceMainFocusMoveUp(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceMainFocusMoveUp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceMainFocusRead(kpid: number, id: number, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceMainFocusRead(kpid, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesCareerTkshCareer} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceMainFocusUpdate(body: KshClassesCareerTkshCareer, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceMainFocusUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [expand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceNewsList(expand?: string, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceNewsList(expand, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesNewsTkshNews} body 
     * @param {string} [expand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceNewsUpdate(body: KshClassesNewsTkshNews, expand?: string, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceNewsUpdate(body, expand, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServicePhotoRead(options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServicePhotoRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServicePublicationCreate(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServicePublicationCreate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServicePublicationDelete(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServicePublicationDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServicePublicationFileDelete(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServicePublicationFileDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {number} ID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServicePublicationFileLoad(kpid: number, ID: number, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServicePublicationFileLoad(kpid, ID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesFileloadTkshFileLoad} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServicePublicationFileSave(body: KshClassesFileloadTkshFileLoad, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServicePublicationFileSave(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServicePublicationList(options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServicePublicationList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServicePublicationMoveDown(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServicePublicationMoveDown(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServicePublicationMoveUp(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServicePublicationMoveUp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServicePublicationRead(kpid: number, id: number, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServicePublicationRead(kpid, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServicePublicationUpdate(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServicePublicationUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceResearchCreate(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceResearchCreate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceResearchDelete(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceResearchDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceResearchFileDelete(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceResearchFileDelete(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {number} ID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceResearchFileLoad(kpid: number, ID: number, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceResearchFileLoad(kpid, ID, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesFileloadTkshFileLoad} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceResearchFileSave(body: KshClassesFileloadTkshFileLoad, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceResearchFileSave(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceResearchList(options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceResearchList(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceResearchMoveDown(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceResearchMoveDown(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceResearchMoveUp(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceResearchMoveUp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceResearchRead(kpid: number, id: number, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceResearchRead(kpid, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPublicationTkshPublication} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessorApi
     */
    public ikshProfessorServiceResearchUpdate(body: KshClassesPublicationTkshPublication, options?: any) {
        return ProfessorApiFp(this.configuration).ikshProfessorServiceResearchUpdate(body, options)(this.fetch, this.basePath);
    }

}

/**
 * StaffApi - fetch parameter creator
 * @export
 */
export const StaffApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStaffServiceDataRead(options: any = {}): FetchArgs {
            const localVarPath = `/Staff/DataRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesStaffTkshStaff} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStaffServiceDataUpdate(body: KshClassesStaffTkshStaff, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshStaffServiceDataUpdate.');
            }
            const localVarPath = `/Staff/DataUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesStaffTkshStaff" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * StaffApi - functional programming interface
 * @export
 */
export const StaffApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStaffServiceDataRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesStaffTkshStaff> {
            const localVarFetchArgs = StaffApiFetchParamCreator(configuration).ikshStaffServiceDataRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesStaffTkshStaff} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStaffServiceDataUpdate(body: KshClassesStaffTkshStaff, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = StaffApiFetchParamCreator(configuration).ikshStaffServiceDataUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * StaffApi - factory interface
 * @export
 */
export const StaffApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStaffServiceDataRead(options?: any) {
            return StaffApiFp(configuration).ikshStaffServiceDataRead(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesStaffTkshStaff} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStaffServiceDataUpdate(body: KshClassesStaffTkshStaff, options?: any) {
            return StaffApiFp(configuration).ikshStaffServiceDataUpdate(body, options)(fetch, basePath);
        },
    };
};

/**
 * StaffApi - object-oriented interface
 * @export
 * @class StaffApi
 * @extends {BaseAPI}
 */
export class StaffApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public ikshStaffServiceDataRead(options?: any) {
        return StaffApiFp(this.configuration).ikshStaffServiceDataRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesStaffTkshStaff} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public ikshStaffServiceDataUpdate(body: KshClassesStaffTkshStaff, options?: any) {
        return StaffApiFp(this.configuration).ikshStaffServiceDataUpdate(body, options)(this.fetch, this.basePath);
    }

}

/**
 * StudentApi - fetch parameter creator
 * @export
 */
export const StudentApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServiceDataRead(options: any = {}): FetchArgs {
            const localVarPath = `/Student/DataRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServicePhotoRead(options: any = {}): FetchArgs {
            const localVarPath = `/Student/PhotoRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPhotoTkshPhoto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServicePhotoUpdate(body: KshClassesPhotoTkshPhoto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshStudentServicePhotoUpdate.');
            }
            const localVarPath = `/Student/PhotoUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPhotoTkshPhoto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServiceProgramList(options: any = {}): FetchArgs {
            const localVarPath = `/Student/ProgramList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * StudentApi - functional programming interface
 * @export
 */
export const StudentApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServiceDataRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesStudentTkshStudent> {
            const localVarFetchArgs = StudentApiFetchParamCreator(configuration).ikshStudentServiceDataRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServicePhotoRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesPhotoTkshPhoto> {
            const localVarFetchArgs = StudentApiFetchParamCreator(configuration).ikshStudentServicePhotoRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPhotoTkshPhoto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServicePhotoUpdate(body: KshClassesPhotoTkshPhoto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = StudentApiFetchParamCreator(configuration).ikshStudentServicePhotoUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServiceProgramList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2007> {
            const localVarFetchArgs = StudentApiFetchParamCreator(configuration).ikshStudentServiceProgramList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * StudentApi - factory interface
 * @export
 */
export const StudentApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServiceDataRead(options?: any) {
            return StudentApiFp(configuration).ikshStudentServiceDataRead(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServicePhotoRead(options?: any) {
            return StudentApiFp(configuration).ikshStudentServicePhotoRead(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPhotoTkshPhoto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServicePhotoUpdate(body: KshClassesPhotoTkshPhoto, options?: any) {
            return StudentApiFp(configuration).ikshStudentServicePhotoUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshStudentServiceProgramList(options?: any) {
            return StudentApiFp(configuration).ikshStudentServiceProgramList(options)(fetch, basePath);
        },
    };
};

/**
 * StudentApi - object-oriented interface
 * @export
 * @class StudentApi
 * @extends {BaseAPI}
 */
export class StudentApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public ikshStudentServiceDataRead(options?: any) {
        return StudentApiFp(this.configuration).ikshStudentServiceDataRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public ikshStudentServicePhotoRead(options?: any) {
        return StudentApiFp(this.configuration).ikshStudentServicePhotoRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPhotoTkshPhoto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public ikshStudentServicePhotoUpdate(body: KshClassesPhotoTkshPhoto, options?: any) {
        return StudentApiFp(this.configuration).ikshStudentServicePhotoUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentApi
     */
    public ikshStudentServiceProgramList(options?: any) {
        return StudentApiFp(this.configuration).ikshStudentServiceProgramList(options)(this.fetch, this.basePath);
    }

}

/**
 * SwaggeruiApi - fetch parameter creator
 * @export
 */
export const SwaggeruiApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iCustomSwaggerUIServiceSwaggerUI(options: any = {}): FetchArgs {
            const localVarPath = `/swaggerui`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SwaggeruiApi - functional programming interface
 * @export
 */
export const SwaggeruiApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iCustomSwaggerUIServiceSwaggerUI(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = SwaggeruiApiFetchParamCreator(configuration).iCustomSwaggerUIServiceSwaggerUI(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * SwaggeruiApi - factory interface
 * @export
 */
export const SwaggeruiApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iCustomSwaggerUIServiceSwaggerUI(options?: any) {
            return SwaggeruiApiFp(configuration).iCustomSwaggerUIServiceSwaggerUI(options)(fetch, basePath);
        },
    };
};

/**
 * SwaggeruiApi - object-oriented interface
 * @export
 * @class SwaggeruiApi
 * @extends {BaseAPI}
 */
export class SwaggeruiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwaggeruiApi
     */
    public iCustomSwaggerUIServiceSwaggerUI(options?: any) {
        return SwaggeruiApiFp(this.configuration).iCustomSwaggerUIServiceSwaggerUI(options)(this.fetch, this.basePath);
    }

}

/**
 * ToolsApi - fetch parameter creator
 * @export
 */
export const ToolsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} mZipCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceAddressCodeByZip(mZipCode: string, options: any = {}): FetchArgs {
            // verify required parameter 'mZipCode' is not null or undefined
            if (mZipCode === null || mZipCode === undefined) {
                throw new RequiredError('mZipCode', 'Required parameter mZipCode was null or undefined when calling ikshToolsServiceAddressCodeByZip.');
            }
            const localVarPath = `/Tools/AddressCodeByZip`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mZipCode !== undefined) {
                localVarQueryParameter['mZipCode'] = mZipCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesAppcmdTkshAppCmd} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceAppCmdCreateOrUpdate(body: KshClassesAppcmdTkshAppCmd, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling ikshToolsServiceAppCmdCreateOrUpdate.');
            }
            const localVarPath = `/Tools/AppCmdCreateOrUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesAppcmdTkshAppCmd" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceTestDummy(options: any = {}): FetchArgs {
            const localVarPath = `/Tools/TestDummy`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceTestJson(options: any = {}): FetchArgs {
            const localVarPath = `/Tools/TestJson`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ToolsApi - functional programming interface
 * @export
 */
export const ToolsApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} mZipCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceAddressCodeByZip(mZipCode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshXdataServicePortalToolsTkshAddressCode> {
            const localVarFetchArgs = ToolsApiFetchParamCreator(configuration).ikshToolsServiceAddressCodeByZip(mZipCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesAppcmdTkshAppCmd} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceAppCmdCreateOrUpdate(body: KshClassesAppcmdTkshAppCmd, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = ToolsApiFetchParamCreator(configuration).ikshToolsServiceAppCmdCreateOrUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceTestDummy(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesTestdummyTkshTestDummy> {
            const localVarFetchArgs = ToolsApiFetchParamCreator(configuration).ikshToolsServiceTestDummy(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceTestJson(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = ToolsApiFetchParamCreator(configuration).ikshToolsServiceTestJson(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * ToolsApi - factory interface
 * @export
 */
export const ToolsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} mZipCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceAddressCodeByZip(mZipCode: string, options?: any) {
            return ToolsApiFp(configuration).ikshToolsServiceAddressCodeByZip(mZipCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesAppcmdTkshAppCmd} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceAppCmdCreateOrUpdate(body: KshClassesAppcmdTkshAppCmd, options?: any) {
            return ToolsApiFp(configuration).ikshToolsServiceAppCmdCreateOrUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceTestDummy(options?: any) {
            return ToolsApiFp(configuration).ikshToolsServiceTestDummy(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshToolsServiceTestJson(options?: any) {
            return ToolsApiFp(configuration).ikshToolsServiceTestJson(options)(fetch, basePath);
        },
    };
};

/**
 * ToolsApi - object-oriented interface
 * @export
 * @class ToolsApi
 * @extends {BaseAPI}
 */
export class ToolsApi extends BaseAPI {
    /**
     * 
     * @param {string} mZipCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    public ikshToolsServiceAddressCodeByZip(mZipCode: string, options?: any) {
        return ToolsApiFp(this.configuration).ikshToolsServiceAddressCodeByZip(mZipCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesAppcmdTkshAppCmd} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    public ikshToolsServiceAppCmdCreateOrUpdate(body: KshClassesAppcmdTkshAppCmd, options?: any) {
        return ToolsApiFp(this.configuration).ikshToolsServiceAppCmdCreateOrUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    public ikshToolsServiceTestDummy(options?: any) {
        return ToolsApiFp(this.configuration).ikshToolsServiceTestDummy(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToolsApi
     */
    public ikshToolsServiceTestJson(options?: any) {
        return ToolsApiFp(this.configuration).ikshToolsServiceTestJson(options)(this.fetch, this.basePath);
    }

}

/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressHomeRead(options: any = {}): FetchArgs {
            const localVarPath = `/User/AddressHomeRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPostaladdressTkshPostalAddress} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressHomeUpdate(body: KshClassesPostaladdressTkshPostalAddress, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling iUserServiceAddressHomeUpdate.');
            }
            const localVarPath = `/User/AddressHomeUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPostaladdressTkshPostalAddress" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressMainRead(options: any = {}): FetchArgs {
            const localVarPath = `/User/AddressMainRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesPostaladdressTkshPostalAddress} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressMainUpdate(body: KshClassesPostaladdressTkshPostalAddress, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling iUserServiceAddressMainUpdate.');
            }
            const localVarPath = `/User/AddressMainUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesPostaladdressTkshPostalAddress" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceDataRead(options: any = {}): FetchArgs {
            const localVarPath = `/User/DataRead`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesParamstrTkshParamStr} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceEmailUpdate(body: KshClassesParamstrTkshParamStr, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling iUserServiceEmailUpdate.');
            }
            const localVarPath = `/User/EmailUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesParamstrTkshParamStr" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceLoginNewActivate(kpid: number, token: string, options: any = {}): FetchArgs {
            // verify required parameter 'kpid' is not null or undefined
            if (kpid === null || kpid === undefined) {
                throw new RequiredError('kpid', 'Required parameter kpid was null or undefined when calling iUserServiceLoginNewActivate.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token', 'Required parameter token was null or undefined when calling iUserServiceLoginNewActivate.');
            }
            const localVarPath = `/User/LoginNewActivate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpid !== undefined) {
                localVarQueryParameter['kpid'] = kpid;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesParamstrTkshParamStr} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceMobilePhoneUpdate(body: KshClassesParamstrTkshParamStr, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling iUserServiceMobilePhoneUpdate.');
            }
            const localVarPath = `/User/MobilePhoneUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesParamstrTkshParamStr" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesUserPasswordresetTkshPasswordReset} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServicePasswordReset(body: KshClassesUserPasswordresetTkshPasswordReset, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling iUserServicePasswordReset.');
            }
            const localVarPath = `/User/PasswordReset`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesUserPasswordresetTkshPasswordReset" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesUserPasswordresetTkshPasswordReset} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServicePasswordResetRequest(body: KshClassesUserPasswordresetTkshPasswordReset, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling iUserServicePasswordResetRequest.');
            }
            const localVarPath = `/User/PasswordResetRequest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesUserPasswordresetTkshPasswordReset" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KshClassesParamstrTkshParamStr} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServicePasswordUpdate(body: KshClassesParamstrTkshParamStr, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling iUserServicePasswordUpdate.');
            }
            const localVarPath = `/User/PasswordUpdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KshClassesParamstrTkshParamStr" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Body} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceReviewDataLog(body: Body, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling iUserServiceReviewDataLog.');
            }
            const localVarPath = `/User/ReviewDataLog`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceTestProc(options: any = {}): FetchArgs {
            const localVarPath = `/User/TestProc`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceUserGroupList(options: any = {}): FetchArgs {
            const localVarPath = `/User/UserGroupList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressHomeRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesPostaladdressTkshPostalAddress> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServiceAddressHomeRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPostaladdressTkshPostalAddress} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressHomeUpdate(body: KshClassesPostaladdressTkshPostalAddress, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServiceAddressHomeUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressMainRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesPostaladdressTkshPostalAddress> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServiceAddressMainRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesPostaladdressTkshPostalAddress} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressMainUpdate(body: KshClassesPostaladdressTkshPostalAddress, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServiceAddressMainUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceDataRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KshClassesUserTkshUser> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServiceDataRead(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesParamstrTkshParamStr} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceEmailUpdate(body: KshClassesParamstrTkshParamStr, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServiceEmailUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} kpid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceLoginNewActivate(kpid: number, token: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServiceLoginNewActivate(kpid, token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesParamstrTkshParamStr} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceMobilePhoneUpdate(body: KshClassesParamstrTkshParamStr, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServiceMobilePhoneUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesUserPasswordresetTkshPasswordReset} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServicePasswordReset(body: KshClassesUserPasswordresetTkshPasswordReset, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServicePasswordReset(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesUserPasswordresetTkshPasswordReset} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServicePasswordResetRequest(body: KshClassesUserPasswordresetTkshPasswordReset, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServicePasswordResetRequest(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KshClassesParamstrTkshParamStr} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServicePasswordUpdate(body: KshClassesParamstrTkshParamStr, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServicePasswordUpdate(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Body} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceReviewDataLog(body: Body, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServiceReviewDataLog(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceTestProc(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServiceTestProc(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceUserGroupList(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2008> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).iUserServiceUserGroupList(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressHomeRead(options?: any) {
            return UserApiFp(configuration).iUserServiceAddressHomeRead(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPostaladdressTkshPostalAddress} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressHomeUpdate(body: KshClassesPostaladdressTkshPostalAddress, options?: any) {
            return UserApiFp(configuration).iUserServiceAddressHomeUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressMainRead(options?: any) {
            return UserApiFp(configuration).iUserServiceAddressMainRead(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesPostaladdressTkshPostalAddress} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceAddressMainUpdate(body: KshClassesPostaladdressTkshPostalAddress, options?: any) {
            return UserApiFp(configuration).iUserServiceAddressMainUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceDataRead(options?: any) {
            return UserApiFp(configuration).iUserServiceDataRead(options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesParamstrTkshParamStr} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceEmailUpdate(body: KshClassesParamstrTkshParamStr, options?: any) {
            return UserApiFp(configuration).iUserServiceEmailUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} kpid 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceLoginNewActivate(kpid: number, token: string, options?: any) {
            return UserApiFp(configuration).iUserServiceLoginNewActivate(kpid, token, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesParamstrTkshParamStr} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceMobilePhoneUpdate(body: KshClassesParamstrTkshParamStr, options?: any) {
            return UserApiFp(configuration).iUserServiceMobilePhoneUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesUserPasswordresetTkshPasswordReset} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServicePasswordReset(body: KshClassesUserPasswordresetTkshPasswordReset, options?: any) {
            return UserApiFp(configuration).iUserServicePasswordReset(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesUserPasswordresetTkshPasswordReset} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServicePasswordResetRequest(body: KshClassesUserPasswordresetTkshPasswordReset, options?: any) {
            return UserApiFp(configuration).iUserServicePasswordResetRequest(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KshClassesParamstrTkshParamStr} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServicePasswordUpdate(body: KshClassesParamstrTkshParamStr, options?: any) {
            return UserApiFp(configuration).iUserServicePasswordUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {Body} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceReviewDataLog(body: Body, options?: any) {
            return UserApiFp(configuration).iUserServiceReviewDataLog(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceTestProc(options?: any) {
            return UserApiFp(configuration).iUserServiceTestProc(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iUserServiceUserGroupList(options?: any) {
            return UserApiFp(configuration).iUserServiceUserGroupList(options)(fetch, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServiceAddressHomeRead(options?: any) {
        return UserApiFp(this.configuration).iUserServiceAddressHomeRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPostaladdressTkshPostalAddress} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServiceAddressHomeUpdate(body: KshClassesPostaladdressTkshPostalAddress, options?: any) {
        return UserApiFp(this.configuration).iUserServiceAddressHomeUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServiceAddressMainRead(options?: any) {
        return UserApiFp(this.configuration).iUserServiceAddressMainRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesPostaladdressTkshPostalAddress} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServiceAddressMainUpdate(body: KshClassesPostaladdressTkshPostalAddress, options?: any) {
        return UserApiFp(this.configuration).iUserServiceAddressMainUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServiceDataRead(options?: any) {
        return UserApiFp(this.configuration).iUserServiceDataRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesParamstrTkshParamStr} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServiceEmailUpdate(body: KshClassesParamstrTkshParamStr, options?: any) {
        return UserApiFp(this.configuration).iUserServiceEmailUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} kpid 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServiceLoginNewActivate(kpid: number, token: string, options?: any) {
        return UserApiFp(this.configuration).iUserServiceLoginNewActivate(kpid, token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesParamstrTkshParamStr} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServiceMobilePhoneUpdate(body: KshClassesParamstrTkshParamStr, options?: any) {
        return UserApiFp(this.configuration).iUserServiceMobilePhoneUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesUserPasswordresetTkshPasswordReset} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServicePasswordReset(body: KshClassesUserPasswordresetTkshPasswordReset, options?: any) {
        return UserApiFp(this.configuration).iUserServicePasswordReset(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesUserPasswordresetTkshPasswordReset} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServicePasswordResetRequest(body: KshClassesUserPasswordresetTkshPasswordReset, options?: any) {
        return UserApiFp(this.configuration).iUserServicePasswordResetRequest(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {KshClassesParamstrTkshParamStr} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServicePasswordUpdate(body: KshClassesParamstrTkshParamStr, options?: any) {
        return UserApiFp(this.configuration).iUserServicePasswordUpdate(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Body} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServiceReviewDataLog(body: Body, options?: any) {
        return UserApiFp(this.configuration).iUserServiceReviewDataLog(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServiceTestProc(options?: any) {
        return UserApiFp(this.configuration).iUserServiceTestProc(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public iUserServiceUserGroupList(options?: any) {
        return UserApiFp(this.configuration).iUserServiceUserGroupList(options)(this.fetch, this.basePath);
    }

}

/**
 * WwwApi - fetch parameter creator
 * @export
 */
export const WwwApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshWwwServiceTestJson(options: any = {}): FetchArgs {
            const localVarPath = `/Www/TestJson`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshWwwServiceTestWww(options: any = {}): FetchArgs {
            const localVarPath = `/Www/TestWww`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshWwwServiceTestWww2(options: any = {}): FetchArgs {
            const localVarPath = `/Www/TestWwwPost`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * WwwApi - functional programming interface
 * @export
 */
export const WwwApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshWwwServiceTestJson(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SystemJSONTJSONObject> {
            const localVarFetchArgs = WwwApiFetchParamCreator(configuration).ikshWwwServiceTestJson(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshWwwServiceTestWww(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = WwwApiFetchParamCreator(configuration).ikshWwwServiceTestWww(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshWwwServiceTestWww2(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = WwwApiFetchParamCreator(configuration).ikshWwwServiceTestWww2(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    };
};

/**
 * WwwApi - factory interface
 * @export
 */
export const WwwApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshWwwServiceTestJson(options?: any) {
            return WwwApiFp(configuration).ikshWwwServiceTestJson(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshWwwServiceTestWww(options?: any) {
            return WwwApiFp(configuration).ikshWwwServiceTestWww(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ikshWwwServiceTestWww2(options?: any) {
            return WwwApiFp(configuration).ikshWwwServiceTestWww2(options)(fetch, basePath);
        },
    };
};

/**
 * WwwApi - object-oriented interface
 * @export
 * @class WwwApi
 * @extends {BaseAPI}
 */
export class WwwApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WwwApi
     */
    public ikshWwwServiceTestJson(options?: any) {
        return WwwApiFp(this.configuration).ikshWwwServiceTestJson(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WwwApi
     */
    public ikshWwwServiceTestWww(options?: any) {
        return WwwApiFp(this.configuration).ikshWwwServiceTestWww(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WwwApi
     */
    public ikshWwwServiceTestWww2(options?: any) {
        return WwwApiFp(this.configuration).ikshWwwServiceTestWww2(options)(this.fetch, this.basePath);
    }

}

