import { User } from "../Types/User";
import Cookies from "js-cookie";
import AddressInterface from "Types/AddressInterface";
import {
  UserApiFp,
  StudentApiFp,
  ProfessorApiFp,
  LecturerApiFp,
  StaffApiFp,
  EmailApiFp,
  KeyTablesApi,
  SystemJSONTJSONObject,
  KshClassesStudyprogramTkshStudyProgram,
  KshClassesCareerTkshCareer,
  KshClassesProfessorTkshProfessor,
  KshClassesPhotoTkshPhoto,
  KshClassesStaffTkshStaff,
  KshClassesLecturerTkshLecturer,
  KshClassesNewsTkshNews,
  KshClassesPublicationTkshPublication,
  KshClassesUserPasswordresetTkshPasswordReset,
  KshClassesEmailAliasTkshEmailAlias,
  KeyTablesApiFp,
  KshClassesFileloadTkshFileLoad,
  KshClassesGroupTkshGroup,
  SystemGenericsCollectionsTListkshClassesCareerTkshCareer
} from "Connectivity/Portal";
import extractValueFromResponse from "../Utils/ExtractValueFromResponse";
// import console = require("console");
import { AddressOptions } from "Types";
import Publication from "src/04_organisms/Prof/Publication";

const valueOrEmptyArray = (response: {
  value?: SystemGenericsCollectionsTListkshClassesCareerTkshCareer;
}) => {
  if (response.value) {
    return response.value;
  }

  return [];
};

const host = "https://services.ksh-m.de:2001";
// {"username":"kh64wmr","password":"SX2:Ucrk"} qw12ER!!5

const userApiFp = UserApiFp();
const studentApiFp = StudentApiFp();
const emailApiFp = EmailApiFp();
const professorApiFp = ProfessorApiFp();
const lecturerApiFp = LecturerApiFp();
const staffApiFp = StaffApiFp();
const keyApiFp = KeyTablesApiFp();

const configureRequest = (
  method: "POST" | "GET",
  requestConfig: RequestInit = {}
): RequestInit => {
  const token = Cookies.get("token") || false;
  const headers: HeadersInit = {
    "Content-Type": "application/json; charset=utf-8"
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config: RequestInit = {
    method: method,
    mode: "cors",
    credentials: "same-origin",
    cache: "no-cache",
    headers: headers,
    ...requestConfig
  };

  return config;
};

const uriBuilder = (uri: string): string => `${host}${uri}`;

const post = async (uri: string, body: Object): Promise<Response> =>
  await fetch(
    uriBuilder(uri),
    configureRequest("POST", { body: JSON.stringify(body) })
  );

const getRequestConfig = () => {
  const request: RequestInit = {};
  const token = Cookies.get("token") || false;
  const headers: HeadersInit = {
    "Content-Type": "application/json; charset=utf-8"
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  request.headers = headers;

  return request;
};

export const loginAction = async (login: string, password: string) => {
  const token = await post(`/auth/v1/login/jwt4app`, {
    App: "id-portal",
    User: login,
    Pass: password
  });

  if (token.status !== 200) {
    return false;
  }
  const data = await token.json();

  Cookies.set("token", data.value);
  return data;
};

export const meAction = async (): Promise<User> => {
  return await userApiFp.iUserServiceDataRead(getRequestConfig())();
};

export const updateEmailAction = async (email: string) => {
  return await userApiFp.iUserServiceEmailUpdate(
    { editstr: email },
    getRequestConfig()
  )();
};

export const updatePasswordAction = async (password: string) => {
  return await userApiFp.iUserServicePasswordUpdate(
    { editstr: password },
    getRequestConfig()
  )();
};

/**
 * @TODO check return type
 */
export const updateAddressAction = async (
  type: AddressOptions = "main",
  address: AddressInterface
): Promise<SystemJSONTJSONObject> => {
  let call = userApiFp.iUserServiceAddressMainUpdate(
    address,
    getRequestConfig()
  );

  if (type === "home") {
    call = userApiFp.iUserServiceAddressHomeUpdate(address, getRequestConfig());
  }

  return call();
};

export const getAddressAction = async (
  type: AddressOptions = "main"
): Promise<AddressInterface> => {
  let call = userApiFp.iUserServiceAddressMainRead(getRequestConfig());
  if (type === "home") {
    call = userApiFp.iUserServiceAddressHomeRead(getRequestConfig());
  }

  return call();
};

export const updateMobileAction = async (mobile: string) => {
  return userApiFp.iUserServiceMobilePhoneUpdate(
    {
      editstr: mobile
    },
    getRequestConfig()
  )();
};

export const getStudentDataAction = async () => {
  return await studentApiFp.ikshStudentServiceDataRead(getRequestConfig())();
};

export const getStudyDataAction = async () => {
  const study = await studentApiFp.ikshStudentServiceProgramList(
    getRequestConfig()
  )();

  return study.value;
};

export const getStudentPhotoAction = async () => {
  return await studentApiFp.ikshStudentServicePhotoRead(getRequestConfig())();
};

export const updateStudentPhotoAction = async (
  photo: KshClassesPhotoTkshPhoto
) => {
  return await studentApiFp.ikshStudentServicePhotoUpdate(
    photo,
    getRequestConfig()
  )();
};

export const getStudentEmailAliasAction = async () => {
  return await emailApiFp.ikshEmailServiceAliasStudentRead(
    getRequestConfig()
  )();
};

export const getLecturerEmailAliasAction = async () => {
  return await emailApiFp.ikshEmailServiceAliasLecturerRead(
    getRequestConfig()
  )();
};

export const getEmailAliasListAction = async () => {
  return await emailApiFp.ikshEmailServiceAliasList(getRequestConfig())();
};

export const switchStudentToAccount = async (
  mail: KshClassesEmailAliasTkshEmailAlias
) => {
  return await emailApiFp.ikshEmailServiceSwitchStudentToAccount(
    mail,
    getRequestConfig()
  )();
};

export const switchLecuturerToForwarding = async (
  mail: KshClassesEmailAliasTkshEmailAlias
) => {
  return await emailApiFp.ikshEmailServiceSwitchLecturerToForwarding(
    mail,
    getRequestConfig()
  )();
};

export const switchLecuturerToAccount = async (
  mail: KshClassesEmailAliasTkshEmailAlias
) => {
  return await emailApiFp.ikshEmailServiceSwitchLecturerToAccount(
    mail,
    getRequestConfig()
  )();
};

export const switchStudentToForwarding = async (
  mail: KshClassesEmailAliasTkshEmailAlias
) => {
  return await emailApiFp.ikshEmailServiceSwitchStudentToForwarding(
    mail,
    getRequestConfig()
  )();
};

export const getProfessorDataAction = async () => {
  return await professorApiFp.ikshProfessorServiceDataRead(
    getRequestConfig()
  )();
};

export const updateProfessorDataAction = async (
  professorData: KshClassesProfessorTkshProfessor
) => {
  return await professorApiFp.ikshProfessorServiceDataUpdate(
    professorData,
    getRequestConfig()
  )();
};

export const createProfessorCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceCareerCreate(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const deleteProfessortCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceCareerDelete(
    career,
    getRequestConfig()
  )();
  return extractValueFromResponse(res);
};

export const getProfessorCareerListDataAction = async () => {
  const res = await professorApiFp.ikshProfessorServiceCareerList(
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const moveDownProfessorCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const result = await professorApiFp.ikshProfessorServiceCareerMoveDown(
    career,
    getRequestConfig()
  )();

  return valueOrEmptyArray(result);
};

export const moveUpProfessorCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const result = await professorApiFp.ikshProfessorServiceCareerMoveUp(
    career,
    getRequestConfig()
  )();

  return valueOrEmptyArray(result);
};

export const updateProfessorCareerDataAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceCareerUpdate(
    career,
    getRequestConfig()
  )();
  return extractValueFromResponse(res);
};

export const getLecturerDataAction = async () => {
  return await lecturerApiFp.ikshLecturerServiceDataRead(getRequestConfig())();
};

export const updateLectureDataAction = async (
  lecturerData: KshClassesLecturerTkshLecturer
) => {
  return await lecturerApiFp.ikshLecturerServiceDataUpdate(
    lecturerData,
    getRequestConfig()
  )();
};

export const getStaffDataAction = async () => {
  return await staffApiFp.ikshStaffServiceDataRead(getRequestConfig())();
};

export const updateStaffDataAction = async (
  staffData: KshClassesStaffTkshStaff
) => {
  return await staffApiFp.ikshStaffServiceDataUpdate(
    staffData,
    getRequestConfig()
  )();
};

export const createMainFocusCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceMainFocusCreate(
    career,
    getRequestConfig()
  )();
  return extractValueFromResponse(res);
};

export const getMainFocusDataAction = async () => {
  const res = await professorApiFp.ikshProfessorServiceMainFocusList(
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const updateMainFocusDataAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceMainFocusUpdate(
    career,
    getRequestConfig()
  )();
  return extractValueFromResponse(res);
};

export const moveUpMainFocusCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceMainFocusMoveUp(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const moveDownMainFocusCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  return valueOrEmptyArray(
    await professorApiFp.ikshProfessorServiceMainFocusMoveDown(
      career,
      getRequestConfig()
    )()
  );
};

export const deleteMainFocusCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceMainFocusDelete(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const createFunctionKshCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceFunctionKshCreate(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const getFunctionKshDataAction = async () => {
  const res = await professorApiFp.ikshProfessorServiceFunctionKshList(
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const updateFunctionKshDataAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceFunctionKshUpdate(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const moveUpFunctionKshCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  return valueOrEmptyArray(
    await professorApiFp.ikshProfessorServiceFunctionKshMoveUp(
      career,
      getRequestConfig()
    )()
  );
};

export const moveDownFunctionKshCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  return valueOrEmptyArray(
    await professorApiFp.ikshProfessorServiceFunctionKshMoveDown(
      career,
      getRequestConfig()
    )()
  );
};

export const confirmFunctionKshCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceFunctionKshDelete(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const createFunctionExternalCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceFunctionExternalCreate(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const getFunctionExternalDataAction = async () => {
  const res = await professorApiFp.ikshProfessorServiceFunctionExternalList(
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const updateFunctionExternalDataAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceFunctionExternalUpdate(
    career,
    getRequestConfig()
  )();
  return extractValueFromResponse(res);
};

export const moveUpFunctionExternalCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  return valueOrEmptyArray(
    await professorApiFp.ikshProfessorServiceFunctionExternalMoveUp(
      career,
      getRequestConfig()
    )()
  );
};

export const moveDownFunctionExternalCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  return valueOrEmptyArray(
    await professorApiFp.ikshProfessorServiceFunctionExternalMoveDown(
      career,
      getRequestConfig()
    )()
  );
};

export const confirmFunctionExternalCareerAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceFunctionExternalDelete(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const getNewsDataAction = async () => {
  const res = await professorApiFp.ikshProfessorServiceNewsList(
    undefined,
    getRequestConfig()
  )();

  if (res.value) {
    return res.value;
  }

  return [];
};

export const updateNewsDataAction = async (
  newsData: KshClassesNewsTkshNews
) => {
  return await professorApiFp.ikshProfessorServiceNewsUpdate(
    newsData,
    undefined,
    getRequestConfig()
  )();
};

export const createServiceResearchAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceResearchCreate(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const getServiceResearchAction = async () => {
  const res = await professorApiFp.ikshProfessorServiceResearchList(
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const updateServiceResearchAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceResearchUpdate(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const moveUpServiceResearchAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const response = await professorApiFp.ikshProfessorServiceResearchMoveUp(
    career,
    getRequestConfig()
  )();

  if (response.value) {
    return response.value;
  }

  return [];
};

export const moveDownServiceResearchAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceResearchMoveDown(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const deleteServiceResearchAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceResearchDelete(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const createServiceLectureAction = async (
  publication: KshClassesPublicationTkshPublication
) => {
  const res = await professorApiFp.ikshProfessorServiceLectureCreate(
    publication,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const getServiceLectureAction = async () => {
  const res = await professorApiFp.ikshProfessorServiceLectureList(
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const updateServiceLectureAction = async (
  publication: KshClassesPublicationTkshPublication
) => {
  const res = await professorApiFp.ikshProfessorServiceLectureUpdate(
    publication,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const moveUpServiceLectureAction = async (
  publication: KshClassesPublicationTkshPublication
) => {
  return valueOrEmptyArray(
    await professorApiFp.ikshProfessorServiceLectureMoveUp(
      publication,
      getRequestConfig()
    )()
  );
};

export const moveDownServiceLectureAction = async (
  publication: KshClassesPublicationTkshPublication
) => {
  return valueOrEmptyArray(
    await professorApiFp.ikshProfessorServiceLectureMoveDown(
      publication,
      getRequestConfig()
    )()
  );
};

export const deleteServiceLectureAction = async (
  publication: KshClassesPublicationTkshPublication
) => {
  const res = await professorApiFp.ikshProfessorServiceLectureDelete(
    publication,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const updateServiceLectureFileAction = async (
  file: KshClassesFileloadTkshFileLoad
) => {
  return await professorApiFp.ikshProfessorServiceLectureFileSave(
    file,
    getRequestConfig()
  )();
};

export const deleteServiceLectureFileAction = async (
  lecture: KshClassesPublicationTkshPublication
) => {
  return await professorApiFp.ikshProfessorServiceLectureFileDelete(
    lecture,
    getRequestConfig()
  )();
};

export const updateServiceResearchFileAction = async (
  file: KshClassesFileloadTkshFileLoad
) => {
  return await professorApiFp.ikshProfessorServiceResearchFileSave(
    file,
    getRequestConfig()
  )();
};

export const deleteServiceResearchFileAction = async (
  lecture: KshClassesPublicationTkshPublication
) => {
  return await professorApiFp.ikshProfessorServiceResearchFileDelete(
    lecture,
    getRequestConfig()
  )();
};

export const createServicePublicationAction = async (
  publication: KshClassesPublicationTkshPublication
) => {
  const res = await professorApiFp.ikshProfessorServicePublicationCreate(
    publication,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const getServicePublicationAction = async () => {
  const res = await professorApiFp.ikshProfessorServicePublicationList(
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const updateServicePublicationAction = async (
  publication: KshClassesPublicationTkshPublication
) => {
  const res = await professorApiFp.ikshProfessorServicePublicationUpdate(
    publication,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const moveUpServicePublicationAction = async (
  publication: KshClassesPublicationTkshPublication
) => {
  return valueOrEmptyArray(
    await professorApiFp.ikshProfessorServicePublicationMoveUp(
      publication,
      getRequestConfig()
    )()
  );
};

export const moveDownServicePublicationAction = async (
  publication: KshClassesPublicationTkshPublication
) => {
  return valueOrEmptyArray(
    await professorApiFp.ikshProfessorServicePublicationMoveDown(
      publication,
      getRequestConfig()
    )()
  );
};

export const deleteServicePublicationAction = async (
  publication: KshClassesPublicationTkshPublication
) => {
  const res = await professorApiFp.ikshProfessorServicePublicationDelete(
    publication,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const updateServicePublicationFileAction = async (
  file: KshClassesFileloadTkshFileLoad
) => {
  return await professorApiFp.ikshProfessorServicePublicationFileSave(
    file,
    getRequestConfig()
  )();
};

export const deleteServicePublicationFileAction = async (
  publication: KshClassesPublicationTkshPublication
) => {
  return await professorApiFp.ikshProfessorServicePublicationFileDelete(
    publication,
    getRequestConfig()
  )();
};

export const createServiceFurtherEducationAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceFurtherEducationCreate(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const getServiceFurtherEducationAction = async () => {
  const res = await professorApiFp.ikshProfessorServiceFurtherEducationList(
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const updateServiceFurtherEducationAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceFurtherEducationUpdate(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const moveUpServiceFurtherEducationAction = async (
  career: KshClassesCareerTkshCareer
) => {
  return valueOrEmptyArray(
    await professorApiFp.ikshProfessorServiceFurtherEducationMoveUp(
      career,
      getRequestConfig()
    )()
  );
};

export const moveDownServiceFurtherEducationAction = async (
  career: KshClassesCareerTkshCareer
) => {
  return valueOrEmptyArray(
    await professorApiFp.ikshProfessorServiceFurtherEducationMoveDown(
      career,
      getRequestConfig()
    )()
  );
};

export const deleteServiceFurtherEducationAction = async (
  career: KshClassesCareerTkshCareer
) => {
  const res = await professorApiFp.ikshProfessorServiceFurtherEducationDelete(
    career,
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const passwordResetRequest = async (
  request: KshClassesUserPasswordresetTkshPasswordReset
) => {
  return await userApiFp.iUserServicePasswordResetRequest(
    request,
    getRequestConfig()
  )();
};

export const passwordReset = async (
  request: KshClassesUserPasswordresetTkshPasswordReset
) => {
  return await userApiFp.iUserServicePasswordReset(
    request,
    getRequestConfig()
  )();
};

export const getCountryList = async () => {
  const res = await keyApiFp.iKeyTablesServiceCountryCodes(
    getRequestConfig()
  )();

  return extractValueFromResponse(res);
};

export const getUserGroupList = async () => {
  const res = await userApiFp.iUserServiceUserGroupList(getRequestConfig())();

  return res.value;
};

export const reviewLog = async (kpid: number) => {
  return await userApiFp.iUserServiceReviewDataLog(
    { kpid },
    getRequestConfig()
  )();
};
