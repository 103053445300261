import {
  KshClassesCareerTkshCareer,
  SystemGenericsCollectionsTListkshClassesNewsTkshNews
} from "Connectivity/Portal";
import React, { useRef, useState } from "react";
import FormRow from "02_atoms/Form/FormRow";
import FormHeadline from "02_atoms/FormHeadline";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import StyledForm from "04_organisms/Form";
import styled, { css } from "styled-components";
import ActiveIndicator from "02_atoms/Form/ActiveIndicator";
import Button from "02_atoms/Button";
import Overlay from "02_atoms/Overlay";
import { async } from "q";
import { render } from "react-dom";
import { textAlign, width } from "styled-system";
import { KshClassesNewsTkshNews } from "../../Connectivity/Portal";
import TitleWrapper from "02_atoms/Form/TitleWrapper";
import DateRow from "02_atoms/Form/DateRow";
import CheckboxRow from "02_atoms/Form/CheckboxRow";
import FormActionContainer from "02_atoms/Form/FormActionContainer";
import PlaceholderTd from "02_atoms/Placeholder/PlaceholderTd";
import CancelButton from "02_atoms/CancelButton";
import NewsCountSpan from "02_atoms/Form/NewsCountSpan";
import Confirm from "02_atoms/Confirm";

type NewsProps = {
  reFetch: Function;
  updateNews: Function;
  deleteNews?: Function;
  news: KshClassesNewsTkshNews;
  isNew?: boolean;
  label: string;
};

const News: React.FunctionComponent<NewsProps> = ({
  reFetch,
  deleteNews,
  updateNews,
  news,
  isNew = false,
  label
}) => {
  const closeCareerFunction = useRef<Function>(() => { });
  const [textAreaLength, setTextAreaLength] = useState(
    news.content ? news.content.length : 0
  );

  const prepareClearCareerFunction = async (
    career: KshClassesCareerTkshCareer
  ) => {
    await clear(news);
    reFetch();
    closeCareerFunction.current();
    return;
  };

  const clear = async (news: KshClassesNewsTkshNews) => {
    const newsCleared = {
      ...news,
      caption: "",
      content: "",
      active: 0
    };
    const newsList = await updateNews(newsCleared);
    if (!newsList) {
      return [FORM_ERROR];
    }
    return newsList;
  };

  // @TODO get rid of any
  const onSaveChange = async (values: any) => {
    values.active = values.active ? 1 : 0;
    const newsList = await updateNews(values);
    if (!newsList) {
      return [FORM_ERROR];
    }
    reFetch();
    closeCareerFunction.current();
  };

  return (
    <>
      <tr>
        {/* // @ts-ignore */}
        <TitleWrapper caption={label + news.caption} />
        <PlaceholderTd tdWidth={"5%"} />
        <PlaceholderTd tdWidth={"5%"} />
        <ActiveIndicator
          active={!!news.active}
          updateFunction={updateNews}
          objectToUpdate={news}
          reFetch={reFetch}
        />
        <PlaceholderTd tdWidth={"10%"}>
          <Overlay
            editLabel="News"
            initialOpen={isNew}
            buttonLabel="bearbeiten"
            closeFunction={closeCareerFunction}
          >
            <Form
              initialValues={news}
              onSubmit={onSaveChange}
              render={({
                submitError,
                handleSubmit,
                form,
                submitting,
                pristine,
                values
              }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <FormActionContainer>
                      <div>
                        {submitError && (
                          <div className="error">{submitError}</div>
                        )}
                        <FormRow>
                          <label>Titel der News</label>
                          <Field
                            name="caption"
                            component="input"
                            type="text"
                            placeholder="Titel der News"
                          />
                        </FormRow>
                        <FormRow>
                          <label>Inhalt</label>
                          <Field
                            name="content"
                            component="textarea"
                            //   type="text"
                            placeholder="Inhalt der News"
                            maxLength="500"
                            onInput={(e: any) =>
                              setTextAreaLength(e.target.value.length)
                            }
                          />
                          <NewsCountSpan>{textAreaLength} / 500</NewsCountSpan>
                        </FormRow>
                        <DateRow>
                          <label>Gültig bis</label>
                          <Field
                            name="validto"
                            component="input"
                            type="date"
                            placeholder=""
                          />
                        </DateRow>
                        <CheckboxRow>
                          <label>Auf der Webseite veröffentlichen?</label>
                          <Field
                            name="active"
                            component="input"
                            type="checkbox"
                          />
                        </CheckboxRow>
                        <FormRow>
                          <Button type="submit" disabled={submitting || pristine}>
                            Übernehmen
                        </Button>
                          <CancelButton
                            isNew={isNew}
                            closeFunction={() => closeCareerFunction.current()}
                          />
                        </FormRow>
                        <FormRow>
                          <Confirm confirmFunction={() => prepareClearCareerFunction(news)} buttonText={"Leeren"} />

                        </FormRow>
                      </div>
                    </FormActionContainer>
                  </StyledForm>
                )}
            />
          </Overlay>
        </PlaceholderTd>
        <PlaceholderTd tdWidth={"6%"} />
      </tr>
      {/* <tr>
        <ContentTd>{news.content}</ContentTd>
      </tr> */}
    </>
  );
};

export default News;
