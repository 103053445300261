import styled from "styled-components";

export const PageHeader = styled.div`
  padding: 15px 20px;
  text-align: center;
`;

export const PageHeadline = styled.h3`
  font-size: 25px;
  margin: 1rem 0 2rem 0;
  line-height: 35px;
  font-weight: bold;
`;

export const PageDescription = styled.p``;
