import React from "react";
import styled from "styled-components";
import { FormattedDate } from "react-intl";
import { User } from "../../Types/User";
import { hasGroup } from "../../Utils/Groups";
import Address from "./Address";
import Email from "./Email";
import Mobile from "./Mobile";
import DataWrapper from "02_atoms/DataWrapper";
import Hidden from "02_atoms/Hidden";

type PersonalProps = {
  user: User;
  updateEmail: Function;
  updateMobile: Function;
};

type PersonalState = {
  isOpen: boolean;
};

class Personal extends React.Component<PersonalProps, PersonalState> {
  render() {
    const { user } = this.props;
    const noValue = "[undefiniert]";
    return (
      <>
        <DataWrapper>
          <label>Name, Vorname</label>
          <p>
            {user.lastname}, {user.firstname}
          </p>
        </DataWrapper>

        {user.birthname !== noValue && 
        <DataWrapper>
          <label>Geburtsname</label>
          <p>{user.birthname}</p>
        </DataWrapper>
        }

        {/* <DataWrapper>
          <label>Rufname</label>
          <p>{user.}</p>
        </DataWrapper> */}

        <DataWrapper>
          <label>Geburtsdatum, -ort und Land</label>
          <p>
            {user.birthdate && (
              <>
                <FormattedDate value={user.birthdate} />,{" "}
              </>
            )}
            {user.birthcity}, {user.birthcountry}
          </p>
        </DataWrapper>

        <Hidden
          testValue={
            (user.citizenship1 && user.citizenship1) === noValue
              ? noValue
              : null
          }
        >
          <DataWrapper>
            <label>Staatsangehörigkeit</label>
            <p>
              <Hidden testValue={user.citizenship1}>{user.citizenship1}</Hidden>
              <br />
              <Hidden testValue={user.citizenship2}>{user.citizenship2}</Hidden>
            </p>
          </DataWrapper>
        </Hidden>

        <DataWrapper>
          <label>Login (ksh-ID)</label>
          <p>{user.login}</p>
        </DataWrapper>

        <Hidden testValue={user.email}>
          <Email updateEmail={this.props.updateEmail} email={user.email} />
        </Hidden>

        <Hidden testValue={user.mobilephone}>
          <Mobile
            updateMobile={this.props.updateMobile}
            mobile={user.mobilephone}
          />
        </Hidden>

        <Address label="Semester- und Kontaktanschrift *" type="main" />

        {hasGroup(user, "students") && (
          <Address type="home" label="Heimatanschrift" />
        )}

        <DataWrapper>
          <p>(* Pflichtfeld)</p>
        </DataWrapper>
      </>
    );
  }
}

export default Personal;
