import React, { useState, useEffect, useContext, useRef } from "react";
import { FormattedDate } from "react-intl";
import AddressInterface from "Types/AddressInterface";
import "@reach/dialog/styles.css";
import { Form, Field } from "react-final-form";
import StyledForm from "04_organisms/Form";
import { LoginContext } from "LoginContext";
import { AddressOptions } from "Types";
import { KshClassesCountrycodeTkshCountryCode } from "Connectivity/Portal";
import countries from "generated/countries.json";
import Overlay from "02_atoms/Overlay";
import Button from "02_atoms/Button";
import DataWrapper from "02_atoms/DataWrapper";
import FormRow from "02_atoms/Form/FormRow";
import CancelButton from "02_atoms/CancelButton";
import Hidden from "02_atoms/Hidden";

type AddressProps = {
  type: AddressOptions;
  label: String;
};

const Address: React.FunctionComponent<AddressProps> = ({ type, label }) => {
  const closeFunction = useRef<Function>(() => { });
  const loginContext = useContext(LoginContext);

  const [address, setAddress] = useState<AddressInterface | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await loginContext.getAddress(type);
      setAddress(result);
    };

    fetchData();
  }, [loginContext, type]);

  if (address === null) {
    return null;
  }

  const onSaveChange = async (address: AddressInterface) => {
    // @ts-ignore
    const newCountryId = parseInt(address.countryid, 10);

    if (countries) {
      const country = countries.find(
        countries => countries.id === newCountryId
      );

      if (country) {
        const newCountryCap = country.caption;

        const dataToUpdate = {
          ...address,
          countrystr: newCountryCap,
          countryid: newCountryId
        };

        await loginContext.updateAddress(type, dataToUpdate);
        setAddress(dataToUpdate);
      }
    }

    closeFunction.current();
  };

  return (
    <>
      <Hidden testValue={address.city}>
        <DataWrapper>
          <label>{label}</label>
          {address.lastconfirmed && (
            <p>
              zuletzt bestätigt: <FormattedDate value={address.lastconfirmed} />
            </p>
          )}
          <p>{address.street}</p>
          {address.additional && <p>{address.additional}</p>}
          <p>
            {address.zip} {address.city}
          </p>
          <p>{address.countrystr}</p>
        </DataWrapper>

        <Overlay editLabel="Adresse bearbeiten" closeFunction={closeFunction}>
          <Form
            onSubmit={onSaveChange}
            initialValues={address}
            render={({
              submitError,
              handleSubmit,
              form,
              submitting,
              pristine,
              values
            }) => (
                <StyledForm onSubmit={handleSubmit}>
                  {submitError && <div className="error">{submitError}</div>}
                  <FormRow>
                    <label>Straße</label>
                    <Field
                      name="street"
                      component="input"
                      type="text"
                      placeholder="Straße"
                    />
                  </FormRow>
                  <FormRow>
                    <label>Addresszusatz</label>
                    <Field
                      name="additional"
                      component="input"
                      type="text"
                      placeholder="Addresszusatz"
                    />
                  </FormRow>

                  <FormRow>
                    <label>Postleitzahl, Ort</label>
                    <Field
                      name="zip"
                      component="input"
                      type="text"
                      placeholder="Postleitzahl"
                    />
                    <Field
                      name="city"
                      component="input"
                      type="text"
                      placeholder="Ort"
                    />
                  </FormRow>

                  <FormRow>
                    <label>Land</label>
                    <Field
                      name="countryid"
                      component="select"
                      type="number"
                      placeholder="Land"
                    >
                      {countries &&
                        countries.map(
                          (country: KshClassesCountrycodeTkshCountryCode) => (
                            <option key={country.acid} value={country.id}>
                              {country.caption}
                            </option>
                          )
                        )}
                    </Field>
                  </FormRow>

                  <FormRow>
                    <Button type="submit" disabled={submitting || pristine}>
                      Übernehmen
                  </Button>
                    <CancelButton closeFunction={() => closeFunction.current()} />
                  </FormRow>
                </StyledForm>
              )}
          />
        </Overlay>
      </Hidden>
    </>
  );
};

export default Address;
