import styled from "styled-components";
import FormRow from "./FormRow";

const DateRow = styled(FormRow)`
  /* padding: 0 0 10px 0; */

  div {
    display: flex;
    justify-content: space-between;
  }

  label {
    display: table;
  }

  input {
    width: 40%;
    margin-right: 10px;
  }

  @media only screen and (max-width: 768px) {
    input {
      width: 90%;
    }
  }
`;

export default DateRow;
