import { KshClassesCareerTkshCareer } from "Connectivity/Portal";

export const sortResult = (result: Array<KshClassesCareerTkshCareer>) => {
  return result;

  return result.sort(
    (career1, career2): number => {
      if (!career1.seq) {
        return 0;
      }

      if (!career2.seq) {
        return 0;
      }

      if (career1.seq === career2.seq) {
        return 0;
      }

      if (career1.seq < career2.seq) {
        return -1;
      }

      if (career1.seq > career2.seq) {
        return 1;
      }

      return 0;
    }
  );
};
