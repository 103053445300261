import React, { useContext } from "react";
import { LoginContext } from "./LoginContext";

import { Router, RouteComponentProps } from "@reach/router";

import Login from "06_pages/Login";
import Home from "06_pages/Home";
import Newsletter from "06_pages/Newsletter";
import Data from "06_pages/Data";
import Review from "06_pages/Review";
import Prof from "06_pages/Prof";

import PasswordResetRequest from "06_pages/PasswordResetRequest";
import PasswordReset from "06_pages/PasswordReset";
import PasswordResetSuccess from "06_pages/PasswordResetSuccess";
import TestError from "./testError";

/**
 * @see https://github.com/reach/router/issues/141
 */
const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;

const RouterPageParam = ({
  pageComponent,
  ...routerProps
}: {
  pageComponent: (routerProps: RouteComponentProps) => JSX.Element;
} & RouteComponentProps) => {
  return <>{pageComponent(routerProps)}</>;
};

const AppRouter = () => {
  const context = useContext(LoginContext);

  const { loggedIn, login, user } = context;

  if (loggedIn && user) {
    if (user.reviewdata === 1) {
      return <Review />;
    }

    return (
      <Router>
        <RouterPage pageComponent={<TestError />} path="/testError" />

        <RouterPage pageComponent={<Newsletter />} path="/newsletter" />
        <RouterPage pageComponent={<Data />} path="/data" default />
        <RouterPageParam
          path="/data/:section"
          pageComponent={(props: RouteComponentProps<{ section: string }>) => (
            <Data section={props.section} />
          )}
        />
        <RouterPage pageComponent={<Prof />} path="/prof/" />
        <RouterPageParam
          path="/prof/:section"
          pageComponent={(props: RouteComponentProps<{ section: string }>) => (
            <Prof section={props.section} />
          )}
        />
      </Router>
    );
  }

  return (
    <Router>
      <RouterPage
        pageComponent={<PasswordResetRequest />}
        path="/resetrequest"
      />
      <RouterPage
        pageComponent={<PasswordResetSuccess />}
        path="/reset/success"
      />
      <RouterPage pageComponent={<Login login={login} />} path="/" default />
      <RouterPageParam
        path="/reset/:token"
        pageComponent={(props: RouteComponentProps<{ token: string }>) => (
          <PasswordReset tokenId={props.token} />
        )}
      />
      <RouterPage pageComponent={<TestError />} path="/testError" />
    </Router>
  );
};

export default AppRouter;
