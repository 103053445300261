import React, { useState, useEffect, useCallback } from "react";
import { useToggle } from "Utils/Hooks";
import { KshClassesCareerTkshCareer } from "Connectivity/Portal";
import Button from "02_atoms/Button";
import Table from "02_atoms/Form/Table";
import { sortResult } from "Utils/CareerSort";
import {
  getMainFocusDataAction,
  updateMainFocusDataAction,
  moveUpMainFocusCareerAction,
  moveDownMainFocusCareerAction,
  createMainFocusCareerAction,
  deleteMainFocusCareerAction
} from "Connectivity/Api";
import styled from "styled-components";
import Career from "./Career";
import Focus from "./Focus";
import notify, { addNotification } from "Utils/SaveNotification";

const FocusList: React.FunctionComponent<{}> = () => {
  const [addNewIsOpen, toggle] = useToggle(false);
  const [focustList, setFocus] = useState<Array<KshClassesCareerTkshCareer>>(
    []
  );

  const fetchData = useCallback(async () => {
    const result = await getMainFocusDataAction();
    sortResult(result);
    setFocus(result);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Table>
        <tbody>
          {focustList &&
            focustList.map((career: KshClassesCareerTkshCareer, i) => (
              <Focus
                career={career}
                key={`career-${i}`}
                reFetch={(newFocusList: Array<KshClassesCareerTkshCareer>) => setFocus(newFocusList)}
                updateCareer={addNotification(updateMainFocusDataAction)}
                deleteCareer={addNotification(deleteMainFocusCareerAction)}
                reFetchWithoutNewList={fetchData}
                moveUp={async () => {
                  let list = await moveUpMainFocusCareerAction(career);
                  notify();
                  list = sortResult(list);
                  setFocus(list);
                }}
                moveDown={async () => {
                  let list = await moveDownMainFocusCareerAction(career);
                  notify();
                  list = sortResult(list);
                  setFocus(list);
                }}
              >
                {career.caption}
              </Focus>
            ))}

          {addNewIsOpen && (
            <Focus
              career={{}}
              isNew={true}
              updateCareer={createMainFocusCareerAction}
              toggleAddNew={toggle}
              reFetch={async (newFocusList: Array<KshClassesCareerTkshCareer>) => {
                await setFocus(newFocusList);
                toggle();
              }}
            />
          )}
        </tbody>
      </Table>

      {!addNewIsOpen && (
        <Button fullsize cta onClick={() => toggle()}>
          neuen Arbeits- und Forschungsschwerpunkt hinzufügen
        </Button>
      )}
    </>
  );
};

export default FocusList;
