import React, { useState, useEffect, useCallback } from "react";
import { KshClassesCareerTkshCareer } from "Connectivity/Portal";
import FunctionExternal from "./FunctionExternal";
import { useToggle } from "Utils/Hooks";
import Button from "02_atoms/Button";
import { sortResult } from "Utils/CareerSort";
import {
  getFunctionExternalDataAction,
  createFunctionExternalCareerAction,
  confirmFunctionExternalCareerAction,
  moveDownFunctionExternalCareerAction,
  moveUpFunctionExternalCareerAction,
  updateFunctionExternalDataAction
} from "../../Connectivity/Api";
import styled from "styled-components";
import Table from "02_atoms/Form/Table";
import notify, { addNotification } from "Utils/SaveNotification";

const FunctionExternalList: React.FunctionComponent<{}> = () => {
  const [addNewIsOpen, toggle] = useToggle(false);
  const [careersList, setCareers] = useState<Array<KshClassesCareerTkshCareer>>(
    []
  );

  const fetchData = useCallback(async () => {
    const result = await getFunctionExternalDataAction();
    sortResult(result);
    setCareers(result);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Table>
        <tbody>
          {careersList &&
            careersList.map((career: KshClassesCareerTkshCareer, i) => (
              <FunctionExternal
                career={career}
                key={`career-${i}`}
                reFetch={(newFunctionExternal: Array<KshClassesCareerTkshCareer>) => setCareers(newFunctionExternal)}
                reFetchWithoutNewList={fetchData}
                updateCareer={addNotification(updateFunctionExternalDataAction)}
                deleteCareer={addNotification(confirmFunctionExternalCareerAction)}
                moveUp={async () => {
                  let list = await moveUpFunctionExternalCareerAction(career);
                  notify();
                  list = sortResult(list);
                  setCareers(list);
                }}
                moveDown={async () => {
                  let list = await moveDownFunctionExternalCareerAction(career);
                  notify();
                  list = sortResult(list);
                  setCareers(list);
                }}
              />
            ))}

          {addNewIsOpen && (
            <FunctionExternal
              career={{}}
              isNew={true}
              updateCareer={createFunctionExternalCareerAction}
              toggleAddNew={toggle}
              reFetch={async (newFunctionExternal: Array<KshClassesCareerTkshCareer>) => {
                await setCareers(newFunctionExternal);
                toggle();
              }}
            />
          )}
        </tbody>
      </Table>

      {!addNewIsOpen && (
        <Button fullsize cta onClick={() => toggle()}>
          neue weitere Funktion und Mitgliedschaft hinzufügen
        </Button>
      )}
    </>
  );
};

export default FunctionExternalList;
