import { toast } from "react-toastify";
import React from "react";
import styled from "styled-components";

const Headline = styled.strong`
  font-size: 20px;
  font-weight: bold;
`;

const Message: React.FunctionComponent<{}> = () => (
  <>
    <Headline>Änderungen wurde gespeichert</Headline>
    <br />
    <span>
      Bitte beachten Sie, dass es ein paar Minuten dauern kann bis die
      Änderungen sichtbar werden
    </span>
  </>
);

const notify = () => {
  toast.success(<Message />, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
};

export default notify;

export const addNotification = (originalFunction: Function): any => {
  return async (...args: any[]) => {
    notify();
    return await originalFunction(...args);
  };
};
