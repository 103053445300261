import styled from "styled-components";
import React from "react";

const StyledMoveActionButton = styled("td")`
  vertical-align: middle;
  width: 6%;
  /* width: 50px; */

  .actions {
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */

    a {
      width: 20px;
      height: 20px;
      margin-right: 3px;
      /* border: 2px solid black; */
      text-align: center;
      border-radius: 20px;

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
`;

const MoveActionButton: React.FC<{
  moveUp: Function | undefined;
  moveDown: Function | undefined;
}> = ({ moveUp, moveDown }) => (
  <StyledMoveActionButton>
    <div className="actions">
      {moveUp && (
        <a
          className="button"
          title="up"
          onClick={() => {
            moveUp();
          }}
        >
          ▼
        </a>
      )}

      {moveDown && (
        <a
          className="button"
          title="down"
          onClick={() => {
            moveDown();
          }}
        >
          ▲
        </a>
      )}
    </div>
  </StyledMoveActionButton>
);

export default MoveActionButton;
