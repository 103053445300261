import React from "react";
interface FromTo {
  yearfrom?: number | string;
  yearto?: number | string;
}

export default (values: FromTo) => {
  let showYearFrom = false;
  if (values.yearfrom && values.yearfrom > 0) {
    showYearFrom = true;
  }

  let showYearTo = false;
  if (values.yearto && values.yearto > 0) {
    showYearTo = true;
  }
  return (
    <>
      {showYearFrom && (
        <>
          {!showYearTo && "seit "} {values.yearfrom} {showYearTo && "- "}
        </>
      )}

      {!showYearFrom && showYearTo && "bis "}
      {showYearTo && values.yearto}
    </>
  );

};