import React, { useRef } from "react";
import "Types/reach__dialog.d.ts";
import "@reach/dialog/styles.css";
import FormRow from "02_atoms/Form/FormRow";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Overlay from "02_atoms/Overlay";
import DataWrapper from "02_atoms/DataWrapper";
import StyledForm from "04_organisms/Form";
import Button from "02_atoms/Button";
import CancelButton from "02_atoms/CancelButton";

type MobileProps = {
  updateMobile: Function;
  mobile: string | undefined;
};

const Mobile: React.FunctionComponent<MobileProps> = ({
  updateMobile,
  mobile
}) => {
  const closeFunction = useRef<Function>(() => {});

  // @TODO get rid of any
  const onSaveChange = async (values: any) => {
    const result = await updateMobile(values.mobile);
    if (!result) {
      return [FORM_ERROR];
    }
    closeFunction.current();
  };

  return (
    <>
      <DataWrapper>
        <label>Handynummer *</label>
        {/* <p>Lorem ipsum Erklärung der Verwendung</p> */}
        <p>{mobile}</p>
      </DataWrapper>

      <Overlay editLabel="Handyummer bearbeiten" closeFunction={closeFunction}>
        <Form
          onSubmit={onSaveChange}
          render={({
            submitError,
            handleSubmit,
            form,
            submitting,
            pristine,
            values
          }) => (
            <StyledForm onSubmit={handleSubmit}>
              {submitError && <div className="error">{submitError}</div>}
              <DataWrapper>
                <label>Handyummer</label>
                <Field
                  name="mobile"
                  component="input"
                  value={mobile}
                  type="text"
                  placeholder="Handynummer"
                />
              </DataWrapper>
              <DataWrapper>
                <Button type="submit" disabled={submitting || pristine}>
                  Übernehmen
                </Button>
                <CancelButton closeFunction={() => closeFunction.current()} />
              </DataWrapper>
            </StyledForm>
          )}
        />
      </Overlay>
    </>
  );
};

export default Mobile;
