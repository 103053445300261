import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import Note from "02_atoms/Note";
import { FORM_ERROR } from "final-form";
import Page from "05_templates/Page";
import FormRow from "02_atoms/Form/FormRow";
import Button from "02_atoms/Button";
import { PageHeader, PageHeadline, PageDescription } from "02_atoms/PageHeader";
import StyledForm from "04_organisms/Form";
import { Link } from "@reach/router";
import { passwordReset } from "Connectivity/Api";
import { KshClassesUserPasswordresetTkshPasswordReset } from "Connectivity/Portal";
import PasswordValidation, {
  passwordRules
} from "03_molecules/PasswordValidation";
import FormError from "02_atoms/FormError";
import { navigate } from "@reach/router";
import Recaptcha from "../Utils/Recaptcha";

type PasswordResetProps = {
  // updatePassword?: Function;
  tokenId?: String;
  initialResetted?: boolean;
};

const PasswordReset: React.FunctionComponent<PasswordResetProps> = ({
  //   updatePassword
  tokenId,
  initialResetted = false
}) => {
  const [enteredPassword, setEnteredPassword] = useState<string | null>(null);
  const [isPasswordResetted, setPasswordResetted] = useState<Boolean>(
    initialResetted
  );

  const onSubmit = async (
    // passwordNew,
    // passwordNewSecond,
    // kshKennung
    values: KshClassesUserPasswordresetTkshPasswordReset | any
  ) => {
    const { password, passwordRepeat, login } = values;
    if (!password) {
      return {
        [FORM_ERROR]: "Es wurde kein Passwort angegeben"
      };
    }
    const validationErrors = passwordRules.filter(
      rule => !rule.validate(password)
    );

    if (validationErrors.length > 0) {
      return {
        [FORM_ERROR]: "Das Passwort entspricht nicht unseren Richtlinien"
      };
    }

    if (password !== passwordRepeat) {
      return {
        [FORM_ERROR]: "Passwörter stimmen nicht überein"
      };
    }

    if (!tokenId || !login) {
      return { [FORM_ERROR]: "Ihre KSH Kennung fehlt" };
    }

    // @ts-ignore
    const reCaptchaResponse = window.grecaptcha.getResponse();
    if(reCaptchaResponse.length == 0) {
        return { [FORM_ERROR]: "Bitte bestätigen Sie das Captcha" };
    }

    const passwordToUpdate: KshClassesUserPasswordresetTkshPasswordReset = {
      token: tokenId.toString(),
      login: login,
      unsec: password
    };

    const success = await passwordToReset(passwordToUpdate);

    if (success) {
      navigate(`/reset/success`);
    }

    return { [FORM_ERROR]: "Es ist ein Fehler aufgetreten" };
  };

  const passwordToReset = async (
    values: KshClassesUserPasswordresetTkshPasswordReset
  ) => {
    const updatePassword = {
      login: values.login,
      token: tokenId ? tokenId.toString() : "",
      unsec: values.unsec
    };

    const result = await passwordReset(updatePassword);

    // @ts-ignore
    return result.result === "ok";
  };

  if (isPasswordResetted) {
    return (
      <Page navbarInitialShown={false}>
        <PageHeader>
          <PageHeadline>Passwort geändert</PageHeadline>
          <PageDescription>
            Vielen Dank. Sie haben ein neues Passwort vergeben. Sie können sich
            in wenigen Momenten mit diesem einloggen.
          </PageDescription>
        </PageHeader>
        <div>
          <Link to="/">Zurück zum Login</Link>
        </div>
      </Page>
    );
  }

  return (
    <Page navbarInitialShown={false}>
      <PageHeader>
        <PageHeadline>Setzen Sie hier Ihr Password zurück</PageHeadline>
        <PageDescription>
          Hier haben Sie die Möglichkeit Ihr Passwort zurückzusetzen. Dafür
          geben Sie bitte Ihren Nutzernamen und Ihren Geburtstag ein.
        </PageDescription>
      </PageHeader>
      <PasswordValidation password={enteredPassword} />
      <Form
        onSubmit={onSubmit}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values
        }) => (
          <StyledForm onSubmit={handleSubmit}>
            {submitError && <div className="error">{submitError}</div>}
            <FormRow>
              <label>Ihre KSH Kennung:</label>
              <Field
                name="login"
                component="input"
                type="text"
                autoComplete="off"
              />
              <em>
                Hinweis: Sollten Sie Ihre Kennung vergessen haben, wenden Sie
                Sich bitte per E-Mail an{" "}
                <a href="mailto:support@ksh-m.de">support@ksh-m.de</a>
              </em>
            </FormRow>
            <FormRow>
              <label>Ihr neues Passwort:</label>
              <Field
                name="password"
                component="input"
                type="password"
                autoComplete="off"
                validate={value => {
                  if (value !== undefined) {
                    setEnteredPassword(value);
                  }
                }}
              />
            </FormRow>
            <FormRow>
              <label>Ihr neues Passwort wiederholen:</label>
              <Field
                name="passwordRepeat"
                component="input"
                type="password"
                autoComplete="off"
              />
            </FormRow>
            <Recaptcha />
            {submitError && <FormError>{submitError}</FormError>}
            <Button
              type="submit"
              cta
              fullsize
              disabled={submitting || pristine}
            >
              neues Passwort speichern
            </Button>
          </StyledForm>
        )}
      />
      <div>
        <Link to="/">Zurück zum Login</Link>
      </div>
    </Page>
  );
};

export default PasswordReset;
