import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import Loading from "02_atoms/Loading";

const UploadContainer = styled.div`
  border: 1px solid #ccc;
  margin-top: 1rem;
  padding: 20px;
`;

type FileUploadProps = {
  onFileUploadCompleted: Function;
  accept?: Array<string>;
  humanReadabledAcceptedFiletypes?: string;
  extraMessageForOneFile?: string;
};

const FileUpload: React.FunctionComponent<FileUploadProps> = ({
  onFileUploadCompleted,
  accept = ["application/pdf"],
  humanReadabledAcceptedFiletypes = "pdf",
  extraMessageForOneFile = " - Nur eine PDF-Datei je Beitrag"
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const onDrop = useCallback(
    acceptedFiles => {
      setIsUploading(true);
      // Do something with the files

      if (acceptedFiles.length === 0) {
        return setIsUploading(false);
      }

      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async () => {
        // remove base64header
        // @ts-ignore
        const content = reader.result.substr(reader.result.indexOf(",") + 1);

        await onFileUploadCompleted(acceptedFiles[0].name, content);
        setIsUploading(false);
      };

      reader.readAsDataURL(acceptedFiles[0]);
    },
    [onFileUploadCompleted]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept,
    multiple: false
  });
  return (
    <UploadContainer {...getRootProps()}>
      <input {...getInputProps()} />

      {isUploading ? (
        <Loading width={40} height={40} />
      ) : (
          <>
            <p>
              {isDragActive
                ? "Datei hier ablegen"
                : "Datei hier ablegen oder per Click auswählen"}
            </p>
            <em>
              (Es werden nur <strong>*.{humanReadabledAcceptedFiletypes}</strong> Dateien akzeptiert) {extraMessageForOneFile}
            </em>
          </>
        )}
    </UploadContainer>
  );
};

export default FileUpload;
