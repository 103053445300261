import React from "react";
import styled, { css } from "styled-components";

const TestErrorB = styled("button")`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid;
  z-index: 100;
  display: block;
`;
const TestError: React.FunctionComponent = (): any => {
  return (
    <TestErrorB
      onClick={() => {
        throw new Error("Exception message");
      }}
    >
      TestErrorButton
    </TestErrorB>
  );
};

export default TestError;
