import styled from "styled-components";
import React from "react";
import { number } from "prop-types";

const LoadingWrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  text-align: center;
`;

type LoadingProps = {
  width?: number;
  height?: number;
};

type SpinnerProps = {
  width: number;
  height: number;
};
const Spinner: React.FunctionComponent<SpinnerProps> = ({ width, height }) => (
  <StyledSpinner viewBox="0 0 50 50" width={width} height={height}>
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="2"
    />
  </StyledSpinner>
);

const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  margin: 10px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  & .path {
    stroke: ${props => props.theme.colors.orange};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const Loading: React.FunctionComponent<LoadingProps> = ({
  width = 100,
  height = 100
}) => (
  <LoadingWrapper>
    <div>
      <Spinner width={width} height={height} />
    </div>
  </LoadingWrapper>
);

export default Loading;
