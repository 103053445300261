import { display } from "Utils/Display";
import React, { ReactNode, ReactElement } from "react";

type HiddenProps = {
  children: ReactNode;
  testValue?: Number | String | null | undefined;
};

const Hidden: React.FunctionComponent<HiddenProps> = (props): any => {
  const { children, testValue = null } = props;

  if (testValue === null) {
    return children;
  }

  if (display(testValue)) {
    return children;
  }

  return false;
};

export default Hidden;
