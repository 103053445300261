import React, { ReactChild } from "react";
import styled, { keyframes, css } from "styled-components";
import FormHeadline from "02_atoms/FormHeadline";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`;

type SectionProps = {
  isOpen?: Boolean;
};

const Section = styled("section")<SectionProps>`
  border-top: 1px solid grey;
  padding: 1rem;

  div.body {
    max-height: ${props => (props.isOpen ? "10000px" : "0")};
    overflow: hidden;
    margin-top: ${props =>
      // props.isOpen ? "border-top: 1px solid grey; margin-top: 15px" : ""};
      props.isOpen ? "15px" : "0"};
    transition: max-height 0.5s, margin-top 0.5s;
  }

  h2 {
    cursor: pointer;
  }
`;

const ToggleButton = styled("button")<SectionProps>`
  float: right;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border: 2px solid black;
  border-radius: 30px;
  font-size: 0.6rem;
  padding: 0;
  ${props =>
    props.isOpen ? "transform: rotate(180deg)" : "transform: rotate(0);"};
  transition: all 0.5s;
  margin-left: auto;
  margin-right: 0;
`;

type OpenableProps = {
  children: ReactChild;
  label: string;
  open?: boolean;
  toggleOpen: Function;
  toggleClose: Function;
};

const Openable: React.FunctionComponent<OpenableProps> = ({
  open = false,
  children,
  label,
  toggleOpen,
  toggleClose
}) => {
  const clickFunction = open ? toggleClose : toggleOpen;

  return (
    <Section isOpen={open}>
      <FormHeadline onClick={() => clickFunction()}>
        {label} <ToggleButton isOpen={open}>▼</ToggleButton>
      </FormHeadline>
      {open && <div className="body">{children}</div>}
    </Section>
  );
};

export default Openable;
