import React, { useState, useEffect, useCallback } from "react";
import { useToggle } from "Utils/Hooks";
import { KshClassesPublicationTkshPublication } from "Connectivity/Portal";
import Button from "02_atoms/Button";
import { sortResult } from "Utils/CareerSort";
import {
  getServiceResearchAction,
  updateServiceResearchAction,
  moveUpServiceResearchAction,
  moveDownServiceResearchAction,
  createServiceResearchAction,
  deleteServiceResearchAction,
  updateServiceResearchFileAction,
  deleteServiceResearchFileAction
} from "Connectivity/Api";
import styled from "styled-components";
import Research from "./Research";
import Table from "02_atoms/Form/Table";
import notify, { addNotification } from "Utils/SaveNotification";

const ResearchList: React.FunctionComponent<{}> = () => {
  const [addNewIsOpen, toggle] = useToggle(false);
  const [researchList, setResearch] = useState<
    Array<KshClassesPublicationTkshPublication>
  >([]);

  const fetchData = useCallback(async () => {
    const result = await getServiceResearchAction();
    sortResult(result);
    setResearch(result);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Table>
        <tbody>
          {researchList &&
            researchList.map(
              (research: KshClassesPublicationTkshPublication, i) => (
                <Research
                  research={research}
                  key={`career-${i}`}
                  reFetch={(newResearchList: Array<KshClassesPublicationTkshPublication>) => setResearch(newResearchList)}
                  reFetchWithoutNewList={fetchData}
                  updateResearch={addNotification(updateServiceResearchAction)}
                  deleteResearch={addNotification(deleteServiceResearchAction)}
                  updateFile={updateServiceResearchFileAction}
                  deleteFile={deleteServiceResearchFileAction}
                  moveUp={async () => {
                    let list = await moveUpServiceResearchAction(research);
                    notify();
                    list = sortResult(list);
                    setResearch(list);
                  }}
                  moveDown={async () => {
                    let list = await moveDownServiceResearchAction(research);
                    notify();
                    list = sortResult(list);
                    setResearch(list);
                  }}
                >
                  {research.caption}
                </Research>
              )
            )}

          {addNewIsOpen && (
            <Research
              research={{}}
              isNew={true}
              updateResearch={createServiceResearchAction}
              toggleAddNew={toggle}
              reFetch={async (newResearchList: Array<KshClassesPublicationTkshPublication>) => {
                await setResearch(newResearchList);
                toggle();
              }} />
          )}
        </tbody>
      </Table>

      {!addNewIsOpen && (
        <Button fullsize cta onClick={() => toggle()}>
          neue Forschung hinzufügen
        </Button>
      )}
    </>
  );
};

export default ResearchList;
