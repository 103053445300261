import React, { useState } from "react";
import DateRow from "02_atoms/Form/DateRow";
import { KshClassesCareerTkshCareer } from "Connectivity/Portal";
import { Field } from "react-final-form";
import { number } from "prop-types";

type DateSelectorProps = {
  item: KshClassesCareerTkshCareer;
};

const DateSelector: React.FunctionComponent<DateSelectorProps> = ({ item }) => {
  const [yearTo, setYearTo] = useState<number>(item.yearto || 0);
  const [untilNow, setUntilNow] = useState<boolean>(yearTo === 0);

  return (
    <DateRow>
      <label>Von - Bis</label>
      <Field
        name="yearfrom"
        component="input"
        type="number"
        placeholder="Von"
      />
      <Field name="yearto" component="input" type="number" placeholder="Bis" />
    </DateRow>
  );
};

export default DateSelector;
