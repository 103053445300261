import React, { useContext } from "react";
import Page from "05_templates/Page";
import { hasGroup } from "Utils/Groups";
import FormHeadline from "02_atoms/FormHeadline";
import Openable from "03_molecules/Openable";
import ImageEditor from "04_organisms/ImageEditor";
import Password from "04_organisms/Data/Password";
import { navigate } from "@reach/router";
import { PageHeader, PageHeadline, PageDescription } from "02_atoms/PageHeader";
import styled from "styled-components";
import Personal from "04_organisms/Data/Personal";
import Study from "04_organisms/Data/Study";
import Lecturer from "04_organisms/Data/Lecturer";
import Staff from "04_organisms/Data/Staff";
import { RouteComponentProps } from "@reach/router";
import { LoginContext } from "../LoginContext";

const SectionLinkList = styled.ul`
  margin: 10px;
`;

const Section = styled("section")``;

const SectionLink = styled.li`
  font-family: "Droid Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  padding: 5px;
`;

type DataProps = {
  section?: String;
};

const Data: React.FunctionComponent<DataProps> = ({ section = "" }) => {
  const context = useContext(LoginContext);

  const { user, updateEmail, updatePassword, updateMobile } = context;

  if (!user) {
    return null;
  }

  return (
    <Page>
      <PageHeader>
        <PageHeadline>Ihre Benutzerdaten</PageHeadline>
      </PageHeader>
      <Openable
        label="Persönliche Daten"
        open={section === "personal"}
        toggleOpen={() => navigate(`/data/personal`)}
        toggleClose={() => navigate(`/data`)}
      >
        <Personal
          user={user}
          updateEmail={updateEmail}
          updateMobile={updateMobile}
        />
      </Openable>

      <Openable
        label="Passwort"
        open={section === "password"}
        toggleOpen={() => navigate(`/data/password`)}
        toggleClose={() => navigate(`/data`)}
      >
        <Password updatePassword={updatePassword} />
      </Openable>

      {(hasGroup(user, "staff") || hasGroup(user, "professors")) && (
        <Openable
          label="Mitarbeiterdaten"
          open={section === "staff"}
          toggleOpen={() => navigate(`/data/staff`)}
          toggleClose={() => navigate(`/data`)}
        >
          <Staff user={user} />
        </Openable>
      )}

      {hasGroup(user, "students") && (
        <Openable
          label="zum Studium"
          open={section === "study"}
          toggleOpen={() => navigate(`/data/study`)}
          toggleClose={() => navigate(`/data`)}
        >
          <Study user={user} />
        </Openable>
      )}

      {hasGroup(user, "lecturers") && (
        <Openable
          label="zum Lehrauftrag"
          open={section === "lecturer"}
          toggleOpen={() => navigate(`/data/lecturer`)}
          toggleClose={() => navigate(`/data`)}
        >
          <Lecturer user={user} />
        </Openable>
      )}
      
      {hasGroup(user, "any") && (
        <Openable
          label="Ausweisbild"
          open={section === "image"}
          toggleOpen={() => navigate(`/data/image`)}
          toggleClose={() => navigate(`/data`)}
        >
          <ImageEditor />
        </Openable>
      )}
    </Page>
  );
};

export default Data;
