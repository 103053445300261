import styled, { keyframes } from "styled-components";

const loading = keyframes`
  from {
    background-position: 0 0;
    /* rotate: 0; */
  }

  to {
    background-position: 100% 100%;
    /* rotate: 360deg; */
  }
`;

const Form = styled.form`
  /* padding: 20px; */
  font-weight: 600;

  label {
    border-bottom: 2px solid ${props => props.theme.colors.orange};
    padding-bottom: 0.4rem;
    margin-bottom: 2px;
    display: inline-block;
    font-weight: bold;
  }

  input,
  textarea,
  select,
  img {
    width: calc(100% - 1rem);
    border: 1px solid ${props => props.theme.colors.grey};
    margin-top: 1rem;
    padding: 0.5rem;

    &:focus {
      outline: 0;
      border-color: ${props => props.theme.colors.orange};
    }
  } 
  

  em {
    color: ${props => props.theme.colors.orange};
    font-style: italic;
    font-size: 0.6rem;
  }  
  /* button,
  input[type="submit"] {
    width: auto;
    background: ${props => props.theme.colors.orange};
    color: white;
    border: 0;
    font-size: 2rem;
    font-weight: 600;
    padding: 0.5rem 1.2rem;
  } */
  
  fieldset {
    border: 0;
    padding: 0;

    &[disabled] {
      opacity: 0.5;
    }
    &::before {
      height: 10px;
      content: "";
      display: block;
      background-image: linear-gradient(
        to right,
        #ff3019 0%,
        #e2b04a 50%,
        #ff3019 100%
      );
    }
    &[aria-busy="true"]::before {
      background-size: 50% auto;
      animation: ${loading} 0.5s linear infinite;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0
  }
`;

export default Form;
