import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";

export const theme = {
  font: {
    sans: 'font-family: "Droid Sans", "Helvetica Neue", sans-serif;'
  },
  maxWidth: "900px",
  colors: {
    orange: "#ea5906",
    darkgrey: "#333",
    grey: "#ccc",
    white: "#FFF"
  }
};

export type Theme = typeof theme;

const Base = createGlobalStyle<{ theme: Theme }>`
  ${reset}

  @font-face {
    font-family: 'Droid Sans';
    font-style: normal;
    font-weight: 400;
    src: local("Droid Sans Regular"), local("DroidSans-Regular"), url(https://www.ksh-muenchen.de/typo3conf/ext/ksfh/Resources/Public/fonts/SlGVmQWMvZQIdix7AFxXkHNSbRYXags.woff2?269d5d0785ee700528e67019d51209d4) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

  /* latin */
  @font-face {
    font-family: 'Droid Sans';
    font-style: normal;
    font-weight: 700;
    src: local("Droid Sans Bold"), local("DroidSans-Bold"), url(https://www.ksh-muenchen.de/typo3conf/ext/ksfh/Resources/Public/fonts/SlGWmQWMvZQIdix7AFxXmMh3eDs1ZyHKpWg.woff2?50399f4d0604c644c2855d4619a80e3c) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

  /* latin */
  @font-face {
    font-family: 'Droid Serif';
    font-style: italic;
    font-weight: 400;
    src: local("Droid Serif Italic"), local("DroidSerif-Italic"), url(https://www.ksh-muenchen.de/typo3conf/ext/ksfh/Resources/Public/fonts/tDbK2oqRg1oM3QBjjcaDkOr4nAfcHi6FRUI.woff2?0d724dec30d8f986c72903821ad963c6) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

  /* latin */
  @font-face {
    font-family: 'Droid Serif';
    font-style: normal;
    font-weight: 400;
    src: local("Droid Serif Regular"), local("DroidSerif-Regular"), url(https://www.ksh-muenchen.de/typo3conf/ext/ksfh/Resources/Public/fonts/tDbI2oqRg1oM3QBjjcaDkOr9rAXWGQyH.woff2?2c6f2cbad27e877d25b3856f348385b8) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

  /* latin */
  @font-face {
    font-family: 'Droid Serif';
    font-style: normal;
    font-weight: 700;
    src: local("Droid Serif Bold"), local("DroidSerif-Bold"), url(https://www.ksh-muenchen.de/typo3conf/ext/ksfh/Resources/Public/fonts/tDbV2oqRg1oM3QBjjcaDkOJGiRD7OwGtT0rU.woff2?554a2104939a22de12fd345f811896a9) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }  

  body {
    font-size: 15px;
    line-height: 1.42857;
    background-color: #fff; 
    ${props => props.theme.font.sans}
    color: ${props => props.theme.colors.darkgrey}
  }

  h1,h2,h3,h4,h5 {
    ${props => props.theme.font.sans}
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; 
  }

  a {
    color: ${props => props.theme.colors.orange};
    text-decoration: none; 
  }

  a:hover, a:focus {
    color: ${props => props.theme.colors.orange};
    text-decoration: underline; 
  }

  a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; 
  }  

`;

export default Base;
