import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { User } from "Types/User";
import { Student, Lecturer } from "Types";
import {
  switchStudentToAccount,
  switchLecuturerToAccount,
  switchStudentToForwarding,
  switchLecuturerToForwarding
} from "Connectivity/Api";
import Overlay from "02_atoms/Overlay";
import Page from "05_templates/Page";
import FormRow from "02_atoms/Form/FormRow";
import { Form, Field } from "react-final-form";
import { async } from "q";
import {
  KshClassesStudentTkshStudent,
  KshClassesEmailAliasTkshEmailAlias,
  KshClassesLecturerTkshLecturer
} from "Connectivity/Portal";
import { useToggle, useMount } from "../../Utils/Hooks";
import DataWrapper from "02_atoms/DataWrapper";
import CancelButton from "02_atoms/CancelButton";
import Button from "02_atoms/Button";

const lecuturerEmailDomainId = 20;

const StyledSelector = styled.form`
  padding: 20px;
  font-weight: 600;
  fieldset {
    border: 0;
    padding: 0;

    &[disabled] {
      opacity: 0.5;
    }
    &::before {
      height: 10px;
      content: "";
      display: block;
      background-image: linear-gradient(
        to right,
        #ff3019 0%,
        #e2b04a 50%,
        #ff3019 100%
      );
    }
    &[aria-busy="true"]::before {
      background-size: 50% auto;
    }
  }
`;

type EmailAliasProps = {
  user: User;
  personData: Student | Lecturer;
  getEmailAliasAction: Function;
};

const EmailAlias: React.FunctionComponent<EmailAliasProps> = ({
  personData,
  user,
  getEmailAliasAction
}) => {
  const [
    emailAlias,
    setEmailAlias
  ] = useState<KshClassesEmailAliasTkshEmailAlias | null>(null);

  const loadEmailAliasData = async () => {
    const Alias = await getEmailAliasAction();
    setEmailAlias(Alias);
  };

  useMount(() => {
    loadEmailAliasData();
  });

  if (!emailAlias) {
    return null;
  }

  return (
    <EmailAliasForm
      user={user}
      personData={personData}
      emailAlias={emailAlias}
    />
  );
};

type EmailAliasFormProps = {
  emailAlias: KshClassesEmailAliasTkshEmailAlias;
  user: User;
  personData: Student | Lecturer;
};

const EmailAliasForm: React.FunctionComponent<EmailAliasFormProps> = ({
  user,
  personData,
  emailAlias
}) => {
  const closeFunction = useRef<Function>(() => {});
  const [isChecked, toggle] = useToggle(emailAlias.isaccount === 1);

  const updateEmailAlias = async (
    person: KshClassesStudentTkshStudent | KshClassesLecturerTkshLecturer
  ) => {
    let switchToMailForwarding = switchStudentToForwarding;
    let switchToMailAccount = switchStudentToAccount;

    if (emailAlias.domainid === lecuturerEmailDomainId) {
      switchToMailForwarding = switchLecuturerToForwarding;
      switchToMailAccount = switchLecuturerToAccount;
    }

    person.kshemail === user.email
      ? switchToMailForwarding(emailAlias)
      : switchToMailAccount(emailAlias);

    closeFunction.current();
  };

  return (
    <>
      <DataWrapper>
        <label>KSH-Email-Adresse</label>
        <p>{emailAlias.source}</p>
      </DataWrapper>

      <Overlay editLabel="Zustellungsart wählen" closeFunction={closeFunction}>
        <Form
          onSubmit={updateEmailAlias}
          render={({
            submitError,
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            errors
          }) => (
            <StyledSelector onSubmit={handleSubmit}>
              {submitError && <div className="error">{submitError}</div>}
              <FormRow>
                <label>
                  <Field
                    name="kshemail"
                    component="input"
                    type="radio"
                    value={emailAlias.destination}
                    checked={!isChecked}
                    onClick={toggle}
                  />
                  Weiterleitung an {emailAlias.destination}
                </label>
              </FormRow>
              <FormRow>
                <label>
                  <Field
                    name="kshemail"
                    component="input"
                    type="radio"
                    value={emailAlias.source}
                    checked={isChecked}
                    onClick={toggle}
                  />
                  Empfang im Postfach {emailAlias.source}
                </label>
              </FormRow>
              <FormRow>
                <Button type="submit" disabled={submitting || pristine}>
                  Speichern
                </Button>
                <CancelButton closeFunction={() => closeFunction.current()} />
              </FormRow>
            </StyledSelector>
          )}
        />
      </Overlay>
    </>
  );
};

export default EmailAlias;
