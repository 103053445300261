import styled from "styled-components";
import DataWrapper from "02_atoms/DataWrapper";

const FormRow = styled(DataWrapper)`
  /* padding: 0 0 10px 0; */
  overflow: hidden;

  a.button {
    border: 1px solid ${props => props.theme.colors.orange};
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    font-weight: normal;
    padding: 5px 10px;
    color: ${props => props.theme.colors.orange};
    cursor: pointer;
    padding: 5px;
  }
`;

export default FormRow;
