import React from "react";
import styled from "styled-components";

const DataWrapper = styled.div`
  padding: 7px 10px;
  ${props => props.theme.font.sans}
  h1,h2,h3,h4,h5 {
    ${props => props.theme.colors.darkgrey}
  }
  label {
    font-weight: bold;
  }
  p {
    /* font-weight: bold; */
  }
`;

export default DataWrapper;
