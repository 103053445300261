import React, { useState, useEffect, useCallback } from "react";
import { KshClassesCareerTkshCareer } from "Connectivity/Portal";
import { useToggle } from "Utils/Hooks";
import Button from "02_atoms/Button";
import { sortResult } from "Utils/CareerSort";
import {
  getFunctionKshDataAction,
  createFunctionKshCareerAction,
  confirmFunctionKshCareerAction,
  moveDownFunctionKshCareerAction,
  moveUpFunctionKshCareerAction,
  updateFunctionKshDataAction
} from "../../Connectivity/Api";
import FunctionKsh from "./FunctionKsh";
import Table from "02_atoms/Form/Table";
import notify, { addNotification } from "Utils/SaveNotification";

const FunctionKshList: React.FunctionComponent<{}> = () => {
  const [addNewIsOpen, toggle] = useToggle(false);
  const [careersList, setCareers] = useState<Array<KshClassesCareerTkshCareer>>(
    []
  );

  const fetchData = useCallback(async () => {
    const result = await getFunctionKshDataAction();
    sortResult(result);
    setCareers(result);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Table>
        <tbody>
          {careersList &&
            careersList.map((career: KshClassesCareerTkshCareer, i) => (
              <FunctionKsh
                career={career}
                key={`career-${i}`}
                reFetch={(newFunctionKshList: Array<KshClassesCareerTkshCareer>) => setCareers(newFunctionKshList)}
                reFetchWithoutNewList={fetchData}
                updateCareer={addNotification(updateFunctionKshDataAction)}
                deleteCareer={addNotification(confirmFunctionKshCareerAction)}
                moveUp={async () => {
                  let list = await moveUpFunctionKshCareerAction(career);
                  notify();
                  list = sortResult(list);
                  setCareers(list);
                }}
                moveDown={async () => {
                  let list = await moveDownFunctionKshCareerAction(career);
                  notify();
                  list = sortResult(list);
                  setCareers(list);
                }}
              />
            ))}

          {addNewIsOpen && (
            <FunctionKsh
              career={{}}
              isNew={true}
              updateCareer={createFunctionKshCareerAction}
              toggleAddNew={toggle}
              reFetch={async (newFunctionKshList: Array<KshClassesCareerTkshCareer>) => {
                await setCareers(newFunctionKshList);
                toggle();
              }}
            />
          )}
        </tbody>
      </Table>

      {!addNewIsOpen && (
        <Button fullsize cta onClick={() => toggle()}>
          neue Funktion an der KSH hinzufügen
        </Button>
      )}
    </>
  );
};

export default FunctionKshList;
