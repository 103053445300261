type Response<T> = {
  value?: Array<T>;
};

export default <T>(res: Response<T>): Array<T> => {
  if (res.value) {
    return res.value;
  }

  return [];
};
