import styled from "styled-components";

const Table = styled("table")`
  vertical-align: middle;
  position: relative;
  /* display: block; */
  margin: 10px auto;
  padding: 0;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  text-align: center;
  /* table-layout: fixed; */
`;

export default Table;
