import styled from "styled-components";

export const Footer = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 10px auto;
  padding: 10px;
`;

export const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.colors.grey};
`;
