import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DataWrapper from "../../02_atoms/DataWrapper";
import Overlay from "../../02_atoms/Overlay";
import { User } from "../../Types/User";
import { KshClassesLecturerTkshLecturer } from "../../Connectivity/Portal";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import FormRow from "02_atoms/Form/FormRow";
import FormHeadline from "../../02_atoms/FormHeadline";
import EmailAlias from "./EmailAlias";
import { getLecturerEmailAliasAction } from "Connectivity/Api";
import StyledForm from "04_organisms/Form";
import Button from "02_atoms/Button";
import CancelButton from "02_atoms/CancelButton";
import Hidden from "02_atoms/Hidden";

import {
  getLecturerDataAction,
  updateLectureDataAction
} from "../../Connectivity/Api";

type LecturerProps = {
  user: User;
};

const Lecturer: React.FunctionComponent<LecturerProps> = ({ user }) => {
  const closeFunction = useRef<Function>(() => { });
  const [
    lecturerData,
    setLecturerData
  ] = useState<KshClassesLecturerTkshLecturer | null>(null);

  useEffect(() => {
    const fetchLecturerData = async () => {
      const result = await getLecturerDataAction();
      setLecturerData(result);
    };

    fetchLecturerData();
  }, []);

  if (!lecturerData) {
    return null;
  }

  const onSaveChange = async (values: KshClassesLecturerTkshLecturer) => {
    const result = await updateLectureDataAction(values);
    if (!result) {
      return [FORM_ERROR];
    }
    setLecturerData(values);
    closeFunction.current();
  };

  const updateEmailAlias = async (lecturer: KshClassesLecturerTkshLecturer) => {
    const dataToUpdate = {
      ...lecturer
    };
    // await setEmailAlias(dataToUpdate);
    closeFunction.current();
  };

  return (
    <>
      <EmailAlias
        user={user}
        personData={lecturerData}
        getEmailAliasAction={getLecturerEmailAliasAction}
      />
      <Hidden testValue={lecturerData.qualification}>
        <DataWrapper>
          <label>Qualifikation</label>
          <p>{lecturerData.qualification}</p>
        </DataWrapper>
      </Hidden>
      <Hidden testValue={lecturerData.urlextern}>
        <DataWrapper>
          <label>Url (extern)</label>
          <p>
            <a href={lecturerData.urlextern} target="_blank">
              {lecturerData.urlextern}
            </a>
          </p>
        </DataWrapper>
      </Hidden>
      <Hidden testValue={lecturerData.workspace}>
        <DataWrapper>
          <label>Arbeitsfeld(er)</label>
          <p>{lecturerData.workspace}</p>
        </DataWrapper>
      </Hidden>
      <Overlay closeFunction={closeFunction}>
        <Form
          initialValues={lecturerData}
          onSubmit={onSaveChange}
          render={({
            submitError,
            handleSubmit,
            form,
            submitting,
            pristine,
            values
          }) => (
              <StyledForm onSubmit={handleSubmit}>
                {submitError && <div className="error">{submitError}</div>}
                <Hidden testValue={lecturerData.qualification}>
                  <FormRow>
                    <label>Qualifikation</label>
                    <Field
                      name="qualification"
                      component="input"
                      type="text"
                      placeholder="Qualifikation"
                    />
                  </FormRow>
                </Hidden>
                <Hidden testValue={lecturerData.urlextern}>
                  <FormRow>
                    <label>Url (extern)</label>
                    <Field
                      name="urlextern"
                      component="input"
                      type="text"
                      placeholder="https://..."
                    />
                  </FormRow>
                </Hidden>
                <Hidden testValue={lecturerData.workspace}>
                  <FormRow>
                    <label>Arbeitsfeld(er)</label>
                    <Field
                      name="workspace"
                      component="input"
                      type="text"
                      placeholder="Ihre Arbeitsfelder"
                    />
                  </FormRow>
                </Hidden>
                <FormRow>
                  <Button type="submit" disabled={submitting || pristine}>
                    Übernehmen
                </Button>
                  <CancelButton closeFunction={() => closeFunction.current()} />
                </FormRow>
              </StyledForm>
            )}
        />
      </Overlay>
    </>
  );
};

export default Lecturer;
