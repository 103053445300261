import { useState, useEffect, EffectCallback } from "react";

export const useToggle = (initial: Boolean): [Boolean, Function] => {
  const [isOn, setIsOn] = useState<Boolean>(initial);

  const toggle = () => {
    setIsOn(!isOn);
  };

  return [isOn, toggle];
};

export const useMount = (effect: EffectCallback) => {
  useEffect(effect, []);
};
