import styled from "styled-components";
import FormRow from "./FormRow";

const CheckboxRow = styled(FormRow)`
  /* padding: 0 0 10px 0; */
  input {
    width: 15px;
    height: 15px;
    margin-left: calc(12% - 1rem);
  }
`;

export default CheckboxRow;
