import React from "react";
import Note from "02_atoms/Note";
import styled from "styled-components";

function createMarkup(html: string) {
  return { __html: html };
}

const BoldWrapper = styled("p")`
  font-weight: bold;
`;

type PasswordValidationProps = {
  password: string | null;
};

type passwordRule = {
  text: string;
  validate(password: string): boolean;
};

const RuleValid = styled.span`
  color: green;
`;

const RuleInvalid = styled.span`
  color: red;
`;

export const passwordRules: Array<passwordRule> = [
  {
    text: "mindestens 8 Zeichen lang",
    validate: password => password.length >= 8
  },
  {
    text: "mindestens ein großer und ein kleiner Buchstabe",
    validate: password =>
      new RegExp(/[a-z]/).test(password) && new RegExp(/[A-Z]/).test(password)
  },
  {
    text: "mindestens eine Ziffer",
    validate: password => new RegExp(/[0-9]/).test(password)
  },
  {
    text: 'mindestens ein Sonderzeichen !$"%()=?*+#;,:._-',
    validate: password => new RegExp(/[!$"%()=?*+#;,:._-]/).test(password)
  },
  {
    text: "nicht mögliche Sonderzeichen &/|§²³°€µ~@^<>äÄöÖüÜß",
    validate: password =>
      !new RegExp(/[&\/\\|§²³°€µ~@^<>äÄöÖüÜß]/).test(password)
  }
];

const PasswordValidation: React.FunctionComponent<PasswordValidationProps> = ({
  password = null
}) => {
  return (
    <Note>
      <BoldWrapper>Passwortrichtlinien</BoldWrapper>
      <ul>
        {passwordRules.map((rule, i) => {
          return (
            <li key={`rule-${i}`}>
              {password && (
                <>
                  {rule.validate(password) ? (
                    <RuleValid
                      dangerouslySetInnerHTML={createMarkup(" &check; ")}
                    />
                  ) : (
                    <RuleInvalid
                      dangerouslySetInnerHTML={createMarkup(" &cross; ")}
                    />
                  )}
                </>
              )}
              {rule.text}
            </li>
          );
        })}
        <li>
          <a
            href="https://www.bsi-fuer-buerger.de/BSIFB/DE/Empfehlungen/Passwoerter/passwoerter_node.html"
            target="_blank"
          >
            Was ist ein gutes Passwort?
          </a>
        </li>
      </ul>
    </Note>
  );
};

export default PasswordValidation;
