import { User } from "../Types/User";
import { Group } from "../Types";

export const hasGroup = (user: User | null, desiredGroup?: Group): Boolean => {
  if (!user) {
    return false;
  }

  if (!user.groups) {
    return false;
  }

  if (user.groups && desiredGroup === "any") {
    return true;
  }
  
  const groups = user.groups.filter(group => group.caption === desiredGroup);

  return groups.length > 0;
};
