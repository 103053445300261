import styled from "styled-components";

const FormActionContainer = styled("div")`
  /* display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 20px; */

  overflow: hidden;

  textarea {
    width: calc(100% - 20px);
    height: 100px;
  }
`;

export default FormActionContainer;
