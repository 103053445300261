import React, { useState, useEffect, useCallback } from "react";
import { KshClassesCareerTkshCareer } from "Connectivity/Portal";
import Career from "./Career";
import { useToggle, useMount } from "Utils/Hooks";
import Button from "02_atoms/Button";
import Table from "02_atoms/Form/Table";
import { sortResult } from "Utils/CareerSort";

import {
  getProfessorCareerListDataAction,
  createProfessorCareerAction,
  deleteProfessortCareerAction,
  moveDownProfessorCareerAction,
  moveUpProfessorCareerAction,
  updateProfessorCareerDataAction
} from "../../Connectivity/Api";
import notify, { addNotification } from "Utils/SaveNotification";

const CareerList: React.FunctionComponent<{}> = () => {
  const [addNewIsOpen, toggle] = useToggle(false);
  const [careersList, setCareers] = useState<Array<KshClassesCareerTkshCareer>>(
    []
  );

  const fetchData = async () => {
    const result = await getProfessorCareerListDataAction();
    sortResult(result);
    setCareers(result);
  };

  useMount(() => {
    fetchData();
  });

  return (
    <>
      <Table>
        <tbody>
          {careersList &&
            careersList.map((career: KshClassesCareerTkshCareer, i) => (
              <Career
                career={career}
                key={`career-${i}`}
                reFetch={(newCareerList: Array<KshClassesCareerTkshCareer>) => setCareers(newCareerList)}
                reFetchWithoutNewList={fetchData}
                updateCareer={addNotification(updateProfessorCareerDataAction)}
                deleteCareer={addNotification(deleteProfessortCareerAction)}
                moveUp={async () => {
                  let list = await moveUpProfessorCareerAction(career);
                  notify();
                  list = sortResult(list);
                  setCareers(list);
                }}
                moveDown={async () => {
                  let list = await moveDownProfessorCareerAction(career);
                  notify();
                  list = sortResult(list);
                  setCareers(list);
                }}
              />
            ))}

          {addNewIsOpen && (
            <Career
              career={{}}
              isNew={true}
              updateCareer={createProfessorCareerAction}
              toggleAddNew={toggle}
              reFetch={async (newCareerList: Array<KshClassesCareerTkshCareer>) => {
                await setCareers(newCareerList);
                toggle();
              }}
            />
          )}
        </tbody>
      </Table>

      {!addNewIsOpen && (
        <Button fullsize cta onClick={() => toggle()}>
          neuen Karriereschritt hinzufügen
        </Button>
      )}
    </>
  );
};

export default CareerList;
