import React, {useEffect, useState} from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR, Decorator } from "final-form";
import Page from "05_templates/Page";
import FormRow from "02_atoms/Form/FormRow";
import Button from "02_atoms/Button";
import { PageHeader, PageHeadline, PageDescription } from "02_atoms/PageHeader";
import StyledForm from "04_organisms/Form";
import { passwordResetRequest } from "Connectivity/Api";
import { Link } from "@reach/router";
import { KshClassesUserPasswordresetTkshPasswordReset } from "../Connectivity/Portal";
import DatePicker from "react-datepicker";
import deDE from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";
import FormError from "../02_atoms/FormError";
import createDecorator from "final-form-focus";
import Recaptcha from "../Utils/Recaptcha";

type PasswordResetRequestProps = {
  //   login: Function;
  //   user: User;
  initialResetted?: boolean;
};

interface ResetValues {
  login?: string;
  birthdate?: string;
}
interface Errors {
  login?: string;
  birthdate?: string;
}

const PasswordResetRequest: React.FunctionComponent<
  PasswordResetRequestProps
> = ({ initialResetted = false }) => {
  const [isPasswordResetted, setPasswordResetted] = useState<Boolean>(
    initialResetted
  );

  // @TODO get rid of any

  const focusOnError = createDecorator();

  const validate = (values: ResetValues) => {
    let errors: Errors = {};
    if (!values.login) {
      errors.login = "Erforderlich";
    }
    if (!values.birthdate) {
      errors.birthdate = "Erforderlich";
    }
    return errors;
  };

  const onSubmit = async (
    values: KshClassesUserPasswordresetTkshPasswordReset
  ) => {
    if (!values.login || !values.birthdate) {
      return { [FORM_ERROR]: "KSH Kennung oder Geburtstag nicht angegeben" };
    }

      // @ts-ignore
      const reCaptchaResponse = window.grecaptcha.getResponse();
      if(reCaptchaResponse.length == 0) {
          return { [FORM_ERROR]: "Bitte bestätigen Sie das Captcha" };
      }

    const params = {
      login: values.login,
      birthdate: format(values.birthdate, "yyyy-MM-dd")
    };

    values.birthdate = new Date(values.birthdate);
    // @ts-ignore
    const result = await passwordResetRequest(params);
    // @ts-ignore
    if (result.result === "ok") {
      setPasswordResetted(true);
    }
  };

  if (isPasswordResetted) {
    return (
      <Page navbarInitialShown={false}>
        <PageHeader>
          <PageHeadline>Passwort zurücksetzten</PageHeadline>
          <PageDescription>
            Vielen Dank. Wir haben Ihre Anforderung zum Zurücksetzen des
            Passwortes erhalten. Bei korrekten Daten erhalten Sie in wenigen
            Minuten eine Email mit dem Code zum Zurücksetzen des Passworts.
          </PageDescription>
        </PageHeader>
        <div>
          <Link to="/">Zurück zum Login</Link>
        </div>
      </Page>
    );
  }

  return (
    <Page navbarInitialShown={false}>
      <PageHeader>
        <PageHeadline>Passwort zurücksetzten</PageHeadline>
      </PageHeader>
      <Form
        decorators={[focusOnError as Decorator]}
        validate={validate}
        onSubmit={onSubmit}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values
        }) => (
            <StyledForm onSubmit={handleSubmit}>
              <FormRow>
                <label>Ihre KSH Kennung:</label>
                <Field name="login">
                  {({ input, meta: { error, touched } }) => (
                    <div>
                      <input {...input} type={"text"} />
                      {error && touched && (
                        <span style={{ fontSize: 10 }}>*{error}</span>
                      )}
                    </div>
                  )}
                </Field>
                <em>
                  Hinweis: Sollten Sie Ihre Kennung vergessen haben, wenden Sie
                Sich bitte per E-Mail an{" "}
                  <a href="mailto:support@ksh-m.de">support@ksh-m.de</a>
                </em>
              </FormRow>
              <FormRow>
                <label>Ihr Geburtstag</label>
                <div>
                  <Field name="birthdate">
                    {({ input, meta: { error, touched } }) => (
                      <div>
                        <DatePicker
                          {...input}
                          locale={deDE}
                          name={name}
                          selected={input.value === "" ? null : input.value}
                          onChange={input.onChange as any}
                          showYearDropdown
                          dateFormat="dd.MM.yyyy"
                        />
                        {error && touched && (
                          <>
                            <br />
                            <span style={{ fontSize: 10 }}>*{error}</span>
                          </>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
              </FormRow>
                <Recaptcha />
              {submitError && <FormError>{submitError}</FormError>}
              <Button
                type="submit"
                cta
                fullsize
                disabled={submitting || pristine}
              >
                Passwort zurücksetzen
            </Button>
            </StyledForm>
          )}
      />
      <div>
        <Link to="/">Zurück zum Login</Link>
      </div>
    </Page>
  );
};

export default PasswordResetRequest;
