import React, { useState, useEffect, useContext, useRef } from "react";
import { KshClassesStaffTkshStaff } from "Connectivity/Portal";
import styled from "styled-components";
import { getStaffDataAction, updateStaffDataAction } from "Connectivity/Api";
import {
  getProfessorDataAction,
  updateProfessorDataAction
} from "Connectivity/Api";
import { KshClassesProfessorTkshProfessor } from "Connectivity/Portal";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { hasGroup } from "Utils/Groups";
import { User } from "Types/User";
import { useToggle } from "Utils/Hooks";
import Overlay from "02_atoms/Overlay";
import FormRow from "02_atoms/Form/FormRow";
import Hidden from "02_atoms/Hidden";
import StyledForm from "04_organisms/Form";
import Button from "02_atoms/Button";
import DataWrapper from "02_atoms/DataWrapper";
import CancelButton from "02_atoms/CancelButton";
import FormHeadline from "02_atoms/FormHeadline";

interface Error {
  phonepin?: String;
  phonepin_verify?: String;
  phonepin_equal?: String;
}

type StaffProps = {
  // updatePhone: Function;
  user: User;
};

const Staff: React.FunctionComponent<StaffProps> = ({ user }) => {
  const closePhoneFunction = useRef<Function>(() => { });
  const closePinFunction = useRef<Function>(() => { });
  const closeEmailFunction = useRef<Function>(() => { });
  const closeLabelFunction = useRef<Function>(() => { });
  const closeConsultationFunction = useRef<Function>(() => { });

  const [phonePinIsOpen, phonePinToggle] = useToggle(false);

  const [
    profData,
    setProfData
  ] = useState<KshClassesProfessorTkshProfessor | null>(null);

  const [staffData, setStaffData] = useState<KshClassesStaffTkshStaff | null>(
    null
  );

  useEffect(() => {
    const fetchProfData = async () => {
      const profResult = await getProfessorDataAction();
      setProfData(profResult);
    };

    const fetchStaffData = async () => {
      const result = await getStaffDataAction();
      setStaffData(result);
    };

    fetchProfData();
    fetchStaffData();
  }, []);

  if (!staffData) {
    return null;
  }

  if (!profData) {
    return null;
  }

  const updateProfConsultationAction = async (
    prof: KshClassesProfessorTkshProfessor
  ) => {
    const dataToUpdate = {
      ...profData,
      consultation: prof.consultation
    };
    await updateProfessorDataAction(dataToUpdate);
    setProfData(dataToUpdate);
    closeConsultationFunction.current();
  };

  const updatePhoneInternal = async (staff: KshClassesStaffTkshStaff) => {
    const dataToUpdate = {
      ...staffData,
      phoneinternal: staff.phoneinternal
    };
    await updateStaffDataAction(dataToUpdate);

    setStaffData(dataToUpdate);
    closePhoneFunction.current();
  };

  const updateEmailSignature = async (staff: KshClassesStaffTkshStaff) => {
    const dataToUpdate = {
      ...staffData,
      emailsignature: staff.emailsignature
    };
    await updateStaffDataAction(dataToUpdate);
    setStaffData(dataToUpdate);
    closeEmailFunction.current();
  };

  const updateRoomLabel = async (staff: KshClassesStaffTkshStaff) => {
    const dataToUpdate = {
      ...staffData,
      roomlabel: staff.roomlabel
    };
    await updateStaffDataAction(dataToUpdate);
    setStaffData(dataToUpdate);
    closeLabelFunction.current();
  };

  const updatePinAction = async (staff: KshClassesStaffTkshStaff) => {
    // @ts-ignore
    const newPhonePin = parseInt(staff.phonepin, 10);
    const dataToUpdate = {
      ...staffData,
      phonepin: newPhonePin
    };
    await updateStaffDataAction(dataToUpdate);
    setStaffData(dataToUpdate);
    closePinFunction.current();
  };

  return (
    <>
      <Hidden testValue={staffData.phoneinternal}>
        <DataWrapper>
          <label>Telefonnummer (intern)</label>
          <p>{staffData.phoneinternal}</p>
        </DataWrapper>
      </Hidden>

      {/* Phone number should not be editable */}
      {/* <Overlay editLabel="Angaben ändern" closeFunction={closePhoneFunction}>
        <Form
          initialValues={staffData}
          onSubmit={updatePhoneInternal}
          render={({
            submitError,
            handleSubmit,
            form,
            submitting,
            pristine,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              {submitError && <div className="error">{submitError}</div>}

              <p>Ihre angegebene Telefonnummer (intern)</p>
              <p>{staffData.phoneinternal}</p>
              <p>Bitte geben Sie hier Ihre neue Telefonnummer (intern) an:</p>
              <FormRow>
                <label>Telefonnummer (intern)</label>
                <Field
                  name="phoneinternal"
                  component="input"
                  type="text"
                  placeholder="Telefonnummer (intern)"
                />
              </FormRow>
              <div>
                <button type="submit" disabled={submitting || pristine}>
                  Übernehmen
                </button>
              </div>
            </form>
          )}
        />
      </Overlay> */}

      {/* <Mobile updateMobile={this.props.}/> */}

      <Hidden testValue={staffData.phonepin}>
        <DataWrapper>
          <label>neuer Telefon-Pin:</label>
          <p>{phonePinIsOpen ? staffData.phonepin : "••••"}</p>
        </DataWrapper>

        <DataWrapper>
          <Button onClick={() => phonePinToggle()}>
            {phonePinIsOpen ? "PIN ausblenden" : "PIN anzeigen"}
          </Button>
        </DataWrapper>

        <Overlay editLabel="PIN ändern" closeFunction={closePinFunction}>
          <Form
            onSubmit={updatePinAction}
            validate={(values: KshClassesStaffTkshStaff | any) => {
              const errors: Error = {};
              if (!values.phonepin) {
                errors.phonepin = "Erforderlich";
              } else if (isNaN(values.phonepin)) {
                errors.phonepin = "Nur Zahlen zulässig";
              }
              if (!values.phonepin_verify) {
                errors.phonepin_verify = "Erforderlich";
              } else if (isNaN(values.phonepin_verify)) {
                errors.phonepin_verify = "Nur Zahlen zulässig";
              }

              if (values.phonepin !== values.phonepin_verify) {
                errors.phonepin_equal = "Die Pins stimmen nicht überein";
              }
              return errors;
            }}
            render={({
              submitError,
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              errors
            }) => (
                <StyledForm onSubmit={handleSubmit}>
                  {submitError && <div className="error">{submitError}</div>}
                  <FormRow>
                    <label>Telefon-Pin</label>
                    <Field
                      name="phonepin"
                      component="input"
                      type="password"
                      placeholder="Geben Sie hier Ihre neue Telefon-PIN ein"
                    />
                    {errors.phonepin && <span>{errors.phonepin}</span>}
                  </FormRow>
                  <FormRow>
                    <label>Telefon-Pin wiederholen</label>
                    <Field
                      name="phonepin_verify"
                      component="input"
                      type="password"
                      placeholder="Wiederholen Sie hier Ihre Telefon-PIN"
                    />
                    {errors.phonepin_verify && (
                      <span>{errors.phonepin_verify}</span>
                    )}
                  </FormRow>
                  {errors.phonepin_equal && <span>{errors.phonepin_equal}</span>}
                  <FormRow>
                    <Button type="submit" disabled={submitting || pristine}>
                      Übernehmen
                  </Button>
                    <CancelButton
                      closeFunction={() => closePinFunction.current()}
                    />
                  </FormRow>
                </StyledForm>
              )}
          />
        </Overlay>
      </Hidden>

      <Hidden testValue={staffData.swcardid}>
        <DataWrapper>
          <label>Nummer Mitarbeiterausweis</label>
          <p>{staffData.swcardid}</p>
        </DataWrapper>
      </Hidden>

      <Hidden testValue={staffData.functioninternal}>
        <DataWrapper>
          <label>Funktion</label>
          <p>{staffData.functioninternal}</p>
        </DataWrapper>
      </Hidden>

      <Hidden testValue={staffData.kshemail}>
        <DataWrapper>
          <label>KSH-E-Mail-Adresse</label>
          <p>{staffData.kshemail}</p>
        </DataWrapper>
      </Hidden>

      <Hidden testValue={staffData.emailsignature}>
        <DataWrapper>
          <label>E-Mail-Signatur</label>
          <p>{staffData.emailsignature}</p>
        </DataWrapper>

        <Overlay
          editLabel="E-Mail Signatur bearbeiten"
          closeFunction={closeEmailFunction}
        >
          <Form
            initialValues={staffData}
            onSubmit={updateEmailSignature}
            render={({
              submitError,
              handleSubmit,
              form,
              submitting,
              pristine,
              values
            }) => (
                <StyledForm onSubmit={handleSubmit}>
                  {submitError && <div className="error">{submitError}</div>}
                  <FormRow>
                    <label>E-Mail-Signatur</label>
                    <Field
                      name="emailsignature"
                      component="textarea"
                      placeholder="E-Mail-Signatur"
                    />
                  </FormRow>
                  <FormRow>
                    <Button type="submit" disabled={submitting || pristine}>
                      Übernehmen
                  </Button>
                    <CancelButton
                      closeFunction={() => closeEmailFunction.current()}
                    />
                  </FormRow>
                </StyledForm>
              )}
          />
        </Overlay>
      </Hidden>
      <Hidden testValue={staffData.lrzid}>
        <DataWrapper>
          <label>LRZ-ID</label>
          <p>
            {staffData.lrzid}{" "}
            <a href="https://idportal.lrz.de" target="_blank">
              [idportal.lrz.de]
            </a>
          </p>
        </DataWrapper>
      </Hidden>

      <Hidden testValue={staffData.roomnr}>
        <DataWrapper>
          <label>Raum</label>
          <p>Raum-Nummer: {staffData.roomnr}</p>
        </DataWrapper>
      </Hidden>

      <Hidden testValue={staffData.roomlabel}>
        <DataWrapper>
          <label>Türschild</label>
          <p>{staffData.roomlabel}</p>
        </DataWrapper>

        <Overlay
          editLabel="Türschild-Text bearbeiten"
          closeFunction={closeLabelFunction}
        >
          <Form
            initialValues={staffData}
            onSubmit={updateRoomLabel}
            render={({
              submitError,
              handleSubmit,
              form,
              submitting,
              pristine,
              values
            }) => (
                <StyledForm onSubmit={handleSubmit}>
                  {submitError && <div className="error">{submitError}</div>}

                  <FormRow>
                    <label>Türschild</label>
                    <Field
                      name="roomlabel"
                      component="textarea"
                      placeholder="Zusatzinformation zu Ihrem Schild"
                    />
                  </FormRow>
                  <FormRow>
                    <Button type="submit" disabled={submitting || pristine}>
                      Übernehmen
                  </Button>
                    <CancelButton
                      closeFunction={() => closeLabelFunction.current()}
                    />
                  </FormRow>
                </StyledForm>
              )}
          />
        </Overlay>
      </Hidden>

      {hasGroup(user, "professors") && (
        <>
          <Hidden testValue={profData.consultation}>
            <DataWrapper>
              <label>Sprechzeiten</label>
              <p>{profData.consultation}</p>
            </DataWrapper>

            <Overlay
              editLabel="Sprechzeiten bearbeiten"
              closeFunction={closeConsultationFunction}
            >
              <Form
                initialValues={profData}
                onSubmit={updateProfConsultationAction}
                render={({
                  submitError,
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values
                }) => (
                    <StyledForm onSubmit={handleSubmit}>
                      {submitError && <div className="error">{submitError}</div>}
                      <FormRow>
                        <label>Sprechstunde</label>
                        <Field
                          name="consultation"
                          component="textarea"
                          // type="textarea"
                          placeholder="Ihre Sprechstunde"
                          maxLength={140}
                        />
                      </FormRow>
                      <FormRow>
                        <Button type="submit" disabled={submitting || pristine}>
                          Übernehmen
                      </Button>
                        <CancelButton
                          closeFunction={() =>
                            closeConsultationFunction.current()
                          }
                        />
                      </FormRow>
                    </StyledForm>
                  )}
              />
            </Overlay>
          </Hidden>

          <Hidden testValue={profData.faculty}>
            <DataWrapper>
              <label>Fakultät</label>
              <p>{profData.faculty}</p>
            </DataWrapper>
          </Hidden>

          <Hidden testValue={profData.professorfor}>
            <DataWrapper>
              <label>Berufung für</label>
              <p>{profData.professorfor}</p>
            </DataWrapper>
          </Hidden>
        </>
      )}
    </>
  );

  //   return {
  /* {staffData && (
        <Form
          initialValues={staffData}
          onSubmit={onSaveChange}
          render={({
            submitError,
            handleSubmit,
            form,
            submitting,
            pristine,
            values
          }) => {
            return (
              <StyledForm onSubmit={handleSubmit}>
                {submitError && <div className="error">{submitError}</div>}
                <div>
                  <label>Sprechstunde</label>
                  <Field
                    name="consultation"
                    component="textarea"
                    placeholder="Sprechstunde"
                  />
                </div>
                <div>
                  <label>Fakultät</label>
                  <Field
                    name="faculty"
                    component="textarea"
                    placeholder="Fakultät"
                  />
                </div>
                <div>
                  <label>Professor für</label>
                  <Field
                    name="professorfor"
                    component="textarea"
                    placeholder="Professor für"
                  />
                </div>
                <div>
                  <button type="submit" disabled={submitting || pristine}>
                    Übernehmen
                  </button>
                </div>
              </StyledForm>
            );
          }}
        />
      )} */
  //   };
};

export default Staff;
