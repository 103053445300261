import styled, { css } from "styled-components";
import React from "react";
import {
  KshClassesCareerTkshCareer,
  KshClassesNewsTkshNews
} from "Connectivity/Portal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const StyledActiveIndicator = styled("td") <{
  active?: boolean;
}>`
  ${props =>
    !props.active &&
    css`
      path {
        fill: lightgrey;
      }
    `}

  width: 4%;
  cursor: pointer;
`;

type ActiveIndicatorProps = {
  active?: boolean;
  updateFunction: Function;
  objectToUpdate: KshClassesCareerTkshCareer | KshClassesNewsTkshNews;
  reFetch: Function;
};

const ActiveIndicator: React.FunctionComponent<ActiveIndicatorProps> = ({
  active,
  objectToUpdate,
  reFetch,
  updateFunction
}) => {
  const onClickChange = async () => {
    if (!objectToUpdate) {
      return;
    }

    const invertActive = !objectToUpdate.active;
    objectToUpdate.active = invertActive ? 1 : 0;

    await updateFunction(objectToUpdate);
    await reFetch();
  };

  return (
    <StyledActiveIndicator
      active={active}
      title={active ? "Auf Webseite anzeigen" : "Auf Webseite ausgeblendet"}
      onClick={onClickChange}
    >
      <FontAwesomeIcon icon={faGlobeEurope as IconProp} />
    </StyledActiveIndicator>
  );
};

export default ActiveIndicator;
