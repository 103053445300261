import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Note from "02_atoms/Note";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import FormError from "02_atoms/FormError";
import "@reach/dialog/styles.css";
import FormRow from "02_atoms/Form/FormRow";
import StyledForm from "04_organisms/Form";
import Button from "02_atoms/Button";
import PasswordValidation, {
  passwordRules
} from "03_molecules/PasswordValidation";
import Recaptcha from "../../Utils/Recaptcha";
import { navigate } from "@reach/router";

type PasswordProps = {
  updatePassword: Function;
};

const BoldWrapper = styled("p")`
  font-weight: bold;
`;

const Password: React.FunctionComponent<PasswordProps> = ({
  updatePassword
}) => {
  const [enteredPassword, setEnteredPassword] = useState<string | null>(null);
  const [showChangeSuccess, setShowChangeSuccess] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChangeSuccess(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, [showChangeSuccess]);

  const onSaveChange = async ({ future, futureVerify }: any) => {
    const validationErrors = passwordRules.filter(
      rule => !rule.validate(future)
    );

    if (validationErrors.length > 0) {
      return {
        [FORM_ERROR]: "Das Passwort entspricht nicht unseren Richtlinien"
      };
    }

    if (futureVerify !== future) {
      return {
        [FORM_ERROR]: "Passwörter stimmen nicht überein"
      };
    }

    // @ts-ignore
    const reCaptchaResponse = window.grecaptcha.getResponse();
    if(reCaptchaResponse.length == 0) {
        return { [FORM_ERROR]: "Bitte bestätigen Sie das Captcha" };
    }

    const result = await updatePassword(future);
    if (!result) {
      return [FORM_ERROR];
    }
    const timer = setTimeout(() => {
      navigate(`/data`);
    }, 10000);
    setShowChangeSuccess(true);

    return () => clearTimeout(timer);
  };

  if (showChangeSuccess) {
    return (
      <Note>
        <BoldWrapper>Passwort geändert</BoldWrapper>
        <p>
          Vielen Dank. Sie haben ein neues Passwort vergeben. Sie können sich in
          wenigen Momenten mit diesem einloggen.
        </p>
      </Note>
    );
  }

  return (
    <>
      <PasswordValidation password={enteredPassword} />
      <Form
        onSubmit={onSaveChange}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          reset
        }) => (
          <StyledForm
            onSubmit={handleSubmit}>
            <div>
              <Field
                name="future"
                validate={value => {
                  if (value !== undefined) {
                    setEnteredPassword(value);
                  }
                }}
              >
                {({ input, meta }) => (
                  <FormRow>
                    <label>neues Passwort</label>
                    <input
                      type="password"
                      {...input}
                      placeholder="neues Passwort"
                    />
                    {meta.touched && meta.error && (
                      <FormError>{meta.error}</FormError>
                    )}
                  </FormRow>
                )}
              </Field>
            </div>
            <div>
              <Field name="futureVerify">
                {({ input, meta }) => (
                  <FormRow>
                    <label>neues Passwort wiederholen</label>
                    <input
                      type="password"
                      {...input}
                      placeholder="neues passwort wiederholen"
                    />
                    {meta.touched && meta.error && (
                      <FormError>{meta.error}</FormError>
                    )}
                  </FormRow>
                )}
              </Field>
            </div>
            <Recaptcha />
            <div>
              {submitError && <FormError>{submitError}</FormError>}
              <Button type="submit" disabled={submitting || pristine}>
                Übernehmen
              </Button>
            </div>
          </StyledForm>
        )}
      />
    </>
  );
};
export default Password;
