import React, { useState, useEffect, useContext } from "react";
import { KshClassesProfessorTkshProfessor } from "Connectivity/Portal";
import {
  getProfessorDataAction,
  updateProfessorDataAction
} from "Connectivity/Api";
import Page from "05_templates/Page";
import { PageHeader, PageHeadline, PageDescription } from "02_atoms/PageHeader";
import { navigate } from "@reach/router";
import CareerList from "04_organisms/Prof/CareerList";
import FocusList from "04_organisms/Prof/FocusList";
import FunctionExternalList from "04_organisms/Prof/FunctionExternalList";
import NewsList from "04_organisms/Prof/NewsList";
import ResearchList from "04_organisms/Prof/ResearchList";
import FunctionKshList from "04_organisms/Prof/FunctionKshList";
import LectureList from "04_organisms/Prof/LectureList";
import FurtherEducationList from "04_organisms/Prof/FurtherEducationList";
import PublicationList from "04_organisms/Prof/PublicationList";
import Openable from "03_molecules/Openable";

type ProfProps = {
  section?: String;
};

const Prof: React.FunctionComponent<ProfProps> = ({ section = "" }) => {
  return (
    <Page>
      <PageHeader>
        <PageHeadline>Ihre Lehrprofildaten</PageHeadline>
        <PageDescription>
          Hier können Sie Ihre Lehrprofildaten pflegen. Hierüber können Sie auch
          Ihre Angaben auf der KSH-Website steuern
        </PageDescription>
      </PageHeader>

      <Openable
        label="Beruflicher und wissenschaftlicher Werdegang"
        open={section === "career"}
        toggleOpen={() => navigate(`/prof/career`)}
        toggleClose={() => navigate(`/prof`)}
      >
        <CareerList />
      </Openable>

      <Openable
        label="Arbeits- und Forschungsschwerpunkte"
        open={section === "focus"}
        toggleOpen={() => navigate(`/prof/focus`)}        
        toggleClose={() => navigate(`/prof`)}
      >
        <FocusList />
      </Openable>

      <Openable
        label="Funktionen an der KSH"
        open={section === "function"}
        toggleOpen={() => navigate(`/prof/function`)}
        toggleClose={() => navigate(`/prof`)}
      >
        <FunctionKshList />
      </Openable>

      <Openable
        label="Weitere Funktionen und Mitgliedschaften"
        open={section === "functionExternal"}
        toggleOpen={() => navigate(`/prof/functionExternal`)}
        toggleClose={() => navigate(`/prof`)}
      >
        <FunctionExternalList />
      </Openable>

      <Openable
        label="News"
        open={section === "news"}
        toggleOpen={() => navigate(`/prof/news`)}
        toggleClose={() => navigate(`/prof`)}
      >
        <NewsList />
      </Openable>

      <Openable
        label="Forschung"
        open={section === "research"}
        toggleOpen={() => navigate(`/prof/research`)}
        toggleClose={() => navigate(`/prof`)}
      >
        <ResearchList />
      </Openable>

      <Openable
        label="Vorträge"
        open={section === "lecture"}
        toggleOpen={() => navigate(`/prof/lecture`)}
        toggleClose={() => navigate(`/prof`)}
      >
        <LectureList />
      </Openable>

      <Openable
        label="Weiterbildung"
        open={section === "furtherEducation"}
        toggleOpen={() => navigate(`/prof/furtherEducation`)}
        toggleClose={() => navigate(`/prof`)}
      >
        <FurtherEducationList />
      </Openable>

      <Openable
        label="Publikationen"
        open={section === "publications"}
        toggleOpen={() => navigate(`/prof/publications`)}
        toggleClose={() => navigate(`/prof`)}
      >
        <PublicationList />
      </Openable>
    </Page>
  );
};

export default Prof;
