import { KshClassesCareerTkshCareer } from "Connectivity/Portal";
import React, { useRef } from "react";
import FormRow from "02_atoms/Form/FormRow";
import FormHeadline from "02_atoms/FormHeadline";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import StyledForm from "04_organisms/Form";
import styled, { css } from "styled-components";
import Button from "02_atoms/Button";
import Overlay from "02_atoms/Overlay";
import { async } from "q";
import { render } from "react-dom";
import { textAlign } from "styled-system";
import ActiveIndicator from "02_atoms/Form/ActiveIndicator";
import TitleWrapper from "02_atoms/Form/TitleWrapper";
import FormActionContainer from "02_atoms/Form/FormActionContainer";
import CheckboxRow from "02_atoms/Form/CheckboxRow";
import MoveActionButton from "02_atoms/Form/MoveActionButton";
import PlaceholderTd from "02_atoms/Placeholder/PlaceholderTd";
import CancelButton from "02_atoms/CancelButton";
import Confirm from "02_atoms/Confirm";

type CareerProps = {
  reFetch: Function;
  reFetchWithoutNewList?: Function;
  updateCareer: Function;
  deleteCareer?: Function;
  moveUp?: Function;
  moveDown?: Function;
  career: KshClassesCareerTkshCareer;
  isNew?: boolean;
  toggleAddNew?: Function;
};

const FurtherEducation: React.FunctionComponent<CareerProps> = ({
  moveDown,
  moveUp,
  reFetch,
  reFetchWithoutNewList,
  deleteCareer,
  updateCareer,
  career,
  isNew = false,
  toggleAddNew
}) => {
  const closeCareerFunction = useRef<Function>(() => { });

  const prepareDeleteCareerFunction = async (
    career: KshClassesCareerTkshCareer
  ) => {
    if (!deleteCareer) {
      return;
    }
    const furtherEducation = await deleteCareer(career);
    closeCareerFunction.current();
    reFetch(furtherEducation);

    return;
  };

  // @TODO get rid of any
  const onSaveChange = async (values: any) => {
    values.active = values.active ? 1 : 0;
    const furtherEducation = await updateCareer(values);
    if (!furtherEducation) {
      return [FORM_ERROR];
    }
    reFetch(furtherEducation);
    closeCareerFunction.current();
  };

  return (
    <tr>
      <TitleWrapper caption={career.caption} />
      <PlaceholderTd tdWidth={"5%"} />
      <PlaceholderTd tdWidth={"5%"} />

      <ActiveIndicator
        active={!!career.active}
        updateFunction={updateCareer}
        objectToUpdate={career}
        reFetch={reFetchWithoutNewList!}
      />
      <PlaceholderTd tdWidth={"10%"}>
        <Overlay
          initialOpen={isNew}
          editLabel="Titel der Weiterbildung"
          buttonLabel="bearbeiten"
          closeFunction={closeCareerFunction}
        >
          <Form
            initialValues={career}
            onSubmit={onSaveChange}
            render={({
              submitError,
              handleSubmit,
              form,
              submitting,
              pristine,
              values
            }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <FormActionContainer>
                    <div>
                      {submitError && <div className="error">{submitError}</div>}
                      <FormRow>
                        <label>Titel der Weiterbildung</label>
                        <Field
                          name="caption"
                          component="input"
                          type="text"
                          placeholder="Titel der Weiterbildung"
                        />
                      </FormRow>
                      <CheckboxRow>
                        <label>Auf der Webseite veröffentlichen?</label>
                        <Field name="active" component="input" type="checkbox" />
                      </CheckboxRow>
                      <FormRow>
                        <Button type="submit" disabled={submitting || pristine}>
                          Übernehmen
                      </Button>
                        <CancelButton
                          isNew={isNew}
                          closeFunction={() => closeCareerFunction.current()}
                          toggleAddNew={toggleAddNew}
                        />
                      </FormRow>
                      <FormRow>
                        {deleteCareer &&
                          <Confirm confirmFunction={() => prepareDeleteCareerFunction(career)} />
                        }
                      </FormRow>
                    </div>
                  </FormActionContainer>
                </StyledForm>
              )}
          />
        </Overlay>
      </PlaceholderTd>
      <MoveActionButton
        moveUp={moveUp}
        moveDown={moveDown}
      />
    </tr>
  );
};

export default FurtherEducation;
