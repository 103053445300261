import React, { useContext, useEffect, useState } from "react";
import { Link } from "@reach/router";
import StyledLink from "02_atoms/Link";
import Header from "04_organisms/Header";
import { Footer, FooterWrapper } from "04_organisms/Footer";
import styled from "styled-components";
import { LoginContext } from "LoginContext";
import Navbar from "03_molecules/Navbar";
import { hasGroup } from "Utils/Groups";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Wrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: auto;
  padding: 1rem;
`;

type PageType = {
  children: React.ReactNode;
  navbarInitialShown?: boolean;
};

const Page: React.FunctionComponent<PageType> = props => {
  const { children, navbarInitialShown = true } = props;

  const [isNavbarShown, setNavbarShown] = useState<Boolean>(navbarInitialShown);

  const context = useContext(LoginContext);
  const loggedIn = context.loggedIn;
  const user = context.user;

  return (
    <>
      <Wrapper>
        <Header>
          <h1>Katholische Stiftungshochschule München</h1>
          {loggedIn && <Link to={"/data"}>zurück</Link>}
        </Header>
        {!!isNavbarShown && (
          <Navbar>
            <Link to={"/data"}>Nutzerdaten</Link>
            {hasGroup(user, "professors") && (
              <Link to={"/prof"}>Lehrprofildaten</Link>
            )}
          </Navbar>
        )}

        <div>{children}</div>
        <div>
          <a href="#top">Nach oben</a>
        </div>
      </Wrapper>
      <FooterWrapper>
        <Footer>
          <p>
            <a href="https://www.ksh-muenchen.de/" target="_blanke">
              Impressum
            </a>
          </p>
          <p>
            <a href="mailto:support@ksh-m.de">support@ksh-m.de</a>
          </p>
        </Footer>
      </FooterWrapper>
      <ToastContainer />
    </>
  );
};

export default Page;
