import React, { useState, useEffect, useCallback } from "react";
import { useToggle } from "Utils/Hooks";
import { KshClassesPublicationTkshPublication } from "Connectivity/Portal";
import Button from "02_atoms/Button";
import { sortResult } from "Utils/CareerSort";

import {
  getServicePublicationAction,
  updateServicePublicationAction,
  moveUpServicePublicationAction,
  moveDownServicePublicationAction,
  deleteServicePublicationAction,
  createServicePublicationAction,
  updateServicePublicationFileAction,
  deleteServicePublicationFileAction
} from "Connectivity/Api";

import Publication from "./Publication";
import Table from "02_atoms/Form/Table";
import notify, { addNotification } from "Utils/SaveNotification";

const PublicationList: React.FunctionComponent<{}> = () => {
  const [addNewIsOpen, toggle] = useToggle(false);
  const [publicationList, setPublication] = useState<
    Array<KshClassesPublicationTkshPublication>
  >([]);

  const fetchData = useCallback(async () => {
    const result = await getServicePublicationAction();
    sortResult(result);
    setPublication(result);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Table>
        <tbody>
          {publicationList &&
            publicationList.map(
              (publication: KshClassesPublicationTkshPublication, i) => (
                <Publication
                  publication={publication}
                  key={`publication-${i}`}
                  reFetch={(newPublicationList: Array<KshClassesPublicationTkshPublication>) => setPublication(newPublicationList)}
                  reFetchWithoutNewList={fetchData}
                  updatePublication={addNotification(updateServicePublicationAction)}
                  deletePublication={addNotification(deleteServicePublicationAction)}
                  deleteFile={deleteServicePublicationFileAction}
                  updateFile={updateServicePublicationFileAction}
                  moveUp={async () => {
                    let list = await moveUpServicePublicationAction(
                      publication
                    );
                    notify();
                    list = sortResult(list);
                    setPublication(list);
                  }}
                  moveDown={async () => {
                    let list = await moveDownServicePublicationAction(
                      publication
                    );
                    notify();
                    list = sortResult(list);
                    setPublication(list);
                  }}
                >
                  {publication.caption}
                </Publication>
              )
            )}

          {addNewIsOpen && (
            <Publication
              publication={{}}
              isNew={true}
              updatePublication={createServicePublicationAction}
              toggleAddNew={toggle}
              reFetch={async (newPublicationList: Array<KshClassesPublicationTkshPublication>) => {
                await setPublication(newPublicationList);
                toggle();
              }}
            />
          )}
        </tbody>
      </Table>

      {!addNewIsOpen && (
        <Button fullsize cta onClick={() => toggle()}>
          neue Publikation hinzufügen
        </Button>
      )}
    </>
  );
};

export default PublicationList;
