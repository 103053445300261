import styled from "styled-components";
import { Theme } from "01_base/Base";

type NoteProps = {
  theme: Theme;
};

const Note = styled("div")<NoteProps>`
  border: 2px solid ${props => props.theme.colors.orange};
  padding: 2rem;
  margin: 1rem 0;
`;

export default Note;
