import React from "react";
import styled from "styled-components";
import { async } from "q";

const CancelButtonWrapper = styled("a")`
  margin-left: 10px;
  :hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

type CancelButtonType = {
  isNew?: Boolean;
  closeFunction: Function;
  toggleAddNew?: Function;
};

const CancelButton: React.FunctionComponent<CancelButtonType> = ({
  isNew = false,
  closeFunction,
  toggleAddNew
}) => {
  return (
    <CancelButtonWrapper
      className="button"
      onClick={async () => {
        if (!isNew) {
          closeFunction();
        }
        if (isNew && toggleAddNew) {
          toggleAddNew();
        }
      }}
    >
      Abbrechen
    </CancelButtonWrapper>
  );
};

export default CancelButton;
