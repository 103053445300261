import styled from "styled-components";

export default styled.h2`
  font-size: 25px;
  margin: 1rem 0 2rem 0;
  line-height: 35px;
  color: white;
  font-weight: bold;
  padding: 15px 20px;
  background-color: ${props => props.theme.colors.orange};
`;
