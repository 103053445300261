import React from "react";
import styled from "styled-components";
import { useToggle } from "Utils/Hooks";
import Button from "02_atoms/Button";
import { Theme } from "01_base/Base";
// @ts-ignore
import Burger from "@animated-burgers/burger-slip";
import "@animated-burgers/burger-slip/dist/styles.css";

type MenuProps = {
  visible?: Boolean;
  theme: Theme;
};

const BurgerStyles = styled.div`
  .burger .burger-lines,
  .burger .burger-lines:after,
  .burger .burger-lines:before {
    background-color: ${props => props.theme.colors.orange};
  }

  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }
`;

const MenuWrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

const Menu = styled("div")<MenuProps>`
  display: flex;
  a {
    padding-right: 8px;
  }

  width: calc(100% - 30px);

  button {
    display: none;
    width: 30px;
    height: 20px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    a {
      display: ${props => (props.visible ? "block" : "none")};
      flex-direction: column;
      font-size: 1.2em;
      text-align: right;
    }

    button {
      display: block;
    }
  }
`;

const Navbar: React.FunctionComponent<{
  children: JSX.Element[] | JSX.Element;
}> = ({ children }) => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <>
      <BurgerStyles>
        <Burger isOpen={isOpen} onClick={toggle} />
      </BurgerStyles>
      <MenuWrapper>
        <Menu visible={isOpen}>{children}</Menu>
      </MenuWrapper>
    </>
  );
};

export default Navbar;
