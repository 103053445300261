import React, { useState, useRef } from "react";
import "Types/reach__dialog.d.ts";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import DataWrapper from "02_atoms/DataWrapper";
import Overlay from "02_atoms/Overlay";
import Button from "02_atoms/Button";
import StyledForm from "04_organisms/Form";
import CancelButton from "02_atoms/CancelButton";

type EmailProps = {
  updateEmail: Function;
  email: string | undefined;
};

const Email: React.FunctionComponent<EmailProps> = ({ updateEmail, email }) => {
  const closeFunction = useRef<Function>(() => {});
  // @TODO get rid of any
  const onSaveChange = async (values: any) => {
    const result = await updateEmail(values.email);
    if (!result) {
      return [FORM_ERROR];
    }
    closeFunction.current();
  };

  return (
    <>
      <DataWrapper>
        <label>Private E-Mail-Adresse *</label>
        {/* <p>Lorem ipsum Erklärung der Verwendung</p> */}
        <p>{email}</p>
      </DataWrapper>
      <Overlay
        editLabel="E-Mail Adresse bearbeiten"
        closeFunction={closeFunction}
      >
        <Form
          onSubmit={onSaveChange}
          render={({
            submitError,
            handleSubmit,
            form,
            submitting,
            pristine,
            values
          }) => (
            <StyledForm onSubmit={handleSubmit}>
              {submitError && <div className="error">{submitError}</div>}
              <DataWrapper>
                <label>E-Mail</label>
                <Field
                  name="email"
                  component="input"
                  type="text"
                  placeholder="E-Mail"
                  value={email}
                />
              </DataWrapper>
              <DataWrapper>
                <Button type="submit" disabled={submitting || pristine}>
                  Übernehmen
                </Button>
                <CancelButton closeFunction={() => closeFunction.current()} />
              </DataWrapper>
            </StyledForm>
          )}
        />
      </Overlay>
    </>
  );
};

export default Email;
