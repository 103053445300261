import React, {useEffect} from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR, Decorator } from "final-form";
import Page from "05_templates/Page";
import FormRow from "02_atoms/Form/FormRow";
import Button from "02_atoms/Button";
import { PageHeader, PageHeadline, PageDescription } from "02_atoms/PageHeader";
import StyledForm from "04_organisms/Form";
import { Link } from "@reach/router";
import FormError from "../02_atoms/FormError";
import createDecorator from "final-form-focus";
import Recaptcha from "../Utils/Recaptcha";

type LoginProps = {
  login: Function;
};

interface LoginValues {
  login?: string;
  password?: string;
}

interface Errors {
  login?: string;
  password?: string;
}

const Login: React.FunctionComponent<LoginProps> = ({ login }) => {
    const focusOnError = createDecorator();
    const validate = (values: LoginValues) => {
    let errors: Errors = {};
    if (!values.login) {
      errors.login = "Erforderlich";
    }
    if (!values.password) {
      errors.password = "Erforderlich";
    }
    return errors;
    };

    const onSubmit = async (values: LoginValues) => {
        // @ts-ignore
        const reCaptchaResponse = window.grecaptcha.getResponse();
        if (reCaptchaResponse.length == 0) {
            return { [FORM_ERROR]: "Bitte bestätigen Sie das Captcha" };
        }

        if (!values.login || !values.password) {
        return { [FORM_ERROR]: "KSH Kennung oder Passwort fehlt" };
        }

        const status = await login(values.login, values.password);
        if (status !== true) {
        return { [FORM_ERROR]: "KSH Kennung oder Passwort falsch" };
        }
    };

    return (
    <Page navbarInitialShown={false}>
      <PageHeader>
        <PageHeadline>Identity Management Portal</PageHeadline>
      </PageHeader>
      <Form
        decorators={[focusOnError as Decorator]}
        onSubmit={onSubmit}
        validate={validate!}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values
        }) => (
          <StyledForm onSubmit={handleSubmit}>
            <FormRow>
              <label>Ihre KSH Kennung:</label>
              <Field name="login">
                {({ input, meta: { error, touched } }) => (
                  <div>
                    <input {...input} type={"text"} />
                    {error && touched && (
                      <span style={{ fontSize: 10 }}>*{error}</span>
                    )}
                  </div>
                )}
              </Field>
              <em>
                Hinweis: Sollten Sie Ihre Kennung vergessen haben, wenden Sie
                Sich bitte per E-Mail an{" "}
                <a href="mailto:support@ksh-m.de">support@ksh-m.de</a>
              </em>
            </FormRow>
            <FormRow>
              <label>Ihr Passwort</label>
              <Field name="password">
                {({ input, meta: { error, touched } }) => (
                  <div>
                    <input {...input} type={"password"} />
                    {error && touched && (
                      <span style={{ fontSize: 10 }}>*{error}</span>
                    )}
                  </div>
                )}
              </Field>
            </FormRow>
            <Recaptcha />
            {submitError && <FormError>{submitError}</FormError>}

            <Button
              type="submit"
              cta
              fullsize
              disabled={submitting || pristine}
            >
              Anmelden
            </Button>
          </StyledForm>
        )}
      />
      <div>
        <Link to="/resetrequest">Passwort zurücksetzen</Link>
      </div>
    </Page>
  );
};

export default Login;
