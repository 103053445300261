import React from "react";
import { useToggle } from "Utils/Hooks";
import styled from "styled-components";
import { Dialog } from "@reach/dialog";
import { theme } from "../01_base/Base";

const DeleteButtonWrapper = styled("a")``;

const H2 = styled.h2`
  font-size: 22px;
  display: flex;
  place-content: center;
  color: ${theme.colors.darkgrey};
`;

const ConfirmDeleteButtonWrapper = styled("a")`
  border: 1px solid ${theme.colors.orange};
  margin: 1rem;
  font-weight: normal;
  padding: 5px 40px!important;
  color: ${props => props.theme.colors.orange};
  cursor: pointer;
  :hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

type DeleteButtonType = {
  children?: any;
  confirmFunction: Function;
  abortFunction?: Function;
  message?: string;
  buttonText?: string;
};

const CancelButton: React.FunctionComponent<DeleteButtonType> = ({
  children,
  confirmFunction,
  abortFunction,
  message = "Möchten Sie den Eintrag wirklich löschen?",
  buttonText = "Löschen"
}) => {
  const [showConfirm, toggle] = useToggle(false);

  return (
    <>
      <DeleteButtonWrapper
        className="button"
        onClick={() => { toggle(); }}
      >
        {buttonText}
      </DeleteButtonWrapper>
      {showConfirm &&
        <Dialog isOpen={showConfirm} onDismiss={() => toggle()}>
          {children ? children : <H2>{message}</H2>}
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <ConfirmDeleteButtonWrapper className="button" onClick={() => { confirmFunction(); toggle(); }}>
              Ja!
            </ConfirmDeleteButtonWrapper>
            <ConfirmDeleteButtonWrapper className="button" onClick={() => abortFunction ? abortFunction() : toggle()}>
              Nein!
            </ConfirmDeleteButtonWrapper>
          </div>
        </Dialog>
      }
    </>
  );
};

export default CancelButton;
