import React from "react";
import styled from "styled-components";

const StyledTd = styled("td")`
  text-align: left;
  width: 70%;
`;

type TitleWrapperProps = {
  caption?: string;
};

const TitleWrapper: React.FunctionComponent<TitleWrapperProps> = props => {
  let { caption } = props;

  if (caption && caption.length > 60) {
    caption = caption.slice(0, 60) + "...";
  }

  return <StyledTd>{caption}</StyledTd>;
};

export default TitleWrapper;
