import React from "react";
import Page from "05_templates/Page";

import {
  PageHeader,
  PageHeadline,
  PageDescription
} from "../02_atoms/PageHeader";

const availableNewsletters = [
  "Angewandte Sozial- und Bildungswissenschaften (MA kon.)",
  "Angewandte Versorgungsforschung (MA kon.)",
  "Bildung und Erziehung im Kindesalter (B.A.)",
  "Kindheitspädagogik (B.A.)",
  "Pflege Dual (ausbildungsintegrierend, B.Sc.)",
  "Pflegemanagement (B.A.)",
  "Pflegemanagement (Diplom)",
  "Pflegepädagogik (B.A.)",
  "Pflegewissenschaft - Innovative Versorgungskonzepte (MA kon.)",
  "Religionspädagogik und kirchliche Bildungsarbeit (B.A.)",
  "Soziale Arbeit (B.A.) BB",
  "Soziale Arbeit (B.A.) MUC",
  "Soziale Arbeit (Diplom) BB",
  "Soziale Arbeit (Diplom) MUC",
  "Soziale Arbeit (M.A.)",
  "Suchthilfe (M.Sc.)"
];

const Newsletter: React.FunctionComponent<{}> = () => {
  return (
    <Page>
      <PageHeader>
        <PageHeadline>Anmeldung zum Alumni-Newsletter</PageHeadline>
        <PageDescription>
          In diesem Bereich können Sie sich zu allen Studiengangs-Newslettern
          an- und abmelden. Sie können dabei auch eine Mehrfachauswahl treffen.
        </PageDescription>
      </PageHeader>
      {availableNewsletters.map((name, i) => (
        <p>{name}</p>
      ))}
    </Page>
  );
};

export default Newsletter;
