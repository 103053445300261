import React from "react";

import { LoginProvider, LoginConsumer } from "LoginContext";
import Base, { theme } from "01_base/Base";
import AppRouter from "AppRouter";

import { ThemeProvider } from "styled-components";
import { IntlProvider, addLocaleData } from "react-intl";
import de from "react-intl/locale-data/de";

addLocaleData([...de]);

const App = () => (
  <IntlProvider locale="de">
    <ThemeProvider theme={theme}>
      <LoginProvider>
        <Base />
        <AppRouter />
      </LoginProvider>
    </ThemeProvider>
  </IntlProvider>
);

export default App;
