import React, { useContext } from "react";
import styled from "styled-components";
import { LoginContext } from "LoginContext";
import Button from "02_atoms/Button";
import { PageHeadline } from "02_atoms/PageHeader";

const HeaderStyles = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;

  div {
    min-width: 65%;
  }

  img {
    float: left;
  }
`;

const Header: React.FunctionComponent<{}> = () => {
  const login = useContext(LoginContext);

  const { loggedIn, logout, user } = login;

  return (
    <HeaderStyles>
      <div>
        <img alt="KSH München" src="/assets/ksh-logo-small.png" />
        <PageHeadline>IdM-Portal</PageHeadline>
      </div>

      {loggedIn && user && (
        <p>
          <Button cta onClick={logout}>
            Abmelden
          </Button>
        </p>
      )}
    </HeaderStyles>
  );
};

export default Header;
