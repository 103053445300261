import {
  KshClassesPublicationTkshPublication,
  KshClassesFileloadTkshFileLoad
} from "Connectivity/Portal";
import React, { useRef, useState, useContext } from "react";
import FormRow from "02_atoms/Form/FormRow";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import StyledForm from "04_organisms/Form";
import Button from "02_atoms/Button";
import Overlay from "02_atoms/Overlay";
import ActiveIndicator from "02_atoms/Form/ActiveIndicator";
import TitleWrapper from "02_atoms/Form/TitleWrapper";
import DateRow from "02_atoms/Form/DateRow";
import CheckboxRow from "02_atoms/Form/CheckboxRow";
import FormActionContainer from "02_atoms/Form/FormActionContainer";
import PlaceholderTd from "02_atoms/Placeholder/PlaceholderTd";
import MoveActionButton from "02_atoms/Form/MoveActionButton";
import CancelButton from "02_atoms/CancelButton";
import FileUpload from "03_molecules/FileUpload";
import { LoginContext } from "LoginContext";
import NewsCountSpan from "02_atoms/Form/NewsCountSpan";
import Confirm from "02_atoms/Confirm";
import PDFForm from "02_atoms/PDFForm";

type PublicationProps = {
  reFetch: Function;
  reFetchWithoutNewList?: Function;
  updatePublication: Function;
  deletePublication?: Function;
  updateFile?: Function;
  deleteFile?: Function;
  moveUp?: Function;
  moveDown?: Function;
  publication: KshClassesPublicationTkshPublication;
  isNew?: boolean;
  toggleAddNew?: Function;
};

const Publication: React.FunctionComponent<PublicationProps> = ({
  moveDown,
  moveUp,
  reFetch,
  reFetchWithoutNewList,
  deletePublication,
  updatePublication,
  updateFile,
  deleteFile,
  publication,
  isNew = false,
  toggleAddNew
}) => {
  const closePublicationFunction = useRef<Function>(() => { });
  const [src, setSrc] = useState<string | undefined>(undefined);
  const [newFileName, setNewFileName] = useState<string | undefined>(undefined);
  const [deleteFileCheck, setDeleteFileCheck] = useState<boolean>(false);
  const [isExistingFile, setIsExistingFile] = useState<boolean>(false);
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);
  const { user } = useContext(LoginContext);
  const [textAreaLength, setTextAreaLength] = useState(
    publication.description ? publication.description.length : 0
  );

  const updateDownloadUrl = (kpid: number, documentid: number) => {
    setDownloadUrl(`https://services.ksh-m.de:2001/portal/v1/Professor/PublicationFileLoad?kpid=${kpid}&id=${documentid}`);
  };

  const prepareDeleteCareerFunction = async (
    publication: KshClassesPublicationTkshPublication
  ) => {
    if (!deletePublication) {
      return;
    }
    const publicationList = await deletePublication(publication);
    closePublicationFunction.current();
    return reFetch(publicationList);
  };

  // @TODO get rid of any
  const onSaveChange = async (values: any) => {
    values.active = values.active ? 1 : 0;
    if (values.publicationyear && values.publicationyear > 0) {
      values.publicationyear = parseInt(values.publicationyear);
    }
    if (deleteFileCheck && deleteFile) {
      await deleteFile(publication);
      values.documentid = undefined;
    }
    if (isExistingFile) {
      const u = await updateExistingFile();
      values.documentid = u.documentid;
      values.document = u.document;
    }
    if (!updateFile && src) {
      values.document = src;
    }

    values.kpid = user!.kpid!;
    const publicationList = await updatePublication(values);

    if (!publicationList) {
      return [FORM_ERROR];
    }
    reFetch(publicationList);
    closePublicationFunction.current();
  };

  const setUpdateExistingFile = async (filename: string, content: string) => {
    setDownloadUrl(undefined);
    setIsExistingFile(true);
    setNewFileName(filename);
    setSrc(content);
  };

  const updateExistingFile = async () => {
    const file: KshClassesFileloadTkshFileLoad = {
      // @ts-ignore
      kpid: user.kpid,
      id: publication.id,
      filename: newFileName!,
      content: src!
    };

    if (updateFile) {
      const newUploadedFile = await updateFile(file);
      setSrc(undefined);
      updateDownloadUrl(newUploadedFile.kpid, newUploadedFile.documentid);
      setDeleteFileCheck(false);
      return newUploadedFile;
    }
  };

  const createFile = (filename: string, content: string) => {
    setNewFileName(filename);
    setSrc(content);
  };

  const deleteConfirm = () => {
    setDownloadUrl(undefined);
    setDeleteFileCheck(true);
    setIsExistingFile(false);
    setSrc(undefined);
  };

  if (publication.documentid && publication.kpid && !downloadUrl && !deleteFileCheck) {
    updateDownloadUrl(publication.kpid!, publication.documentid);
  }

  return (
    <>
      <tr>
        <TitleWrapper caption={publication.caption} />
        <PlaceholderTd tdWidth={"5%"} />
        <PlaceholderTd tdWidth={"5%"}>
          {publication!.publicationyear! > 0 && publication.publicationyear}
        </PlaceholderTd>
        <PlaceholderTd tdWidth={"5%"}>
          {downloadUrl &&
            <a href={downloadUrl} target="_blank">
              💾{" "}
            </a>
          }
        </PlaceholderTd>

        <ActiveIndicator
          active={!!publication.active}
          updateFunction={updatePublication}
          objectToUpdate={publication}
          reFetch={reFetchWithoutNewList!}
        />
        <PlaceholderTd tdWidth={"10%"}>
          <Overlay
            initialOpen={isNew}
            editLabel="Publikationen"
            buttonLabel="bearbeiten"
            closeFunction={closePublicationFunction}
          >
            <Form
              initialValues={publication}
              onSubmit={onSaveChange}
              render={({
                submitError,
                handleSubmit,
                form,
                submitting,
                pristine,
                values
              }) => (
                  <StyledForm onSubmit={handleSubmit}>
                    <FormActionContainer>
                      <div>
                        {submitError && (
                          <div className="error">{submitError}</div>
                        )}
                        <FormRow>
                          <label>Titel der Publikation</label>
                          <Field
                            name="caption"
                            component="input"
                            type="text"
                            placeholder="Titel der Publikation"
                          />
                        </FormRow>
                        <FormRow>
                          <label>Autoren</label>
                          <Field
                            name="authors"
                            component="input"
                            type="text"
                            placeholder="Autoren"
                          />
                        </FormRow>
                        <DateRow>
                          <label>Erscheinungsjahr</label>
                          <Field
                            name="publicationyear"
                            component="input"
                            type="number"
                            placeholder="Erscheinungsjahr"
                          />
                        </DateRow>
                        <FormRow>
                          <label>Beschreibung</label>
                          <Field
                            name="description"
                            component="textarea"
                            //   type="text"
                            placeholder="Beschreibung"
                            maxLength="500"
                            onInput={(e: any) =>
                              setTextAreaLength(e.target.value.length)
                            }
                          />
                          <NewsCountSpan>{textAreaLength} / 500</NewsCountSpan>
                        </FormRow>
                        <FormRow>
                          <label>Download zur Publikation</label>
                          <FileUpload
                            onFileUploadCompleted={
                              updateFile ? setUpdateExistingFile : createFile
                            }
                            extraMessageForOneFile={" - Nur eine PDF-Datei je Publikation"}
                          />
                        </FormRow>
                        <PDFForm
                          src={src}
                          newFileName={newFileName}
                          document={publication.document}
                          downloadUrl={downloadUrl}
                          deleteFile={deleteFile}
                          deleteConfirm={deleteConfirm}
                          deleteFileCheck={deleteFileCheck} />
                        <FormRow>
                          <label>Link</label>
                          <Field
                            name="url"
                            component="input"
                            placeholder="Link"
                          />
                        </FormRow>
                        <FormRow>
                          <Button type="submit" disabled={submitting}>
                            Übernehmen
                        </Button>
                          <CancelButton
                            isNew={isNew}
                            closeFunction={() => {
                              closePublicationFunction.current();
                              setDeleteFileCheck(false);
                            }}
                            toggleAddNew={toggleAddNew}
                          />
                        </FormRow>
                        <FormRow>
                          {deletePublication &&
                            <Confirm confirmFunction={() => prepareDeleteCareerFunction(publication)} />
                          }
                        </FormRow>
                      </div>
                    </FormActionContainer>
                  </StyledForm>
                )}
            />
          </Overlay>
        </PlaceholderTd>
        <MoveActionButton moveUp={moveUp} moveDown={moveDown} />
      </tr>
      {/* <InformationTr>
        <StyledTd>{publication.authors}</StyledTd>
        <td>{publication.publicationyear}</td>
      </InformationTr>
      <tr>
        <DescriptionTd colSpan={3}>{publication.description}</DescriptionTd>
      </tr> */}
    </>
  );
};

export default Publication;
