import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import FormHeadline from "../../02_atoms/FormHeadline";
import "../../Types/reach__dialog.d.ts";
import { User } from "../../Types/User";
import { Student, StudyProgramm } from "../../Types";
import Address from "./Address";
import { LoginContext } from "../../LoginContext";
import EmailAlias from "./EmailAlias";
import { getStudentEmailAliasAction } from "Connectivity/Api";
import Hidden from "02_atoms/Hidden";

import "@reach/dialog/styles.css";
import Password from "./Password";
import DataWrapper from "02_atoms/DataWrapper";

type StudyProps = {
  user: User;
};

const Study: React.FunctionComponent<StudyProps> = ({ user }) => {
  const [studentData, setStudentData] = useState<Student | null>(null);
  const [studyProgram, setStudyProgram] = useState<Array<StudyProgramm> | null>(
    null
  );
  const loginContext = useContext(LoginContext);

  const fetchStudentData = async () => {
    const result = await loginContext.getStudentData();
    setStudentData(result);
  };

  const fetchStudyData = async () => {
    const result = await loginContext.getStudyData();
    setStudyProgram(result);
  };

  useEffect(() => {
    fetchStudentData();
    fetchStudyData();
  }, [loginContext]);

  if (studentData === null) {
    return null;
  }

  return (
    <>
      <DataWrapper>
        <label>Matrikelnummer</label>
        <p>{studentData.mnr}</p>
      </DataWrapper>

      <DataWrapper>
        <label>Studierendenausweisnummer</label>
        <p>{studentData.swcardid}</p>
      </DataWrapper>

      {studyProgram && (
        <>
          {studyProgram.map((studyProgram: StudyProgramm, index) => (
            <DataWrapper key={`study-${studyProgram.id}`}>
              {index + 1 === 2 ? (
                <Hidden testValue={studyProgram.caption}>
                  <label>Studiengang {index + 1}</label>
                  <p>
                    {studyProgram.caption}, {studyProgram.semester}. Semester
                  </p>
                </Hidden>
              ) : (
                <>
                  <label>Studiengang {index + 1}</label>
                  <p>
                    {studyProgram.caption}, {studyProgram.semester}. Semester
                  </p>
                </>
              )}
            </DataWrapper>
          ))}
        </>
      )}

      <EmailAlias
        user={user}
        personData={studentData}
        getEmailAliasAction={getStudentEmailAliasAction}
      />
    </>
  );
};

export default Study;
