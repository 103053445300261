import {
  KshClassesPublicationTkshPublication,
  KshClassesFileloadTkshFileLoad
} from "Connectivity/Portal";
import React, { useRef, useState, useContext } from "react";
import FormRow from "02_atoms/Form/FormRow";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import StyledForm from "04_organisms/Form";
import Button from "02_atoms/Button";
import Overlay from "02_atoms/Overlay";
import ActiveIndicator from "02_atoms/Form/ActiveIndicator";
import TitleWrapper from "02_atoms/Form/TitleWrapper";
import CheckboxRow from "02_atoms/Form/CheckboxRow";
import FormActionContainer from "02_atoms/Form/FormActionContainer";
import PlaceholderTd from "02_atoms/Placeholder/PlaceholderTd";
import MoveActionButton from "02_atoms/Form/MoveActionButton";
import CancelButton from "02_atoms/CancelButton";
import FileUpload from "03_molecules/FileUpload";
import DateRow from "02_atoms/Form/DateRow";
import { LoginContext } from "LoginContext";
import NewsCountSpan from "02_atoms/Form/NewsCountSpan";
import Confirm from "02_atoms/Confirm";
import PDFForm from "02_atoms/PDFForm";

type ResearchProps = {
  reFetch: Function;
  reFetchWithoutNewList?: Function;
  updateResearch: Function;
  deleteResearch?: Function;
  updateFile?: Function;
  deleteFile?: Function;
  moveUp?: Function;
  moveDown?: Function;
  research: KshClassesPublicationTkshPublication;
  isNew?: boolean;
  toggleAddNew?: Function;
};

const Research: React.FunctionComponent<ResearchProps> = ({
  moveDown,
  moveUp,
  reFetch,
  reFetchWithoutNewList,
  deleteResearch,
  updateResearch,
  updateFile,
  deleteFile,
  research,
  isNew = false,
  toggleAddNew
}) => {
  const closeResearchFunction = useRef<Function>(() => { });
  const [src, setSrc] = useState<string | undefined>(undefined);
  const [newFileName, setNewFileName] = useState<string | undefined>(undefined);
  const [deleteFileCheck, setDeleteFileCheck] = useState<boolean>(false);
  const [isExistingFile, setIsExistingFile] = useState<boolean>(false);
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);
  const { user } = useContext(LoginContext);
  const [textAreaLength, setTextAreaLength] = useState(
    research.description ? research.description.length : 0
  );

  const updateDownloadUrl = (kpid: number, documentid: number) => {
    setDownloadUrl(`https://services.ksh-m.de:2001/portal/v1/Professor/PublicationFileLoad?kpid=${kpid}&id=${documentid}`);
  };

  const prepareDeleteResearchFunction = async (
    career: KshClassesPublicationTkshPublication
  ) => {
    if (!deleteResearch) {
      return;
    }
    const researchList = await deleteResearch(career);
    closeResearchFunction.current();
    return reFetch(researchList);

  };

  // @TODO get rid of any
  const onSaveChange = async (values: any) => {
    values.active = values.active ? 1 : 0;

    if (values.publicationyear && values.publicationyear > 0) {
      values.publicationyear = parseInt(values.publicationyear);
    }
    if (deleteFileCheck && deleteFile) {
      await deleteFile(research);
      values.documentid = undefined;
    }
    if (isExistingFile) {
      const u = await updateExistingFile();
      values.documentid = u.documentid;
      values.document = u.document;
    }
    if (!updateFile && src) {
      values.document = src;
    }

    values.kpid = user!.kpid!;
    const researchList = await updateResearch(values);

    if (!researchList) {
      return [FORM_ERROR];
    }
    await reFetch(researchList);
    closeResearchFunction.current();
  };

  const setUpdateExistingFile = async (filename: string, content: string) => {
    setDownloadUrl(undefined);
    setIsExistingFile(true);
    setNewFileName(filename);
    setSrc(content);
  };

  const updateExistingFile = async () => {
    const file: KshClassesFileloadTkshFileLoad = {
      // @ts-ignore
      kpid: user.kpid,
      id: research.id,
      filename: newFileName!,
      content: src!
    };

    if (updateFile) {
      const newUploadedFile = await updateFile(file);
      setSrc(undefined);
      updateDownloadUrl(newUploadedFile.kpid, newUploadedFile.documentid);
      setDeleteFileCheck(false);
      return newUploadedFile;

    }
  };

  const createFile = (filename: string, content: string) => {
    setNewFileName(filename);
    setSrc(content);
  };

  const deleteConfirm = () => {
    setDownloadUrl(undefined);
    setDeleteFileCheck(true);
    setIsExistingFile(false);
    setSrc(undefined);
  };

  if (research.documentid && research.kpid && !downloadUrl && !deleteFileCheck) {
    updateDownloadUrl(research.kpid!, research.documentid);
  }

  return (
    <tr>
      <TitleWrapper caption={research.caption} />
      <PlaceholderTd tdWidth={"5%"} />
      <PlaceholderTd tdWidth={"5%"}>
        {research!.publicationyear! > 0 && research.publicationyear}
      </PlaceholderTd>
      <PlaceholderTd tdWidth={"5%"}>
        {downloadUrl && (
          <a href={downloadUrl} target="_blank">
            💾{" "}
          </a>
        )}
      </PlaceholderTd>
      <ActiveIndicator
        active={!!research.active}
        updateFunction={updateResearch}
        objectToUpdate={research}
        reFetch={reFetchWithoutNewList!}
      />
      <PlaceholderTd tdWidth={"10%"}>
        <Overlay
          initialOpen={isNew}
          buttonLabel="bearbeiten"
          editLabel="Forschung"
          closeFunction={closeResearchFunction}
        >
          <Form
            initialValues={research}
            onSubmit={onSaveChange}
            render={({
              submitError,
              handleSubmit,
              form,
              submitting,
              pristine,
              values
            }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <FormActionContainer>
                    <div>
                      {submitError && <div className="error">{submitError}</div>}
                      <FormRow>
                        <label>Titel der Forschung</label>
                        <Field
                          name="caption"
                          component="input"
                          type="text"
                          placeholder="Titel der Forschung"
                        />
                      </FormRow>
                      <FormRow>
                        <label>Beschreibung</label>
                        <Field
                          name="description"
                          component="textarea"
                          //   type="text"
                          placeholder="Beschreibung"
                          maxLength="500"
                          onInput={(e: any) =>
                            setTextAreaLength(e.target.value.length)
                          }
                        />
                        <NewsCountSpan>{textAreaLength} / 500</NewsCountSpan>
                      </FormRow>
                      <DateRow>
                        <label>Jahr</label>
                        <Field
                          name="publicationyear"
                          component="input"
                          type="number"
                          placeholder="Erscheinungsjahr"
                        />
                      </DateRow>

                      <FormRow>
                        <label>PDF-Datei zur Forschung</label>
                        <FileUpload
                          onFileUploadCompleted={
                            updateFile ? setUpdateExistingFile : createFile
                          }
                          extraMessageForOneFile={" - Nur eine PDF-Datei je Forschung"}
                        />
                      </FormRow>
                      <PDFForm
                        src={src}
                        newFileName={newFileName}
                        document={research.document}
                        downloadUrl={downloadUrl}
                        deleteFile={deleteFile}
                        deleteConfirm={deleteConfirm}
                        deleteFileCheck={deleteFileCheck} />
                      <FormRow>
                        <label>Link</label>
                        <Field name="url" component="input" placeholder="Link" />
                      </FormRow>
                      <CheckboxRow>
                        <label>Auf der Webseite veröffentlichen?</label>
                        <Field name="active" component="input" type="checkbox" />
                      </CheckboxRow>
                      <FormRow>
                        <Button type="submit" disabled={submitting}>
                          Übernehmen
                      </Button>
                        <CancelButton
                          isNew={isNew}
                          closeFunction={() => {
                            closeResearchFunction.current();
                            setDeleteFileCheck(false);
                          }}
                          toggleAddNew={toggleAddNew}
                        />
                      </FormRow>
                      <FormRow>
                        {deleteResearch &&
                          <Confirm confirmFunction={() => prepareDeleteResearchFunction(research)} />
                        }
                      </FormRow>
                    </div>
                  </FormActionContainer>
                </StyledForm>
              )}
          />
        </Overlay>
      </PlaceholderTd>
      <MoveActionButton moveUp={moveUp} moveDown={moveDown} />
    </tr>
  );
};

export default Research;
