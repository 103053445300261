import {
  KshClassesPublicationTkshPublication,
  KshClassesFileloadTkshFileLoad
} from "Connectivity/Portal";
import React, { useRef, useState, useContext } from "react";
import FormRow from "02_atoms/Form/FormRow";
import DateRow from "02_atoms/Form/DateRow";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import StyledForm from "04_organisms/Form";
import Button from "02_atoms/Button";
import Overlay from "02_atoms/Overlay";
import TitleWrapper from "02_atoms/Form/TitleWrapper";
import FormActionContainer from "02_atoms/Form/FormActionContainer";
import MoveActionButton from "02_atoms/Form/MoveActionButton";
import CancelButton from "02_atoms/CancelButton";
import PlaceholderTd from "02_atoms/Placeholder/PlaceholderTd";
import { LoginContext } from "LoginContext";
import FileUpload from "03_molecules/FileUpload";
import ActiveIndicator from "02_atoms/Form/ActiveIndicator";
import NewsCountSpan from "02_atoms/Form/NewsCountSpan";
import Confirm from "02_atoms/Confirm";
import PDFForm from "02_atoms/PDFForm";

type LectureProps = {
  reFetch: Function;
  reFetchWithoutNewList?: Function;
  updateLecture: Function;
  deleteLecture?: Function;
  updateFile?: Function;
  deleteFile?: Function;
  moveUp?: Function;
  moveDown?: Function;
  lecture: KshClassesPublicationTkshPublication;
  isNew?: boolean;
  toggleAddNew?: Function;
};

const Lecture: React.FunctionComponent<LectureProps> = ({
  moveDown,
  moveUp,
  reFetch,
  reFetchWithoutNewList,
  updateLecture,
  deleteLecture,
  updateFile,
  deleteFile,
  lecture,
  isNew = false,
  toggleAddNew
}) => {
  const closePublicationFunction = useRef<Function>(() => { });
  const [src, setSrc] = useState<string | undefined>(undefined);
  const [newFileName, setNewFileName] = useState<string | undefined>(undefined);
  const [deleteFileCheck, setDeleteFileCheck] = useState<boolean>(false);
  const [isExistingFile, setIsExistingFile] = useState<boolean>(false);
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);
  const { user } = useContext(LoginContext);
  const [textAreaLength, setTextAreaLength] = useState(
    lecture.description ? lecture.description.length : 0
  );

  const updateDownloadUrl = (kpid: number, documentid: number) => {
    setDownloadUrl(`https://services.ksh-m.de:2001/portal/v1/Professor/PublicationFileLoad?kpid=${kpid}&id=${documentid}`);
  };

  const prepareDeleteLectureFunction = async (
    publication: KshClassesPublicationTkshPublication
  ) => {
    if (!deleteLecture) {
      return;
    }
    const lectureList = await deleteLecture(publication);
    closePublicationFunction.current();
    reFetch(lectureList);

    return;
  };

  // @TODO get rid of any
  const onSaveChange = async (values: any) => {
    values.active = values.active ? 1 : 0;

    if (deleteFileCheck && deleteFile) {
      await deleteFile(lecture);
      values.documentid = undefined;
    }
    if (isExistingFile) {
      const u = await updateExistingFile();
      values.documentid = u.documentid;
      values.document = u.document;
    }
    if (!updateFile && newFileName && src) {
      values.document = src;
    }

    values.kpid = user!.kpid!;
    if (values.publicationyear && values.publicationyear > 0) {
      values.publicationyear = parseInt(values.publicationyear);
    }

    const lectureList = await updateLecture(values);
    if (!lectureList) {
      return [FORM_ERROR];
    }
    reFetch(lectureList);
    closePublicationFunction.current();
  };

  const setUpdateExistingFile = async (filename: string, content: string) => {
    setDownloadUrl(undefined);
    setIsExistingFile(true);
    setNewFileName(filename);
    setSrc(content);
  };

  const updateExistingFile = async () => {
    const file: KshClassesFileloadTkshFileLoad = {
      // @ts-ignore
      kpid: user.kpid,
      id: lecture.id,
      filename: newFileName!,
      content: src!
    };

    if (updateFile) {
      const newUploadedFile = await updateFile(file);
      setSrc(undefined);
      updateDownloadUrl(newUploadedFile.kpid, newUploadedFile.documentid);
      setDeleteFileCheck(false);
      return newUploadedFile;
    }
  };

  const createFile = (filename: string, content: string) => {
    setNewFileName(filename);
    setSrc(content);
  };

  const deleteConfirm = () => {
    setDownloadUrl(undefined);
    setDeleteFileCheck(true);
    setIsExistingFile(false);
    setSrc(undefined);
  };

  if (lecture.documentid && lecture.kpid && !downloadUrl && !deleteFileCheck) {
    updateDownloadUrl(lecture.kpid!, lecture.documentid);
  }

  return (
    <tr>
      <TitleWrapper caption={lecture.description} />
      <PlaceholderTd tdWidth={"5%"} />
      <PlaceholderTd tdWidth={"5%"}>
        {downloadUrl && (
          <a href={downloadUrl} target="_blank">
            💾{" "}
          </a>
        )}
      </PlaceholderTd>
      <ActiveIndicator
        active={!!lecture.active}
        updateFunction={updateLecture}
        objectToUpdate={lecture}
        reFetch={reFetchWithoutNewList!}
      />
      <PlaceholderTd tdWidth={"10%"}>
        <Overlay
          initialOpen={isNew}
          editLabel="Vorträge"
          buttonLabel="bearbeiten"
          closeFunction={closePublicationFunction}
        >
          <Form
            initialValues={lecture}
            onSubmit={onSaveChange}
            render={({
              submitError,
              handleSubmit,
              form,
              submitting,
              pristine,
              values
            }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <FormActionContainer>
                    <div>
                      {submitError && <div className="error">{submitError}</div>}
                      <FormRow>
                        <label>Informationen zum Vortrag</label>
                        <p style={{ fontSize: 10, color: "grey", marginTop: 5 }}>Titel des Vortrags, Ort, Datum (z. B.: Freiwilligenarbeit – aktuelle Befunde, Klausurtagung der Freien Wohlfahrtspflege Bayern in Bad Tölz, 16.01.2020)</p>
                        <Field
                          name="description"
                          component="textarea"
                          //   type="text"
                          placeholder="Informationen zum Vortrag"
                          maxLength="500"
                          onInput={(e: any) =>
                            setTextAreaLength(e.target.value.length)
                          }
                        />
                        <NewsCountSpan>{textAreaLength} / 500</NewsCountSpan>
                      </FormRow>

                      <FormRow>
                        <label>PDF-Datei zum Vortrag</label>
                        <FileUpload
                          onFileUploadCompleted={
                            updateFile ? setUpdateExistingFile : createFile
                          }
                          extraMessageForOneFile={" - Nur eine PDF-Datei je Vortrag"}
                        />
                      </FormRow>
                      <PDFForm
                        src={src}
                        newFileName={newFileName}
                        document={lecture.document}
                        downloadUrl={downloadUrl}
                        deleteFile={deleteFile}
                        deleteConfirm={deleteConfirm}
                        deleteFileCheck={deleteFileCheck} />
                      <FormRow>
                        <label>Link</label>
                        <Field name="url" component="input" placeholder="Link" />
                      </FormRow>
                      <FormRow>
                        <Button type="submit" disabled={submitting}>
                          Übernehmen
                      </Button>
                        <CancelButton
                          isNew={isNew}
                          closeFunction={() => {
                            closePublicationFunction.current();
                            setDeleteFileCheck(false);
                          }}
                          toggleAddNew={toggleAddNew}
                        />
                      </FormRow>
                      <FormRow>
                        {deleteLecture &&
                          <Confirm confirmFunction={() => prepareDeleteLectureFunction(lecture)} />
                        }
                      </FormRow>
                    </div>
                  </FormActionContainer>
                </StyledForm>
              )}
          />
        </Overlay>
      </PlaceholderTd>
      <MoveActionButton moveUp={moveUp} moveDown={moveDown} />
    </tr>
  );
};

export default Lecture;
