import React from "react";
import FormRow from "02_atoms/Form/FormRow";
import Confirm from "02_atoms/Confirm";

interface PDFForm {
  src?: string;
  newFileName?: string;
  document?: string;
  downloadUrl?: string;
  deleteFileCheck?: boolean;
  deleteFile?: Function;
  deleteConfirm: Function;
}

export default ({ src, newFileName, document, downloadUrl, deleteFile, deleteConfirm, deleteFileCheck }: PDFForm) => {
  return (
    <>
      {src && <FormRow> <a target="_blank" className="button">{newFileName} (Nicht gespeichert) 💾</a>
        {deleteFile &&
          <Confirm confirmFunction={() => deleteConfirm()} message={`Möchten Sie die Datei (${document}) wirklich löschen?`} buttonText={"Dokument löschen"} />}
      </FormRow>
      }
      {downloadUrl && !deleteFileCheck && !src && (
        <FormRow>
          <a
            href={downloadUrl}
            target="_blank"
            className="button"
          >
            {document
              ? document
              : "Download"}{" "}
            💾
                          </a>
          {deleteFile &&
            <Confirm confirmFunction={() => deleteConfirm()} message={`Möchten Sie die Datei (${document}) wirklich löschen?`} buttonText={"Dokument löschen"} />
          }
        </FormRow>
      )}
    </>

  );
};