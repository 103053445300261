import React, { ReactChild, useRef, RefObject } from "react";
import { Dialog } from "@reach/dialog";

import "@reach/dialog/styles.css";
import { useToggle } from "../Utils/Hooks";
import DataWrapper from "02_atoms/DataWrapper";
import Button from "02_atoms/Button";
import CancelButton from "02_atoms/CancelButton";
import FormHeadline from "02_atoms/FormHeadline";

type OverlayProps = {
  children: ReactChild;
  closeFunction?: React.MutableRefObject<Function>;
  editLabel?: string;
  buttonLabel?: string | null;
  initialOpen?: boolean;
};

const Overlay: React.FunctionComponent<OverlayProps> = props => {
  const {
    children,
    editLabel = "Bearbeiten",
    closeFunction,
    initialOpen = false,
    buttonLabel = null
  } = props;

  const [isOpen, toggle] = useToggle(initialOpen);

  if (closeFunction) {
    closeFunction.current = toggle;
  }

  return (
    <DataWrapper>
      <Button onClick={() => toggle()}>{buttonLabel || editLabel}</Button>
      <Dialog isOpen={isOpen} onDismiss={() => toggle()}>
        <FormHeadline>{editLabel}</FormHeadline>
        {/* <Button className="close-button" onClick={() => toggle()}>
          <span aria-hidden>×</span>
        </Button> */}
        {children}
      </Dialog>
    </DataWrapper>
  );
};

export default Overlay;
