import React, { useState, useEffect, useCallback } from "react";
import { KshClassesCareerTkshCareer } from "Connectivity/Portal";
import { useToggle } from "Utils/Hooks";
import Button from "02_atoms/Button";
import News from "./News";
import {
  getNewsDataAction,
  updateNewsDataAction
} from "../../Connectivity/Api";
import styled from "styled-components";
import { KshClassesNewsTkshNews } from "../../Connectivity/Portal";
import Table from "02_atoms/Form/Table";
import notify, { addNotification } from "Utils/SaveNotification";

const FunctionKshList: React.FunctionComponent<{}> = () => {
  const [addNewIsOpen, toggle] = useToggle(false);
  const [newsList, setNews] = useState<Array<KshClassesNewsTkshNews>>([]);

  const fetchData = useCallback(async () => {
    const result = await getNewsDataAction();
    setNews(result);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Table>
        <tbody>
          {newsList &&
            newsList.map((news: KshClassesNewsTkshNews, i) => (
              <News
                news={news}
                label={i === 0 ? "News links: " : "News rechts: "}
                key={`career-${i}`}
                reFetch={fetchData}
                updateNews={addNotification(updateNewsDataAction)}
              // deleteNews={deleteNewsDataAction}
              />
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default FunctionKshList;
