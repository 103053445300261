import styled from "styled-components";
import { placeholder } from "@babel/types";

type PlaceholderProps = {
  tdWidth?: string;
};

const PlaceholderTd = styled("td")<PlaceholderProps>`
  width: ${props => props.tdWidth};
  a {
    padding: 0;
    :hover {
      text-decoration: none;
    }
  }
`;

export default PlaceholderTd;
