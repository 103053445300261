import React, { useContext } from "react";

import Page from "05_templates/Page";
import SectionHeadline from "02_atoms/SectionHeadline";
import Link from "02_atoms/Link";
import { LoginContext } from "LoginContext";
import Button from "02_atoms/Button";
import Email from "04_organisms/Data/Email";
import Mobile from "04_organisms/Data/Mobile";
import Address from "04_organisms/Data/Address";
import { navigate } from "@reach/router";

const Review = () => {
  const { logout, updateEmail, user, updateMobile, verifyData } = useContext(
    LoginContext
  );
  if (!user) {
    return null;
  }

  const onVerify = async () => {
    await verifyData();
    navigate("/data");
  };

  return (
    <Page>
      <SectionHeadline>Hinweise</SectionHeadline>
      <p>
        Ihre Kontaktdaten müssen einmal im Jahr von Ihnen bestätigt werden.
        Bearbeiten und bestätigen Sie hier Ihre Daten, bevor Sie zu Ihrer
        Datenübersicht gelangen:
      </p>

      <p>Das Passwort muss mindestens jedes Jahr geändert werden.</p>

      <Email updateEmail={updateEmail} email={user.email} />

      <Mobile updateMobile={updateMobile} mobile={user.mobilephone} />

      <Address label="Kontaktadresse" type="main" />

      <Button fullsize cta onClick={() => onVerify()}>
        Bestätigen und zur Datenübersicht
      </Button>
      <Button onClick={logout}>Logout</Button>
    </Page>
  );
};

export default Review;
